export default {
  groupPlaceholder: '选择标签搜索',
  belong: '归属：',
  datePlaceholder: '选择删除时间搜索',
  browserNamePlaceholder: '输入店铺名搜索',
  remarkPlaceholder: '输入备注搜索',
  showAll: '展示全部窗口',
  showSelf: '展示自建窗口',
  recoverSelection: '恢复选中',
  recoverAll: '恢复全部',
  deleteSelection: '删除选中',
  deleteAll: '删除全部',
  recoverDialog: '恢复窗口',
  recoverTips1: '注意：此操作将恢复回收站中的全部窗口至以下分组，包括子账号中的窗口',
  recoverTips2: '注意：此操作将仅恢复选中窗口至以下分组',
  createdName: '归属',
  updateBy: '操作用户',
  deleteTime: '删除时间',
  deleteConfirm: '<p class="text-red">窗口删除后不可恢复，确认要删除已选择的窗口吗？</p>',
  deleteSuccess: '删除成功',
  clearConfirm: '<p class="text-red">窗口删除后不可恢复，确认要删除全部窗口吗？</p>',
  recoverSuccess: '恢复成功'
}
