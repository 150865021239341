import { date } from 'quasar'
import store from 'src/store'
const cnWebsite = store.getters['client/cnWebsite']
const enWebsite = store.getters['client/enWebsite']
/*
 * @description 从url中匹配hostname
 * @params {string} url
 */
export function getHostname(url) {
  // 自定义域名可能没有以 / 结尾，手动补上，以匹配以下正则
  if (!url.endsWith('/')) {
    url += '/'
  }
  const domain = url.match(/^http(s)?:\/\/(www.)?(.*?)\//)
  if (domain) {
    return domain[3]
  }
  return url
}

// 跳转至官网下载页面，redirect 是否当前页面重定向
export function gotoDownload(redirect, isEn) {
  let url = cnWebsite + '/download/'
  if (isEn) url = enWebsite + '/download/'

  if (redirect) {
    location.href = url
  } else {
    window.open(url)
  }
}

// 读取通过file input 选择的文件内容
export async function getFileReaderTextResult(file) {
  if (!file) return ''

  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.addEventListener('loadend', () => {
      resolve(reader.result)
    })
  })
}

// 获取折扣值
export function getDiscountValue(num, locale) {
  return locale === 'zh' ? parseFloat(num * 10).toFixed(1) + ' 折' : parseFloat(num * 100) + ' %'
}

// get请求参数拼接
export function getformatQuery(obj) {
  let str = ''
  for (const key in obj) {
    str += `&${key}=${obj[key]}`
  }
  if (str) {
    str = str.slice(1)
  }
  return str
}
// 是否是移动端
export function hasMobile() {
  let isMobile = false
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    isMobile = true
  }
  if (isMobile && document.body.clientWidth <= 768) {
    isMobile = true
  }
  return isMobile
}

export function getEndTimeDiff(endTime) {
  return date.getDateDiff(endTime, new Date())
}
export function formatDate(time, format) {
  return date.formatDate(time, format)
}
export function isEmptyObject(val) {
  return Object.prototype.toString.call(val) === '[object Object]' && Object.keys(val).length === 0
}
export function formatMoney(num) {
  return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 加载外部js
export function loadScript(src) {
  var scriptElement = document.createElement('script')
  scriptElement.src = src
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(scriptElement, s)
}

export function jsonp(url, options) {
  // 超时处理
  const { timeout } = options
  return new Promise((resolve, reject) => {
    // 防止函数名冲突
    let funcName = `jsonp${Date.now()}`
    let time = null,
      scriptNode
    // 定义callback
    window[funcName] = function (data) {
      if (timeout) clearTimeout(time)
      resolve(data)
      // 很重要的性能优化点
      // 清除本次请求产生的回调函数和script标签
      delete window[funcName]
      document.body.removeChild(scriptNode)
    }
    // 创建script标签
    scriptNode = document.createElement('script')
    // 给script标签添加src属性
    scriptNode.src = `${url}?callback=${funcName}&${options.params}`
    // 发出请求
    document.body.appendChild(scriptNode)
    time = setTimeout(() => {
      reject('network err, timeout')
    }, timeout)
    // 失败
    scriptNode.onerror = function (err) {
      reject(err)
    }
  })
}
export function getSelection() {
  if (window && document) {
    window.getSelection() ? window.getSelection().removeAllRanges() : document.getSelection().empty()
  }
}
// 取消文字选中高亮区域
export function removeSelectionTextArea() {
  const selection = (window.getSelection && window.getSelection()) || document.getSelection()

  if (selection && selection.removeAllRanges) {
    selection.removeAllRanges()
  } else if (selection && selection.empty) {
    selection.empty()
  }
}
export function getSymbolFlag(isZh, money, moneyUnit) {
  money = money ?? 0
  if (isZh) {
    return money + moneyUnit
  } else {
    return moneyUnit + money
  }
}
// 延迟
export function sleep(seconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, seconds * 1000)
  })
}
/**
 * @description 版本对比
 * @returns {number} 1 => 远程版本大，-1 => 本地版本大，0 => 版本相同
 */
export function compareVersion(curr = '', remote = '') {
  if (curr === remote) return 0
  const currStack = curr.split('.')
  const remoteStack = remote.split('.')
  while (remoteStack.length) {
    if (currStack.length === 0) return 1 // 远程版本stack长度大于curr长度时，代表远程的版本大，此时所有currStack都已经遍历完，且和remoteStack的前几项都一样
    const currItem = +currStack.shift()
    const remoteItem = +remoteStack.shift()
    if (currItem > remoteItem) return -1
    if (currItem < remoteItem) return 1
  }
  if (currStack.length > 0) return -1 // 远程版本比较完，currStack还有元素，代表curr版本大
}
