import { request } from 'src/boot/axios'

export function updatePreference(data) {
  return request({ url: '/v1/globalsetting', method: 'put', data })
}

export function getPreference(userId) {
  return request({ url: `/v1/globalsetting/${userId}`, method: 'get' })
}

// 查询全局代理信息
export function getAllGlobalProxy(userId) {
  return request({ url: '/v1/globalproxysetting/findByUserId', method: 'post', data: { userId } })
}

// 根据用户ID和代理类型查询全局代理信息
export function getGlobalProxy(data) {
  return request({ url: '/v1/globalproxysetting/findByUserIdAndProxyType', method: 'post', data })
}

// 修改全局代理设置
export function setGlobalProxy(data) {
  return request({ url: '/v1/globalproxysetting', method: 'put', data })
}

// 查询全局指纹偏好
export function getFingerprintPreference(userId) {
  return request({ url: '/v1/globalbrowsersetting/' + userId, method: 'get' })
}

// 设置全局指纹偏好
export function updateFingerprintPreference(data) {
  return request({ url: '/v1/globalbrowsersetting', method: 'put', data })
}

// 获取全部全局设置
export function getGlobalSettings() {
  return request({ url: '/v1/globalvaluesetting/getByUserId', method: 'post' })
}

export function getGlobalValue(data) {
  return request({ url: '/v1/globalvaluesetting/getGlobalValue', method: 'post', data, cacheTime: 24, cacheAlias: data.globalValueType })
}

// 修改全局value设置
export function setGlobalValueSetting(data) {
  return request({ url: '/v1/globalvaluesetting', method: 'put', data })
}
