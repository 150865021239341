export default {
  fillIn: 'Fill in',
  select: 'Select',
  pickFile: 'Select file',
  fillInUsername: 'Enter a user name',
  fillInGroupName: 'Enter a group name',
  fillInInt: 'Enter an integer',
  fillInNum: 'Enter a number ',
  edit: 'Edit',
  cancel: 'Cancel',
  confirm: 'Confirm',
  action: 'Operation',
  loading: 'Loading...',
  positiveInt: 'Enter an integer greater than 0',
  notExcel: 'Select Excel File To Upload.',
  importSuccess: 'Success',
  reset2default: 'Reset To Default',
  reset: 'Reset',
  save: 'Save',
  saveSuccess: 'Success',
  updateSuccess: 'Success',
  checkAll: 'Check All',
  dontPopupAgain: 'No Longer Pop Up',
  close: 'Close',
  numMax: 'Enter an integer not exceeding 5 digits.',
  numMax1000: 'Enter an integer less than 1000',
  numMax10000: 'Please fill in a four-digit integer',
  numMin500: 'Enter a number greater than 500',
  numMax2000: 'Enter a number less than 2000',
  numMin200: 'Enter a number greater than 200',
  numMin120: 'Enter a number greater than 120',
  numMin40: 'Enter a number greater than 40',
  searchPlaceholder: 'Fill in',
  search: 'Search',
  advancedSearch: 'Advanced',
  searchNow: 'Search Now',
  userPlaceholder: 'Select User Account',
  image: 'Select Image',
  back: 'Back',
  filter: 'Filter',
  next: 'Next',
  prev: 'Previous',
  copy: 'Copy',
  copySuccess: 'Copy successfully.',
  deleteSuccess: 'Success',
  clear: 'Clear',
  home: 'Home',
  retry: 'Retry',
  minimize: 'Minimize',
  contain: 'contain:',
  userPhone: 'Username/Phone'
}
