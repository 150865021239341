import { request } from 'src/boot/axios'
import { StorageCachePrefix } from 'utils/cache'

// 获取字典list
export function getDicListByName(name) {
  const data = {
    searchParas: {
      conditions: [
        {
          name: 'dicName',
          op: 'eq',
          value: name
        }
      ]
    }
  }
  return request({
    url: '/v1/sysDictionarys/list',
    method: 'post',
    data: data,
    cacheTime: 24
  })
}

// 获取字典子值list
export function getChildrenDicts(dicCode) {
  return request({
    url: '/v1/sysDictionarys/getChildrenListByName',
    method: 'post',
    data: { dicCode },
    cacheTime: 24,
    cacheAlias: dicCode,
    cacheStore: `${StorageCachePrefix.dict}${dicCode}`
  })
}

// 获取字典新注册用户赠送金额字典
export function getChildrenListByType(dicType) {
  return request({
    url: '/v1/sysDictionarys/getChildrenListByType',
    method: 'post',
    data: { dicType }
  })
}
// 获取 webAppVersion，无鉴权
export function getWebAppVersion() {
  return request({ url: '/v1/sysDictionarys/getNoPassCode', method: 'post', data: 'webAppVersion' })
}
