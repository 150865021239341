import store from 'src/store'

export default {
  // 在元素被插入到 DOM 前调用
  beforeMount(el, binding, vnode, prevVnode) {},
  // 在绑定元素的父组件
  // 及他自己的所有子节点都挂载完成后调用
  mounted(el, binding, vnode, prevVnode) {
    const { value } = binding
    const permissions = store.getters && store.getters['user/permissions']
    const isAdmin = store.getters && store.getters['user/isMainAccount']

    // 管理员拥有全部权限
    if (isAdmin) return
    if (value && value instanceof Array && value.length > 0) {
      const permissionFlag = value
      const hasPermissions =
        permissions &&
        permissions.some(permission => {
          return permissionFlag.includes(permission)
        })
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('Please set value of permission tag')
    }
  },
  // 绑定元素的父组件更新前调用
  beforeUpdate(el, binding, vnode, prevVnode) {},
  // 在绑定元素的父组件
  // 及他自己的所有子节点都更新后调用
  updated(el, binding, vnode, prevVnode) {},
  // 绑定元素的父组件卸载前调用
  beforeUnmount(el, binding, vnode, prevVnode) {},
  // 绑定元素的父组件卸载后调用
  unmounted(el, binding, vnode, prevVnode) {},

  // 在绑定元素的 attribute 前
  // 或事件监听器应用前调用
  created(el, binding, vnode) {}
}
