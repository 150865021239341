export default {
  attention: 'Attention：',
  tips1:
    '1. The following are recommended proxy IP platforms, only for displaying. FireBrowser does not guarantee and assumes responsibility! Please contact platform customer service if any questions.',
  tips2:
    '2. Disclaimer: Please follow legal and compliance, you should be responsible for the consequences of illegal use, which has nothing related with FireBrowser .',
  itemTitle: 'The {arg} account is automatically registered for you:',
  account: 'Account: ',
  password: 'Initial Password: ',
  itemTitle2: 'Exclusive 40% off Coupon Code:',
  toAdmin: 'To {arg} Admin',
  toIndex: 'To {arg} Dashboard',
  toWebsite: 'To {arg} Platform',
  showMore: 'Show More',
  hideMore: 'Hide More',
  addTelMsg: 'Please complete your mobile number.',
  noInventory: 'No inventory',
  sale: 'SALE',
  deleteConfirmMsg: 'Are you sure you want to delete the selected {arg1} devices? <br/>Recoverable in device recycle bin after deletion!',
  deleteConfirmMsg1: 'Are you sure you want to delete the selected ({arg1}) device? <br/>Recoverable in device recycle bin after deletion!',
  deleteCompletelyConfirm:
    'Are you sure you want to delete ({arg1}) store completely? <p class="text-red">After the store is completely deleted, it cannot be restored, please operate with caution! </p>',
  deleteCompletelyConfirm2:
    'Are you sure to delete all stores completely? <p class="text-red">After the store is completely deleted, it cannot be restored, please operate with caution! </p>',
  deleteCompletelyConfirm1:
    'Are you sure you want to completely delete the selected {arg1} devices? <p class="text-red">The device cannot be recovered after it is completely deleted, please operate with caution! </p>',
  actionSucces: 'Success',
  renewedMsg: 'There are devices currently selected that cannot be renewed. Please reselect!',
  renewedMsg1: 'The current device cannot be renewed, please reselect!',
  maxBuyAgentNum: 'The maximum limit for a single purchase is {num}',
  createTime: 'Create Time',
  orderInformation: 'order Information',
  devicePlatform: 'device Platform',
  quickRecharge: 'Quick recharge'
}
