export default {
  groupControl: 'Group Control',
  groupControlLabel: 'Group Control: ',
  groupControlDesc: 'Browsers Sync All in One',
  proxyIp: 'Proxy IP',
  proxyIpDesc: 'Integrate Global Proxy IP resources',
  chuhai2345: 'Chuhai2345',
  chuhai2345Desc1: '',
  chuhai2345Desc2: 'Integrate the Best Cross-border Resources',
  scriptMarket: 'Script Market',
  scriptMarketDesc: 'Script Customization Platform',
  zhiwenshouji: 'Anti-Phone',
  zhiwenshoujiDesc: 'Anti-association Environment & Unmanned Live Broadcast',
  userLabel: 'User: ',
  roleLabel: 'Role: ',
  balanceLabel: 'Balance: ',
  rechargeLabel: 'Recharge',
  getCoupon: 'Coupon(20% Off) For Old Users',
  package: 'Package:',
  browsers: 'Browser Profiles:',
  unit: '',
  users: 'Users:',
  additionUsers: 'Additional Users:',
  expired: 'Expired:',
  browsersCount: 'Total: ',
  used: 'Used: ',
  assigned: 'Assigned: ',
  activate: 'Subscribe',
  totalUsers: 'Total: ',
  controlBrowsers: 'Total: ',
  groupControlDocs: 'Guide (Graphic)',
  groupControlVideoDocs: 'Guide (Video)',
  qa: 'Q&A',
  open: 'Open',
  notice: 'Notice',
  docs: 'Guide',
  help: 'Help',
  issues: 'Issues',
  apiDocs: 'API Documents (Support Browser Operation And Selenium/Puppeteer Script)',
  createdTime: 'Created Time: ',
  close: 'Close',
  groupControlOpened: 'Group Control has opened.',
  groupControlOpenFail:
    "Group control system failed to open. Please ensure a smooth network connection and try again. If your system hasn't installed the .NET 4.6.1 environment, click the button below to download and install it, then try again!",
  upgrade: 'Please upgrade the client and retry.',
  couponText: `
    <p>Geted, please view at "Cost -> Coupon </p>"</p>
    <p class="q-mt-sm">Could be used for Subscribe Package/Change Plan/Update Users!</p>
  `,
  downloadApp: 'The group control system only supports Windows system, Please download the Windows version client if you need!',
  shop: 'Stores',
  deviceBound: 'Device Bound',
  add: 'Add',
  total: 'Total',
  expiringSoon: 'Expiring Soon',
  rpaDesc: 'Browser automatically completes tedious and repetitive content',
  goBuy: 'Go Buy',
  downloadDonet: '[Download .NET4.6.1]',
  registerGiftTip: `Newly registered users are given <span class='text-red text-bold'>{num}</span> sheet <span class='text-red text-bold'>{arg} ￥</span> coupon, please click "Expense Management Please click "Expense Management" menu and enter "Coupon" interface to check the coupon, or click "View Coupon" at the bottom to check the coupon. The coupon can be used for opening and renewing IP devices!`,
  forcePasswordChangeTip:
    'To ensure the security of your FireBrowser account, we strongly recommend that you change your login password regularly. This time, please be sure to change your password and log in to FireBrowser again. We apologize for any inconvenience caused!',
  goChange: 'Change Now'
}
