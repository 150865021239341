export default {
  baseinfo: 'Basic',
  name: 'Name',
  username: 'Username',
  email: 'Email',
  bindEmail: 'Bind Email',
  changeEmail: 'Change Email',
  qqPlaceholder: 'Please enter your Wechat/QQ',
  qq: 'Wechat/QQ',
  save: 'Save',
  changeTel: 'Change Phone Number',
  currentTel: 'Phone Number',
  codePlaceholder: 'Filter by nation code',
  newTel: 'New Number',
  newEmail: 'New Email',
  telPlaceholder: 'Enter your phone',
  verifyCodePlaceholder: 'enter the verification code',
  emailverifyCode: 'Please enter the verification code',
  verifyCode: 'Verification Code',
  passwordPlaceholder: 'Please enter your login password',
  userNameMaxLen: 'Username cannot exceed 50 characters.',
  password: 'Password',
  confirmChange: 'Confirm And Save',
  passwordSet: 'Reset Password',
  oldPassPlaceholder: 'Please enter your old password',
  oldPassword: 'Old Password',
  newPassPlaceholder: 'Please enter your new password',
  newPass: 'New Password',
  newPassAgain: 'Please confirm your new password',
  confirmPassword: 'Confirm Password',
  savepass: 'Save',
  passRule1: 'Please confirm your new password',
  passRule2: 'The confirm passwords entered twice do not match.',
  nationRule: 'Please select a country',
  resentTips: 'Resend in {seconds} seconds.',
  send: 'Get SMS Code',
  sendEmail: 'Get E-mail Code',
  qqChangeConfirm: 'Confirm to update email?',
  passChangeConfirm: 'Confirm to reset password?<br/>Please log in with the new password after reset!',
  passChangeSuccess: 'Success',
  telChangeConfirm: 'Confirm to bind this mobile number?',
  telChangeSuccess: 'Success',
  telBindSuccess: 'Success',
  telChangeError: 'The mobile phone number has been bound by another account, please change it!',
  emailChangeError: 'The email is already linked to another account. Please use a different email!',
  sendSuccess: 'Success',
  forgetUsername: 'Forget Username',
  findAccount: 'Find your Username',
  yourUsername: 'Your Username',
  getBack: 'Find',
  welcome: 'Log in to',
  backLogin: 'Back To Log in',
  verifyRule1: 'Please enter the verification code.',
  verifyRule2: 'The verification code is 6 digits.',
  usernameCopied: '{name} has copied.',
  usernamePlaceholder: 'Enter your username',
  usernamePlaceholder1: 'Enter username/name',
  usernamePlaceholder2: 'Enter username/phone number.',
  correctUsername: 'Please enter the correct username or phone number',
  loginPassPlaceholder: 'Please enter your password',
  loginPass: 'Password',
  rememberPass: 'Remember Password',
  forgetPass: 'Forget Password',
  login: 'Login',
  toRegister: "Don't have an account yet? To Sign up",
  signingIn: 'Logging in...',
  welcomeRegister: 'Sign up for',
  registerUsername: 'Enter your username',
  tel: 'Phone Number',
  registerQQPlaceholder: 'Optional, please enter your Wechat/QQ',
  registerEmail: 'Optional, please enter your email',
  inviteCodePlaceholder: 'Optional, please enter the invitation code',
  inviteCode: 'Invitation Code',
  register: 'Register',
  readAndKnow: 'I have read and agree',
  userPolicy: 'User Services Agreement',
  userPrivacy: 'User Privacy Agreement',
  haveAccount: '',
  toLogin: 'Already have an account?',
  buyAgentTip1: 'Purchase Device Service Agreement',
  buyAgentTip2: 'Equipment purchase instructions',
  userPolicyDialog: 'User Services Agreement',
  registerUsernameRule: 'Username must be at least 6 characters.',
  registerUsernameRule2: 'User name can have up to 20 digits',
  inviteCodeRule: 'The invitation code cannot exceed 10 characters.',
  policyValid: 'Please read and agree to the "User Services Agreement" "User Privacy Agreement"',
  policyValid1: 'Please read and agree to the "Purchase Device Service Agreement" "Equipment purchase instructions"',
  registerSuccess: 'Registration succeeded. Do you want to download the software client now?',
  downloadNow: 'Download',
  downloadLater: 'Download Later',
  registerAndLogin: 'Registration succeeded, please log in.',
  resetPass: 'Reset Password',
  resetConfirm: 'Confirm',
  resetSuccess: 'Success',
  otherSetting: 'Password Verification',
  opendeleteWindowvVer: 'Turn on completely delete window password verification',
  '2fAuth': 'Two-Factor Authentication',
  enable2fa: 'Enable 2FA',
  enableConfirm:
    'Confirm to enable Two-Factor Authentication?<p class="q-mt-sm">Once enabled, you will need to pass 2fa verification when you log in.</p><p class="q-mt-sm text-red">Note: Please ensure that your can receive SMS normally, or you may not pass Two-Factor Authentication.</p>',
  disable2faConfirm:
    'Confirm to disable Two-Factor Authentication?<p class="q-mt-sm">Once disabled, you can only use username and password to log in.</p>',
  enablePasswordVerification:
    'Confirm enabling thorough shop deletion password verification? <p class="q-mt-sm text-red">Once enabled, password verification will be required when deleting shops/devices.</p>',
  disablePasswordVerification:
    'Confirm disabling thorough shop deletion password verification? <p class="q-mt-sm text-red">Once disabled, password verification will not be required when deleting shops/devices.</p>',
  enableTransPasswordVerification:
    'Confirm enabling shop transfer password verification? <p class="q-mt-sm text-red">Once enabled, password verification will be required when transferring shops.</p>',
  disableTransPasswordVerification:
    'Confirm disabling shop transfer password verification? <p class="q-mt-sm text-red">Once disabled, password verification will be required when transferring shops.</p>',
  authenticator: 'Authenticator',
  bind: 'Bind',
  unbind: 'Unbind',
  bound: 'Bound',
  secretKey: 'Secret Key',
  googleAuthCode: 'Authenticator Code',
  googleCodePlaceholder: 'Please enter the code generated by authenticator',
  bindTitle: 'Binding Authenticator',
  scanQrCode: 'Scan QR Code',
  authValidation: 'Validation',
  scanTips: 'Note: Scan the QR code with authenticator or copy the Secret Key to use.',
  unbindTitle: 'Unbind Authenticator',
  recommended: 'Recommended Authenticator',
  googleAuthenticator: 'Google Authenticator',
  microsoftAuthenticator: 'Microsoft Authenticator',
  tencentAuthenticator: 'Tencent Authenticator',
  sms: 'SMS',
  validationMethod: 'Validation Method',
  smsCode: 'SMS Code',
  requiredEmail: 'Enter your email address.',
  emailPlaceholder: 'Enter your email address',
  emailVerification: 'E-mail Verification',
  phoneVerification: 'Phone Verification',
  checkType: 'Verification Method',
  userProtocol: 'Bit Browser E-commerce User Protocol',
  privacy: 'Privacy Agreement',
  join: 'Invite you to join',
  applyJoin: 'Apply to join',
  pendingReview: 'Pending review',
  assistanAwitTip1: 'Application Submitted You have successfully submitted your application to join {company} company.',
  assistanAwitTip2:
    'We need to wait for approval. The result has been notified in the form of a text message. Please pay attention to the text message!',
  assistanAwitTip3:
    'After passing the review, a success notification will be sent to the phone number you filled in. Please check it carefully!',
  assistanAwitTip4: 'You can use the username and password provided in the application to log in to the Bite-commerce version.',
  assistanExcedd: 'The current link has expired. Please contact your inviter to regenerate the invitation link!',
  securitySetting: 'Security Setting',
  vistualTelTips:
    'Accounts registered with virtual phone numbers are at risk of being stolen. When logging into the system, security verification will be triggered. Please make sure that this number can receive SMS verification codes at any time. It is strongly recommended to register an account with a physical phone number.',
  secureAuth: 'Identity Security Verification',
  usePrivacy: 'User Privacy Agreement',
  loginPhoneTip: 'When entering your phone number, please omit the area code',
  passwordLogin: 'Password Login',
  VerificationLogin: 'Verification Code Login',
  selectUser: 'Select Login Account',
  pleaseSelectUser: 'Select Login Account',
  bindPhone: 'Bind Phone',
  telAuthentication: 'Mobile phone number authentication successful',
  telAuthenticationTitle: 'Mobile number authentication',
  accreditation: 'accreditation',
  authenticated: 'Аутентифицировано',
  phoneEmailLoginTip: 'Note: You can log in to the {arg} directly using the successfully authenticated phone number.',
  notCertified: 'Not Certified,',
  currentEmail: 'Email Number',
  openTransBrowserPassword: 'Enable password verification for transfer Stores',
  deleteBrowserTip: 'Delete store/device password verification',
  changePassword: 'By Current Password',
  changeVerificationCode: 'By Verification Code'
}
