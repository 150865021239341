export default {
  attention: '注意：',
  tips1:
    '1. 以下为客户所推荐的代理IP平台，官方仅做整理展示，服务与质量请自行判断，官方不做保证和承担责任！所用代理IP使用相关问题请咨询平台方客服！',
  tips2: '2. 免责声明：请合法合规使用，若因违规使用产生一切后果自行负责，与本软件官方无关！',
  itemTitle: '自动为您注册的{arg}平台账号密码如下：',
  account: '平台账号：',
  password: '初始密码：',
  itemTitle2: '专属6折优惠券码：',
  toAdmin: '进入{arg}后台页面',
  toIndex: '进入{arg}首页',
  toWebsite: '进入{arg}平台',
  showMore: '查看更多推荐',
  hideMore: '收起更多推荐',
  addTelMsg: '请完善手机号后再进行使用',
  noInventory: '暂无库存',
  sale: '惠',
  deleteConfirmMsg: '确定删除所选{arg1}个设备吗？<br/>删除后，设备不可再使用，可在回收站中恢复！',
  deleteConfirmMsg1: '确定删除 {arg1} 设备吗？<br/>删除后，设备不可再使用，可在回收站中恢复！',
  deleteCompletelyConfirm: '确定彻底删除 {arg1} 设备吗？<p class="text-red">设备彻底删除后不可恢复，请谨慎操作！</p>',
  deleteCompletelyConfirm2: '确定彻底删除全部设备吗？<p class="text-red">设备彻底删除后不可恢复，请谨慎操作！</p>',
  deleteCompletelyConfirm1: '确定彻底删除所选{arg1}个设备吗？<p class="text-red">设备彻底删除后不可恢复，请谨慎操作！</p>',
  actionSucces: '操作成功',
  renewedMsg: '当前选中的设备中有无法续费的设备，请重新选择！',
  renewedMsg1: ' 当前设备无法续费，请重新选择！',
  maxBuyAgentNum: '单次购买限制最大{num}个',
  createTime: '创建日期',
  orderInformation: '订单信息',
  devicePlatform: '设备平台',
  quickRecharge: '快捷充值'
}
