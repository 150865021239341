export default {
  loginRequestAuthorization: '登录请求授权',
  loginLog: '登录日志',
  operationLog: '操作日志',
  pleaseLoginTime: '请选择登录时间',
  pleaseActionTime: '请选择操作时间',
  loginTime: '登录时间',
  loginTime1: '登录时间限制',
  loginEmployee: '登录员工',
  pleaseContent: '请选择操作内容',
  details: '详情',
  Area: '地点',
  exit: '退出',
  code: '终端识别码',
  pass: '已通过',
  fail: '未通过',
  termination: '终止',
  rejected: '已拒绝',
  empower: '授权  ',
  refuse: '拒绝',
  reauthorization: '重新授权',
  userName: '请求授权用户名',
  name: '请求授权姓名',
  device: '访问终端',
  status: '申请状态',
  time: '授权时间',
  refuseTip: '拒绝后该申请用户无法登录软件，您确定拒绝吗？',
  terminationTip: '将终止该用户对已授权终端的登录权限，您确定终止吗？',
  terminationTip1: '终止授权成功',
  refuseTip1: '拒绝授权成功',
  detailTitle: '员工登录授权',
  localNetwork: '本机网络',
  mac: 'MAC 地址',
  authorizationMethod: '登录授权方式',
  permanently: '永久授权使用',
  permanently2: '按日期授权使用',
  noLimit: '不限制登录时间',
  fixedTime: '固定时间登录',
  startTime: '开始时间',
  endTime: '结束时间',
  startDate: '开始日期',
  endDate: '结束日期',
  selectFixed: '请选择固定日期登录',
  approvalSuccessful: '审批成功',
  pendingApproval: '待审批'
}
