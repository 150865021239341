// import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

import user from './modules/user'
import client from './modules/client'
import routes from './modules/routes'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

const store = createStore({
  modules: {
    user: {
      namespaced: true,
      actions: user.actions,
      getters: user.getters,
      state: user.state,
      mutations: user.mutations
    },
    client: {
      namespaced: true,
      state: client.state,
      getters: client.getters,
      mutations: client.mutations,
      actions: client.actions
    },
    routes: {
      namespaced: true,
      state: routes.state,
      getters: routes.getters,
      mutations: routes.mutations,
      actions: routes.actions
    }
  },

  // enable strict mode (adds overhead!)
  // for dev mode and --debug builds only
  strict: process.env.DEBUGGING
})

export default store

// SSR wrapper，可以不用
// export default store(function (/* { ssrContext } */) {
//   const Store = createStore({
//     modules: {
//     },

//     // enable strict mode (adds overhead!)
//     // for dev mode and --debug builds only
//     strict: process.env.DEBUGGING
//   })

//   return Store
// })
