<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" no-backdrop-dismiss :style="outerStyle" :persistent="persistent">
    <q-card class="q-dialog-plugin" :style="contentStyle">
      <q-card-section>
        <div class="flex align-center">
          <q-icon name="bit-help" :color="iconColor" size="18px"></q-icon>
          <span class="q-ml-sm" style="font-size: 14px">
            <strong>{{ $t('common.reminder') }}</strong>
          </span>
          <q-btn
            icon="o_close"
            class="absolute"
            round
            flat
            unelevated
            dense
            style="right: 10px; top: 12px; z-index: 2"
            @click="onCancelClick"
            v-if="showClose"
          ></q-btn>
        </div>
      </q-card-section>
      <q-card-section>
        <div v-if="hasChip" class="text-container q-mb-sm">
          <div class="font-14 row" v-if="hasChip.browser">
            <div class="custom-bg-info row items-center">
              {{ $t('browser.currentStore') }}

              <div class="ellipsis" style="max-width: 250px">
                {{ hasChip.browser.name }}
                <q-tooltip max-width="300px">
                  <div>{{ hasChip.browser.name }}</div>
                </q-tooltip>
              </div>
              <div style="max-width: 250px" class="ellipsis">
                &nbsp;| {{ hasChip.browser.platform }} &nbsp;
                <q-tooltip max-width="300px" style="word-break: break-all">
                  <div>{{ hasChip.browser.platform }}</div>
                </q-tooltip>
              </div>
            </div>
          </div>

          <div class="font-14 row" v-else-if="hasChip.list">
            <div class="custom-bg-info row items-center">
              {{ $t('browser.selectStoresTip', { arg1: hasChip.list }) }}
            </div>
          </div>
        </div>
        <!-- 可以用换行符等 -->
        <div v-html="text" class="text-container"></div>
      </q-card-section>
      <q-card-actions class="row">
        <q-checkbox v-model="closeaction" dense :label="$t('setting.rememberChoose')" color="primary" v-if="saveSelect && token" />

        <q-space></q-space>
        <q-btn no-caps unelevated :style="cancelStyleVal" @click="onCancelClick" v-if="cancelBtn" :label="cancelBtnText"></q-btn>
        <q-btn no-caps :label="$t('form.minimize')" unelevated class="theme-cancel-btn" @click="minimize" v-if="showMinimize"></q-btn>
        <q-btn no-caps color="primary" unelevated @click="onOKClick" :label="okBtnText" :style="okStyle"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import { mapGetters } from 'vuex'

import { useDialogPluginComponent, useQuasar } from 'quasar'

import { getGlobalValue, setGlobalValueSetting } from 'src/api/settings'
import { CacheMap } from 'utils/cache'
export default {
  name: 'SysConfirm',
  props: {
    showClose: {
      type: Boolean,
      default: false
    },
    text: String,
    iconColor: {
      default: 'primary',
      type: String
    },
    okText: {
      type: String,
      default: ''
    },
    cancelBtn: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: ''
    },
    cancelStyle: {
      type: String,
      default: 'background: #ececec;'
    },
    okStyle: String,
    contentStyle: String,
    showMinimize: Boolean,
    outerStyle: String,
    persistent: Boolean,
    saveSelect: Boolean,
    hasChip: Object
  },

  computed: {
    okBtnText() {
      return this.okText || this.$t('form.confirm')
    },
    cancelBtnText() {
      return this.cancelText || this.$t('form.cancel')
    },
    ...mapGetters({
      token: 'user/token'
    })
  },

  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ],

  methods: {
    async minimize() {
      // 判断有没有保存
      if (this.closeaction && this.token) {
        const res = await getGlobalValue({ globalValueType: 'browserBaseSetting' })
        let olData = {}
        if (res.globalValue) {
          olData = JSON.parse(res.globalValue)
        }
        await setGlobalValueSetting({
          globalValueType: 'browserBaseSetting',
          globalValue: JSON.stringify({ ...olData, closeaction: 'minimize' })
        })
        CacheMap.DeleteGlobalValueCache('browserBaseSetting')
      }
      this.dialogRef.hide()
      await this.$nextTick()
      if (window.isMac) {
        window.bitClientMain.minimize()
      } else {
        window.bitClientMain.hideMainWindow()
      }
    }
  },

  setup(props) {
    const $q = useQuasar()
    const cancelStyleVal = ref(props.cancelStyle)
    if (cancelStyleVal.value.includes('#ececec') && $q.dark.isActive) {
      cancelStyleVal.value = 'background: #454444;'
    }
    // REQUIRED; must be called inside of setup()
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome
    let closeaction = ref(false)
    return {
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide,
      closeaction,
      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick() {
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK(closeaction.value)
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },

      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel,
      cancelStyleVal
    }
  }
}
</script>

<style lang="scss" scoped>
.text-container {
  padding-left: 28px;
  word-break: break-all;
}
</style>
