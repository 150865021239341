export default {
  category: 'Category: ',
  addExtension: 'Add Custom Extension',
  addFromLocal: 'From Local',
  addFromGoogle: 'From Google Store',
  publishTips: 'Please contact us to publish the extension to the extension center.',
  publish: 'Publish',
  enable: 'Enable',
  disable: 'Disable',
  status: 'Status: ',
  name: 'Extension Name: ',
  userExtensions: 'My Extensions',
  noUserExtensions: 'No Extensions',
  extensions: 'Extension Center',
  more: 'More',
  provider: 'Provider: ',
  notInClient: 'Please operate in the client',
  uploadExtension: 'Upload Extension',
  uploadTips:
    'Note: The standard chrome extension folder contains a manifest.json file, which contains information such as the name and version, please select the folder where the manifest.json file is located as the root directory.',
  checkDir: 'Click to select the extension and identify the extension information',
  pickDir: 'Select extension dir',
  extensionDir: 'Extension Dir',
  versionPlaceholder: 'Extension Version',
  version: 'Version',
  icon: 'Icon',
  iconTips: 'Select a square image, the size does not exceed 50KB',
  namePlaceholder: 'Extension Name',
  extensionDesc: 'Description',
  googleStore: 'Upload From Chrome Web Store',
  googleStoreTips: 'Note: Enter the extension url specified by the Chrome Web Store to automatically identify and download extension',
  extensionUrlPlaceholder: 'Enter the extension url specified by the Chrome Web Store',
  extensionUrl: 'Extension Url',
  detect: 'Detect',
  nameRuleMsg: 'Enter the extension name',
  descRuleMsg: 'Enter the extension description',
  pathRuleMsg: 'Click the upper button to select the extension directory',
  disableExtension: 'Confirm to disable this extension for all browsers?',
  enableExtension: 'Confirm to enable the extension?',
  syncSuccess: 'Synchronization succeeded',
  syncFailed: 'Synchronization failed, please try again.',
  noManifest: 'The manifest.json file does not exist in the directory, please select the right directory.',
  saveSuccess: 'Saved',
  deleteExtension: 'Confirm to delete this local extension?',
  deleteSuccess: 'Deleted',
  detectFailed: 'Detection failed, please ensure that the global proxy mode is enabled on the local network',
  saveFailed: 'Save failed, please ensure the extension information is valid.',
  '9PerPage': '9 Records/Page',
  '18PerPage': '18 Records/Page',
  '45PerPage': '45 Records/Page',
  '90PerPage': '90 Records/Page',
  upgradeTips: 'The current version does not support this function, please upgrade the client.',
  commerce: 'E-commerce:',
  type: 'Type:',
  ExtensionType: 'Extension Type:',
  moreCategories: 'More categories',
  retract: 'retract',
  uploadMaxSize: 'The selected image should not exceed 50KB in size.',
  notImg: 'Please select an image to upload!',
  deleteExtensionTips: 'Remove Extension',
  upgradeExtensionTips: 'Update extension',
  updateFailed: 'Update failed, please ensure that the global proxy mode is enabled on the local network.',
  updateLocalExtensionTips:
    'When updating an extension, be sure not to select the wrong one, as it can result in the loss of the original extension and its associated information!',
  noNeedUpdate: 'The current version is already up to date, no update is required!',
  cloudSync: 'Cloud Synchronization',
  uploadSuccess: 'Upload successful.',
  syncEnabled: 'Sync Enabled Status To Employee Account',
  syncEnabledConfirm: 'Confirm to synchronize the enabled status of this extension for all employee accounts?',
  syncDisabled: 'Sync Disabled Status To Employee Account',
  syncDisabledConfirm: 'Confirm to synchronize the disabled status of this extension for all employee accounts?',
  uploader: 'Uploader:',
  allBrowser: 'All Stores',
  allBrowserDesc: 'This extension is enabled on all Stores',
  prescribedBrowser: 'Specified Stores',
  prescribedBrowserDesc: 'Manually select the specified window or group to activate this extension',
  settingExtensionMethod: 'Set extension activation method',
  extendsSetiingaVailable: 'This feature is only available for version 3.0.4 and above',
  setting: 'settings',
  cannotDelete: 'Not uploaded by you, cannot be deleted!',
  submitExtensions: 'Submit Extensions',
  submitExtensions1: 'Please enter the URL of the extension on the Chrome Web Store',
  submitExtensions2:
    'Enter the URL of the detail page for the specified extension in the Google Web Store, for example: https://chrome.google.com/webstore/detail/google-translate/aapbdbdomjkkjkaonfhkkikfgjllcleb?hl=en-US (Google Translate)',
  submitExtensions3:
    'We have received your submission request, and our backend team is currently reviewing it at lightning speed. Once approved, it will be displayed in the extension center. Please stay tuned for updates in the extension center.',
  submit: 'Submit',
  contact: 'Contact Customer Service'
}
