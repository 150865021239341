export default {
  updateProxyWithSeq: '修改代理（序号：{seq}）',
  updateProxy: '修改代理',
  updateSuccess: '修改成功',
  proxyMethod: '代理方式',
  ipCheckService: 'IP查询渠道',
  ipCheckTips1: '1.店铺的工作台页面展示的IP相关信息，将使用您选择的IP查询渠道进行查询与展示！',
  ipCheckTips2:
    '2.不同的IP查询渠道，可能会存在一定的检测结果差异！若代理检测结果与实际信息不符，可以调整IP查询渠道以确保检测结果的准确性！',
  ipCheckTips3:
    '3.若该店铺的指纹设置中，勾选了基于IP生成：语言、界面语言、时区、地理位置，请确保选择的IP查询渠道的检测结果与实际信息相符，否则会导致上述几处自动生成后产生误差！',
  ipCheckTips4: '（Rola-IP建议使用渠道IP123）',
  proxyType: '代理类型',
  ipProtocol: 'IP协议',
  useGlobalProxy: '使用全局设置',
  globalProxyInfo: '使用全局设置的 { proxyType } 代理',
  host: '代理主机',
  hostPlaceholder: '支持“代理类型://主机:端口号:账号:密码”自动拆分',
  hostTips: '代理类型://主机:端口:账号:密码，填写该格式，光标离开后自动拆分。<br/>若代理主机为IPv6，请将代理主机填写在 [ ] 内！',
  port: '代理端口',
  portPlaceholder: '请输入端口',
  username: '代理账号',
  usernamePlaceholder: '请输入账号（选填）',
  usernamePlaceholder2: '请输入账号',
  password: '代理密码',
  passwordPlaceholder: '请填写密码（选填）',
  passwordPlaceholder2: '请填写密码',
  ipPlaceholder: '请填写默认使用IP地址（如：11.22.33.44）',
  noChangeIp: '只代理当前您填写的IP，若是当前填写IP失效，不会自动寻找可用IP代理！',
  ipHint: '若填写IP，下次打开窗口将优先使用该IP；不填写IP或已填写的IP失效，将使用下方位置信息自动寻找可用的IP！',
  ipTips: '填写IP后，鼠标点击空白处，自动识别并填充下方国家信息',
  country: '国家/地区',
  provicePlaceholder: '请填写州/省编码',
  provice: '州/省',
  proviceHint: '对应911s5中指定IP的“State”列中的英文代码！',
  cityPlaceholder: '请填写城市编码',
  city: '城市',
  cityHint: '对应911s5中指定IP的“City”列中的英文代码！',
  checkProxy: '代理检测',
  httpsProxyMsg: '[代理检测]<br/>请打开网页后测试',
  ipCheckFailedMsg: '代理检测失败，请确认代理IP的有效性，有疑问请联系代理IP商！',
  ipNoChange: '只代理当前IP',
  ipNoChangeTips: '该IP失效不会自动更换IP',
  hostRuleMsg: '请输入主机地址',
  portRuleRequiredMsg: '请输入端口号',
  portRuleNumMsg: '端口号为纯数字',
  portRuleValidMsg: '端口号范围为0-65535，请勿超出此范围',
  usernameRuleMsg: '代理账号长度不超过200位',
  passwordRuleMsg: '代理密码长度不超过200位',
  ipRuleMsg1: '勾选“只代理当前IP”时，务必填写IP',
  ipRuleValidMsg: '请填写合法的IP地址',
  proviceRuleMsg: '州/省编码为不超过30个字符的英文字母',
  cityRuleMsg1: '请输入正确的城市编码',
  cityRuleMsg2: '城市编码为不超过30个字符的英文字母',
  customize: '自定义代理',
  extractIp: '使用代理IP平台API提取链接提取',
  extractIpDialog: '正在通过API提取链接提取代理IP...',
  extractIpMsg1: '检查上一次的代理IP是否有效...',
  extractIpMsg2: '代理IP可用，直接打开...',
  extractIpMsg3: '代理IP已失效，重新提取代理IP...',
  extractIpMsg4: '检查API提取链接...',
  extractIpMsg5: '代理IP提取链接没有设置，无法提取代理IP。',
  extractIpMsg6: '尝试提取代理IP信息...',
  extractIpMsg7: '正在解析提取结果...',
  extractIpMsg8: '设置成功，正在打开浏览器...',
  extractIpMsg9: '无法提取代理IP，请检查提取链接是否有误。',
  extractIpMsg10: '解析成功，正在设置代理IP...',
  formatError: '请将API返回格式改为text/txt等文本格式后再试...',
  addWhiteList: '正在尝试自动加入白名单...',
  notInWhiteList: '未加入白名单',
  usernameRequiredRule: '请填写代理账号',
  passwordRequiredRule: '请填写代理密码',
  provider: '服务商',
  protocol: '代理协议',
  extractType: '提取方式',
  extractUrlPlaceholder: '请输入提取链接地址',
  extractUrl: '提取链接',
  extractTest: '测试提取',
  whitelistTips: '注意：大部分代理IP平台，需要将本地设备的IP加入其白名单，方可正常访问提取链接，如若出现异常请自行添加白名单！',
  initialPass: '初始密码：',
  extractUrlTips: '获取提取链接方法',
  rolaTips:
    '点击“进入推荐商ROLA-IP后台”按钮后进入Rola-IP后台，点击菜单项：海外住宅IP->动态住宅IP 或 海外手机IP 或 海外机房IP->动态机房IP，然后点击面板项“API接入”，设置好相关参数以后点击“生成API提取”按钮后，即可生成出代理IP提取链接！',
  extractIpRule: '请选择服务商',
  extractUrlRule: '请填写正确的链接URL',
  extractProtocolRule: '请选择代理协议',
  extractIpChange1: '每次打开浏览器窗口都提取新IP',
  extractIpChange2: '上次提取IP失效才提取新IP',
  extractError: '未能请求到数据，请检查API链接是否正确',
  ipCountry: '国家/地区：',
  ipState: '州/省：',
  ipCity: '城市：',
  iplngAndLat: '经度/纬度：',
  ipTimezone: '时区：',
  ipZip: '邮编：',
  ipNotWorking: '网络不通',
  common: '通用',
  noproxy: '直连模式（不设置代理）',
  ipideaauto: 'ipidea 动态代理',
  iphtmlauto: 'iphtml 动态代理',
  oxylabsauto: 'oxylabs 动态代理',
  lumauto: 'luminati 动态代理',
  lunaproxy: 'lunaproxy 动态代理',
  ssh: 'SSH',
  socks5: 'Socks5',
  http: 'HTTP',
  https: 'HTTPS',
  lonAndLat: '经度/纬度',
  ip2world: 'ip2world 动态代理',
  usedLabel1: '检测到该IP于最近时间',
  usedLabel2: '在本软件中使用过！',
  ipNoUsed: '该IP地址未在本软件中使用过！',
  correct: '更正',
  channel: '平台',
  correctSuccess: '更正成功',
  correctTitle: 'IP识别查询/修改',
  isNotSure: '不正确？',
  duplicateCheck: '校验重复',
  lonAndLat: '经度/纬度',
  timeZone: '时区',
  postcode: '语言',
  channel: '平台',
  correct: '更正',
  correctSuccess: '更正成功',
  correctTitle: 'IP识别查询/修改',
  isNotSure: '不正确？'
}
