export default {
  balance: '余额',
  recharge: '在线充值',
  coupon: '查看优惠券',
  bill: '账单月费',
  month: '月',
  days: '天',
  renew: '续费套餐',
  expiredDays: '距离到期还剩',
  expire: '到期',
  freePackage: '免费套餐',
  noExpired: '永久可用',
  billDetail: '账单详情',
  pkgLabel: '经典套餐: 窗口',
  users: '员工',
  additionUsers: '额外员工:',
  activate: '开通套餐',
  change: '变更套餐',
  changeUsers: '变更员工',
  statistics: '访问统计',
  todayOpen: '今日打开次数',
  openCountComment: '每天打开窗口最大次数 = 总窗口数 x {count}，超过总次数当天无法打开，第二天恢复。',
  ordersNotPay: '未支付订单（USDT）',
  moneyUnit: '元',
  unit: '个',
  paymentOrder: '继续支付',
  paymentOrderDialog: '继续支付订单',
  paid: '已完成支付',
  wechatPay: '微信支付',
  balancePay: '余额支付',
  alipay: '支付宝支付',
  rechargePay: '充值',
  withdrawPay: '提现充值',
  time: '时间',
  payMoney: '交易金额',
  payType: '支付方式',
  accountBalance: '账户余额',
  payRemark: '交易详情',
  additionErrMsg: '免费套餐不支持变更额外员工，请先开通收费套餐',
  paySuccess: '支付成功',
  changeInfo: '<strong>经典套餐</strong>(原套餐: 浏览器窗口{browserCount}个 员工数{users}个)',
  pkgChangeLabel: '经典套餐',
  pkgChangeLabel2: '原套餐：浏览器窗口',
  pkgChangeLabel3: '个 员工数',
  pkgBrowsers: '浏览器窗口：{count}个',
  pkgUsers: '员工：{count}个',
  pkgUnit: '元/月',
  activateMonths: '开通周期',
  useBalance: '使用余额',
  balanceAssets: '可用余额: {balance}元',
  paypalTips: '仅支持海外PayPal账户支付',
  totalBrowsers: '总浏览器窗口数: {count}个',
  pkgExpired: '套餐到期时间：',
  pkgOpenCountTips: '注：该套餐，每天打开窗口最大次数 = {browsers} x {maxCount}，超过总次数当天无法打开，第二天恢复。',
  shouldPay: '应付金额：',
  pkgCommentsLabel1: '费用计算公式：',
  pkgCommentsValue1: '(新套餐金额-旧套餐金额)/31*剩余天数',
  pkgCommentsLabel2: '说明：',
  pkgCommentsValue2: '减配套餐系统不会退费，请谨慎操作',
  usersCommentsLabel: '增加个数 x 费用/31 x 剩余天数',
  usersCommentsValue: '减配套餐或者减少员工系统不会退费，请谨慎操作',
  changeConfirm: `
              <p class="text-red text-bold">降低套餐以后：</p>
              <p class="text-red q-mt-sm">1. 将禁用所有员工账号，请到“员工管理”处再次启用！</p>
              <p class="text-red q-mt-sm">2. 系统不进行退费，并且立刻生效！</p>
              <p class="text-red q-mt-sm">3. 套餐数量以外的窗口，不会被删除，但会被禁止打开！</p>
              <p class="q-mt-sm">是否需要操作？</p>
              `,
  activateSuccess: '套餐开通成功',
  changeSuccess: '套餐变更成功',
  calculateError: '金额计算错误，请重试',
  couponDialog: '优惠券',
  sheets: '张',
  myCoupon: '我的优惠券',
  usedCoupon: '已使用优惠券',
  couponType: '优惠券类型',
  couponStatus: '优惠券状态',
  couponCode: '优惠券码',
  couponRemark: '备注',
  cashCoupon: '现金券',
  discountCoupon: '折扣券',
  couponStatus0: '未使用',
  couponStatus1: '已使用',
  couponValue: '优惠券面值',
  couponValueFormat: '{value} 元',
  couponCreatedTime: '发放日期',
  couponExpired: '有效期',
  couponState: '状态',
  couponExpense: '使用优惠券',
  couponExpensePlaceholder: '请输入优惠券码',
  changeUsersDialog: '变更额外员工',
  additionalUsers: '额外增加员工',
  assignedUsers: '已用',
  totalUsers: '总数',
  increase: '增加',
  reduce: '减少',
  userCost: '费用:',
  userCostUnit: '元/个/月',
  extraUsers: '原有额外员工数:',
  extraUsers2: '个，本次',
  reduceTips: '注意：减少员工时，如果剩余的员工数（总数-已用）小于要减少的数量，则变更员工后将禁用所有员工账号，请到员工管理再次启用。',
  totalExtraUsers: '总员工数：',
  usersRule1: '请输入大于等于0的整数',
  usersRule2: '请输入小于10000的整数',
  usersRule3: '减少不能大于原有额外员工数',
  reduceUsersConfirm: '减少额外员工，系统不进行退费，并且立刻生效，请谨慎操作！<br/>是否需要操作？',
  changeUsersSuccess: '额外员工变更成功',
  originalPrice: '原价',
  finalPay: '实付',
  cycleMonth: '个月',
  cycleMonths: '个月',
  confirmActivate: '确认开通',
  confirmChange: '确认变更',
  confirm: '确认',
  orderCreatedTime: '订单时间',
  orderMoney: '订单金额',
  orderState: '订单状态',
  notPay: '未支付',
  hasPaid: '已支付',
  rechargePlaceholder: '请填写金额，元',
  rechargeAmount: '金额',
  minAmount: '最小充值金额为：{amount}元',
  rechargeRule1: '请填写金额，金额为数字',
  rechargeRule2: '最小充值金额为',
  rechargeRule3: '充值金额最小单位为分',
  pkgDetail: '套餐详情',
  extraAddUsers: '额外员工数：{count}个',
  renewCycle: '续费周期',
  youhui: '优惠：',
  renewExpired: '续费后到期时间：',
  renewComments: '(当前套餐费用 + 额外员工费用) x 月数',
  confirmRenew: '确认续费',
  renewSuccess: '续费成功',
  orderType: '订单类型',
  orderType1: '充值',
  orderType2: '开通套餐',
  orderType3: '变更套餐',
  orderType4: '续期套餐',
  orderType8: '变更员工',
  cycle: '周期',
  activatePackage: '套餐{pkg}，周期：1个月',
  activatePackageMonths: '套餐{pkg}，周期：{cycle}个月',
  changePlan: '变更前：{before}套餐，变更后：{after}套餐',
  renewDetail: '套餐：{pkg}，周期：{months}个月',
  increaseUsers: '增加员工：{count}个',
  reduceUsers: '减少员工：{count}个',
  autoRenew: '自动续费',
  enableAutoRenew:
    '确认开启自动续费吗？<p class="q-mt-sm">开启自动续费后，系统会自动检测：在您的套餐到期的前3天，若您的账户有充足的余额，会自动续费1个月套餐并扣相应余额！</p>',
  disableAutoRenew: '确认取消自动续费吗？<p class="q-mt-sm">取消自动续费后，系统会停止检测套餐是否到期，且不会为您自动续费套餐！</p>',
  rate: '费率：',
  bankCard: '银行卡',
  ratioRule1: '不可低于系统默认比例',
  ratioRule2: '不可高于您的佣金比例',
  payWarning: '<p class="text-red">注意：请规范使用该支付方式，如发现盗刷等风险支付行为，您的账号将永久封禁，不可解封。</p>',
  accountSummary: '账户概括',
  recharge1: '立即充值',
  rechargeTip: '提示：充值金额不支持退款，请理性充值。公司内所有员工购买/续费设备时可共享账户余额！',
  orderManagement: '订单管理',
  export: '批量导出',
  orderNum: '订单号',
  pleaseOrderNum: '请输入订单号',
  pleaseSelectTime: '请选择付款时间',
  paymentTime: '付款时间',
  detail: '查看详情',
  paymentUser: '付款用户',
  微信: '微信',
  支付宝: '支付宝',
  余额: '余额',
  后台充值: '后台充值',
  提现充值: '提现充值',
  panpay: 'Panpay',
  虚拟币支付: '虚拟币支付',
  信用卡支付: '信用卡支付',
  银行卡: '银行卡',
  orderDetail: '订单详情',
  orderName: '订单名称',
  number: '数量',
  discountAmount: '优惠金额',
  actualPayment: '实际支付',
  待支付: '待支付',
  生成中: '生成中',
  已生效: '已生效',
  生成失败: '生成失败',
  失效: '失效',
  已支付: '已支付',
  已取消: '已取消',
  交易异常: '交易异常',
  支付失败: '支付失败',
  orderId: '交易单号',
  transactionType: '交易类型',
  balanceDetails: '余额明细',
  exportTip: '请选择导出数据的时间',
  tradeBalance: '交易金额',
  afterBalance: '交易后余额',
  quickPayment: '快捷支付',
  remainingTimes: '剩余使用次数',
  usedTimes: '已使用{arg}次',
  usedDate: '使用日期',
  rechargeRebate: '充值 {amount1} 元 - {amount2} 元',
  rechargeRebate1: '充值 {amount1} 元 - {amount2} 元',
  rechargeRebate2: '返点 <span class="text-red">{rebate}</span>',
  rechargeRebate3: '无返点',
  rechargeRebateTitle: '充值返利活动',
  rechargeRebateTitle1: ' {date}结束，赶快参加啦',
  discount: '折',
  actualArrival: '实际到账：',
  discountPrice: '折扣价',
  originalPrice: '原价',
  discountPrice1: '折扣金额',
  chinIpbuyTip: 'IP为中国大陆地区，不支持该支付方式！',
  discountCoupons: '该项仅允许使用金额优惠券，禁止使用折扣优惠券！',
  limitless: '无限制',
  allowRenewal: '仅允许续费IP',
  purchaseBuy: '仅允许购买IP',
  specialGiftTitle: '注册送好礼',
  specialGiftTip: '注：优惠券的有效期以券面标示为准！',
  beenRefunded: '此笔交易金额已退款',
  cancelOrders: '取消订单',
  useAction: '使用'
}
