export default {
  baseinfo: '基本信息',
  name: '姓名',
  username: '用户名',
  email: '邮箱',
  bindEmail: '绑定邮箱',
  changeEmail: '更换邮箱',
  qqPlaceholder: '请输入微信/QQ',
  qq: '微信/QQ',
  save: '保存信息',
  changeTel: '更换手机号',
  currentTel: '当前手机号码',
  codePlaceholder: '输入编码搜索',
  newTel: '新手机号码',
  newEmail: '新邮箱',
  telPlaceholder: '请输入手机号码',
  verifyCodePlaceholder: '请输入验证码',
  emailverifyCode: '请输入邮箱验证码',
  verifyCode: '验证码',
  checkType: '校验类型',
  passwordPlaceholder: '请输入登录密码',
  password: '登录密码',
  confirmChange: '确认更换',
  passwordSet: '密码设置',
  oldPassPlaceholder: '请输入原始密码',
  oldPassword: '原密码',
  newPassPlaceholder: '请输入新密码',
  newPass: '新密码',
  newPassAgain: '请再次输入新密码',
  confirmPassword: '确认密码',
  savepass: '修改密码',
  passRule1: '请再次输入新密码',
  passRule2: '两次输入密码不一致',
  nationRule: '请选择国家',
  resentTips: '{seconds}秒后重新发送',
  send: '发送验证码',
  sendEmail: '发送验证码',
  qqChangeConfirm: '确定修改邮箱吗？',
  passChangeConfirm: '确定修改密码吗？<br/>修改密码后请使用新密码登录！',
  passChangeSuccess: '密码修改成功',
  telChangeConfirm: '确定绑定此手机号码吗？',
  telChangeSuccess: '手机号码修改成功',
  telBindSuccess: '手机号码绑定成功',
  telChangeError: '当前手机号码已被其他账号绑定，请更换手机号码！',
  emailChangeError: '当前邮箱已被其他账号绑定，请更换邮箱！',
  sendSuccess: '发送成功',
  forgetUsername: '找回用户名',
  findAccount: '找回用户名',
  yourUsername: '您的用户名',
  getBack: '立即找回',
  welcome: 'Hi，欢迎登录',
  backLogin: '返回登录',
  verifyRule1: '请输入动态验证码',
  verifyRule2: '验证码为6位数字',
  usernameCopied: '{name}已复制到剪贴板',
  usernamePlaceholder: '请输入用户名',
  usernamePlaceholder2: '请输入用户名/手机号',
  usernamePlaceholder1: '请输入用户名/手机号',
  correctUsername: '请输入正确的用户名/手机号',
  userNameMaxLen: '用户名长度不能超过50位',
  loginPassPlaceholder: '请输入密码',
  loginPass: '密码',
  rememberPass: '记住密码',
  forgetPass: '忘记密码',
  login: '登录',
  toRegister: '还没有账号？去注册',
  signingIn: '正在登录...',
  welcomeRegister: 'Hi，欢迎注册',
  registerUsername: '请输入用户名',
  tel: '手机号码',
  registerQQPlaceholder: '请输入微信/QQ（选填）',
  registerEmail: '请输入邮箱（选填）',
  inviteCodePlaceholder: '请输入邀请码（选填）',
  inviteCode: '邀请码',
  register: '注册',
  readAndKnow: '已阅读并同意',
  userPolicy: '《用户协议》',
  userPrivacy: '隐私协议',
  haveAccount: '已有账号？',
  toLogin: '去登录',
  userPolicyDialog: '用户服务协议',
  registerUsernameRule: '用户名最少6位',
  registerUsernameRule2: '用户名最多20位',
  inviteCodeRule: '邀请码最长不超过10位',
  policyValid: '请阅读并同意《火豹浏览器用户协议》《隐私协议》',
  policyValid1: '请阅读并同意《购买设备服务协议》《设备购买须知》',
  buyAgentTip1: '购买设备服务协议',
  buyAgentTip2: '设备购买须知',
  registerSuccess: '注册成功，是否立即下载软件客户端？',
  downloadNow: '立即下载',
  downloadLater: '稍后下载',
  registerAndLogin: '注册成功，请登录',
  resetPass: '重置密码',
  resetConfirm: '确认修改',
  resetSuccess: '密码重置成功',
  '2fAuth': '登录双重验证',
  otherSetting: '密码验证',
  opendeleteWindowvVer: '开启彻底删除店铺密码验证',
  enable2fa: '开启双重验证',
  enableConfirm:
    '确认开启双重验证吗？<p class="q-mt-sm">开启后，您在登录软件时将需要通过登录双重验证。</p><p class="q-mt-sm text-red">注意：请确保您当前手机号码可以正常接收短信，以防止因无法接收短信而导致无法通过双重验证！</p>',
  disable2faConfirm: '确认关闭双重验证吗？<p class="q-mt-sm">关闭后，您仅需要使用用户名密码即可登录。</p>',
  enablePasswordVerification:
    '确认开启彻底删除店铺密码验证吗？<p class="q-mt-sm text-red">开启后，在删除店铺/设备时将需要进行密码验证。</p>',
  disablePasswordVerification:
    '确认关闭彻底删除店铺密码验证吗？<p class="q-mt-sm text-red">关闭后，在删除店铺/设备时不需要输入密码进行验证。</p>',
  enableTransPasswordVerification: '确认开启转移店铺密码验证吗？<p class="q-mt-sm text-red">开启后，在转移店铺时将需要进行密码验证。</p>',
  disableTransPasswordVerification: '确认关闭转移店铺密码验证吗？<p class="q-mt-sm text-red">关闭后，在转移店铺时将需要进行密码验证。</p>',
  authenticator: '验证器',
  bind: '绑定',
  unbind: '解绑',
  bound: '已绑定',
  secretKey: '验证器密钥',
  googleAuthCode: '验证器验证码',
  googleCodePlaceholder: '请输入验证器生成的验证码',
  bindTitle: '绑定验证器',
  scanQrCode: '扫描二维码',
  authValidation: '验证绑定',
  scanTips: '温馨提示：使用验证器扫描下方二维码或者复制密钥使用。',
  unbindTitle: '解绑验证器',
  recommended: '推荐使用的验证器',
  googleAuthenticator: '谷歌验证器',
  microsoftAuthenticator: '微软验证器',
  tencentAuthenticator: '腾讯验证器',
  sms: '短信验证',
  validationMethod: '验证方式',
  smsCode: '手机验证码',
  requiredEmail: '请输入邮箱',
  emailPlaceholder: '请输入邮箱',
  emailVerification: '邮箱验证',
  phoneVerification: '手机验证',
  userProtocol: '火豹浏览器用户协议',
  privacy: '隐私协议',
  join: '邀请您加入',
  applyJoin: '申请加入',
  pendingReview: '等待审核',
  assistanAwitTip1: '申请已提交，你已成功提交加入 {company} 的申请。',
  assistanAwitTip2: '需要等待审批，结果以短信形式通知，请留意短信！',
  assistanAwitTip3: '审核通过后，会向你填写的手机号上发送成功通知，请注意查收！',
  assistanAwitTip4: '可使用申请时所填写的用户名、密码登录火豹浏览器。',
  assistanExcedd: '当前链接已失效，请联系你的邀请人重新生成邀请链接！',
  securitySetting: '安全设置',
  vistualTelTips:
    '使用虚拟手机号码注册的账号，有被盗风险，在登录到系统时将会触发安全验证，请确保此号码可随时接收到短信验证码；强烈建议使用实体手机号码注册账号。',
  secureAuth: '身份安全验证',
  usePrivacy: '用户隐私协议',
  loginPhoneTip: '填写手机号时,请勿输入区号',
  passwordLogin: '密码登录',
  VerificationLogin: '验证码登录',
  selectUser: '选择登录账号',
  pleaseSelectUser: '请选择登录账号',
  bindPhone: '绑定手机号',
  telAuthentication: '手机号认证成功',
  telAuthenticationTitle: '手机号认证',
  accreditation: '认证',
  authenticated: '已认证',
  phoneEmailLoginTip: '注：可以直接使用已认证成功的手机号登录{arg}。',
  notCertified: '未认证，',
  currentEmail: '当前邮箱',
  openTransBrowserPassword: '转移店铺密码验证',
  deleteBrowserTip: '删除店铺/设备密码验证',
  changePassword: '原密码修改',
  changeVerificationCode: '手机/邮箱验证码修改'
}
