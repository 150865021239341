import { LocalStorage } from 'quasar'
import validateLocales from 'src/i18n/validateLocales'

// 获取语言，默认zh
function getLocale() {
  return LocalStorage.getItem('locale') || 'zh'
}

/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:|\/\/)/.test(path)
}

/**
 * @description 校验密码是否小于6位
 * @param value
 * @returns {boolean}
 */
export function isPassword(value) {
  return value.length >= 6
}

/**
 * @description 判断是否为数字
 * @param value
 * @returns {boolean}
 */
export function isNumber(value) {
  const reg = /^[0-9]*$/
  return reg.test(value)
}

/**
 * @description 判断是否为正整数
 * @param value
 * @returns {boolean}
 */
export function isPositiveInteger(value) {
  const reg = /^[1-9]\d*$/
  return reg.test(value)
}
/**
 * @description 判断是否是名称
 * @param value
 * @returns {boolean}
 */
export function isName(value) {
  const reg = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/
  return reg.test(value)
}

/**
 * @description 判断是否为IP
 * @param ip
 * @returns {boolean}
 */
export function isIP(ip) {
  const reg =
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
  return reg.test(ip)
}

/**
 * @description 判断是否是host，无http/https开头
 * @param host
 * @returns {boolean}
 */
export function isHost(host) {
  const reg =
    /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|dev|live|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(host)
}

/**
 * @description 判断是否是传统网站
 * @param url
 * @returns {boolean}
 */
export function isUrl(url) {
  const reg =
    /^(http|https):\/\/[\w\-_\u4E00-\u9FA5:/]+(\.[\w\-_\u4E00-\u9FA5]+)+([\u4E00-\u9FA5\w\\n\\r\-.,@?^=%&:/~+#]*[\u4E00-\u9FA5\w\\n\\r\-@?^=%&/~+#])?$/
  return reg.test(url)
}

/**
 * @description 判断是否是小写字母
 * @param value
 * @returns {boolean}
 */
export function isLowerCase(value) {
  const reg = /^[a-z]+$/
  return reg.test(value)
}

/**
 * @description 判断是否是大写字母
 * @param value
 * @returns {boolean}
 */
export function isUpperCase(value) {
  const reg = /^[A-Z]+$/
  return reg.test(value)
}

/**
 * @description 判断是否是纯英文字母
 * @param value
 * @returns {boolean}
 */
export function isPureEnglishLetter(value) {
  const reg = /^[A-Za-z]+$/
  return reg.test(value)
}

/**
 * @description 判断是否是大写字母开头
 * @param value
 * @returns {boolean}
 */
export function isAlphabets(value) {
  const reg = /^[A-Za-z]+$/
  return reg.test(value)
}

/**
 * @description 判断是否是字符串
 * @param value
 * @returns {boolean}
 */
export function isString(value) {
  return typeof value === 'string' || value instanceof String
}

/**
 * @description 判断是否是数组
 * @param arg
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @description 判断是否是对象
 * @param arg
 */
export function isObject(arg) {
  return Object.prototype.toString.call(arg) === '[object Object]'
}

/**
 * @description 判断是否是端口号
 * @param value
 * @returns {boolean}
 */
export function isPort(value) {
  const reg = /^([0-9]|[1-9]\d|[1-9]\d{2}|[1-9]\d{3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/
  return reg.test(value)
}

/**
 * @description 判断是否是手机号
 * @param value
 * @returns {boolean}
 */
export function isPhone(value) {
  const reg = /^1\d{10}$/
  return reg.test(value)
}

/**
 * @description 判断是否是身份证号(第二代)
 * @param value
 * @returns {boolean}
 */
export function isIdCard(value) {
  const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  return reg.test(value)
}

/**
 * @description 判断是否是邮箱
 * @param value
 * @returns {boolean}
 */
export function isEmail(value) {
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  return reg.test(value)
}

/**
 * @description 判断是否中文
 * @param value
 * @returns {boolean}
 */
export function isChina(value) {
  const reg = /^[\u4E00-\u9FA5]{2,4}$/
  return reg.test(value)
}

/**
 * @description 判断是否为空，null要使用双等号，同时判断 null和undefined，直接输入的是number类型时，直接返回false
 * @param value
 * @returns {boolean}
 */
export function isBlank(value) {
  if (typeof value === 'number') return false
  return value == null || false || value === '' || value.trim() === '' || value.toLocaleLowerCase().trim() === 'null'
}

// 通用输入，中英文和数字
export function isNormalInput(val) {
  return /^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(val)
}

/**
 * @description 判断是否为固话
 * @param value
 * @returns {boolean}
 */
export function isTel(value) {
  const reg = /^(400|800)([0-9\\-]{7,10})|(([0-9]{4}|[0-9]{3})([- ])?)?([0-9]{7,8})(([- 转])*([0-9]{1,4}))?$/
  return reg.test(value)
}

/**
 * @description 判断是否为数字且最多两位小数
 * @param value
 * @returns {boolean}
 */
export function isNum(value) {
  const reg = /^\d+(\.\d{1,2})?$/
  return reg.test(value)
}

/**
 * @description 判断是否为json
 * @param value
 * @returns {boolean}
 */
export function isJson(value) {
  if (typeof value === 'string') {
    const obj = JSON.parse(value)
    return !!(typeof obj === 'object' && obj)
  }
  return false
}

// 重置字段
export function resetFields(fields) {
  if (isArray(fields)) return []
  if (isString(fields)) return ''
  if (isNum(fields)) return null
  if (fields == null) return null
  // 均不是以上类型，则是Object
  const newObj = {}
  for (let [key, value] of Object.entries(fields)) {
    newObj[key] = resetFields(value)
  }
  return newObj
}

/**
 * @description 校验姓名
 * */
export function validateName(val) {
  const locale = getLocale()
  if (isBlank(val)) return validateLocales[locale].nameRequired
  if (val.length > 20) return validateLocales[locale].nameLenLimit
  if (!isNormalInput(val)) return validateLocales[locale].nameSchema
  return true
}

/**
 * @description 校验用户名
 */
export function validateUsername(val) {
  const locale = getLocale()
  if (isBlank(val)) return validateLocales[locale].usernameRequired
  if (val.length > 20) return validateLocales[locale].usernameLenLimit
  if (!/^[a-zA-Z0-9_@]+$/.test(val)) return validateLocales[locale].usernameSchema
  return true
}

/**
 * @description 校验密码
 */
export function validatePassword(val) {
  const locale = getLocale()
  if (isBlank(val)) return validateLocales[locale].passRequired
  if (val.length < 6) return validateLocales[locale].passMinLen
  if (val.length > 30) return validateLocales[locale].passMaxLen
  return true
}
/**
 * @description 校验密码（注册，修改和登录的校验不一样）
 */
export function resetValidatePassword(val) {
  const locale = getLocale()
  if (isBlank(val)) return validateLocales[locale].passRequired
  if (!/^\S*$/.test(val)) return validateLocales[locale].spaceError
  if (!/(?=.*[!@#$%^&*()_+{}\[\]:;<>,=.?~\\-])(?=.*\d)(?=.*[a-zA-Z])/.test(val) || val.length < 8 || val.length > 30)
    return validateLocales[locale].speicalCharacters
  return true
}
/**
 * @description 校验手机
 * */
export function validateTelphone(nationCode) {
  const locale = getLocale()
  return function (val) {
    if (isBlank(val)) return validateLocales[locale].telRequired
    if (!isNumber(val)) return validateLocales[locale].telBeNumber
    if (val.length > 20) return validateLocales[locale].telMax
    if (nationCode === '86' && !isPhone(val)) return validateLocales[locale].telInvalid
    return true
  }
}

/**
 * @description 校验可选的手机号
 * */
export function validateOptionalTelphone(nationCode) {
  const locale = getLocale()
  return function (val) {
    if (isBlank(val)) return true
    if (!isNumber(val)) return validateLocales[locale].telBeNumber
    if (val.length > 20) return validateLocales[locale].telMax
    if (nationCode === '86' && !isPhone(val)) return validateLocales[locale].telInvalid
    return true
  }
}

/**
 * @description 校验可选的Email
 * */
export function validateOptionalEmail(val) {
  const locale = getLocale()
  if (isBlank(val)) return true
  if (!isEmail(val)) return validateLocales[locale].emailInvalid
  if (val.length > 50) return validateLocales[locale].emialMaxLen
  return true
}

/**
 * @description 校验可选的qq/微信
 * */
export function validateOptionalQq(val) {
  const locale = getLocale()
  if (isBlank(val)) return true
  if (!/^[\w\d]+$/.test(val)) return validateLocales[locale].qqInvalid
  if (val.length > 30) return validateLocales[locale].qqMaxLen
  return true
}

/**
 * @description 校验短信，邮箱验证码
 */
export function validateEmailCode(val) {
  return /^\d{6}$/.test(val)
}

/**
 * @description 统一社会信用代码
 */
export function validateCreditCode(val) {
  return /^[0-9A-Z]{18}$/.test(val)
}
