export default {
  all: '全部员工',
  employeeApproval: '员工审批',
  departmentManagement: '部门管理',
  roleManagement: '角色管理',
  authorizedStores: '授权店铺',
  add: '添加员工',
  addDeparment: '添加部门',
  invite: '邀请员工',
  numTip: '共有{arg1}员工',
  pleaseDepartment: '请选择部门',
  pleaseStatu: '请选择状态',
  batchOperation: '批量操作',
  enableEmployees: '启用员工',
  disableEmployees: '禁用员工',
  adjustingDepartments: '调整部门',
  checkedStaff: '已选员工：',
  checkedStaff1: ' 已选择{arg1}个员工',
  adjustingRoles: '调整角色',
  adjustingRolesTip: '调整角色后，员工当前的角色将变更为新角色，角色不同对应的权限不同，请谨慎操作！',
  deleteEmployee: '删除员工',
  enable: '启用',
  disable: '禁用',
  tel: '手机号',
  department: '部门',
  rejectReason: '拒绝原因',
  approvedBy: '审批人',
  approvalTime: ' 审批时间',
  pleaseEmployee: '请选择操作员工',

  verifyMarket1: '已离职员工',
  verifyMarket2: '非公司员工',
  verifyMarket3: '资料填写有误',
  verifyMarket4: '其他原因',
  username: '用户名',
  lastTime: '最后登录时间',
  disableTip: '确定启用所选员工吗？启用后将恢复所有店铺权限！',
  disableTip1: '确定禁用所选员工吗？禁用后将无法登录软件！',
  enableSuccess: '员工启用成功',
  disableSuccess: '员工禁用成功',
  role: '角色',
  view: '查看员工',
  handoverStore: '交接店铺',
  deleteTip: '确定删除所选的员工吗？删除后不可恢复，请谨慎操作！',
  selectedEmployee: '已选员工',
  inviteTip: '方法一：通过链接邀请',
  inviteTip1: '您可复制下方的邀请链接，分享至微信、钉钉等，员工打开链接即可加入公司。',
  inviteTip2: '重置后，之前分享的二维码将失效',
  inviteTip3: '方法二：通过二维码邀请',
  inviteTip4: '您可分享二维码至群里，或下载打印出来，员工可扫码加入。',
  copy: '复制链接',
  resetUrl: '重置链接',
  download: '下载',
  resetQr: '重置二维码',
  companyName: '公司名',
  addStaffTip: '登录软件请使用完整用户名，格式：用户名',
  addStaffTip1: '全部登录终端自动永久授权',
  addStaffTip2: '首登终端自动永久授权，后续终端需要上级审批',
  addStaffTip3: '每次登录新终端，都需要上级审批',
  addStaffTip1_0: '员工使用任何终端登录软件，都可以直接登录，不需要授权，适用于离职可能性较低的员工。',
  addStaffTip2_0: '员工第一次登录软件使用的终端，不需要要授权，可以直接登录，后续换了终端登录，需要上级授权才可以登录。',
  addStaffTip3_0: '员工每次使用新的终端登录软件，都需要上级审批，才可以登录。',
  addStaffTip4: '不限制登录时间',
  addStaffTip5: '限制登录时间',
  pleaseStartTime: '请选择开始时间',
  pleaseEndTime: '请选择结束时间',
  pleaseStartDate: '请选择开始日期',
  pleaseEndDate: '请选择结束日期',
  adjustingRoleSuccess: '角色调整成功',
  authorizedtip: '请选择要授权的店铺账号',
  authorizedtip1: ' 已选{arg1}名成员，将授权以上{arg2}个账号（不影响原先已授权的账号）',
  pleaseSelectUser: '请选择被授权员工',
  pleaseSelectStore: '请选择授权店铺',
  currentEmployee: '当前员工',
  exchangeShopTip: '请选择需要交接的店铺（总计：{arg1}个）',
  pleaseEmployeeName: '请输入用户名/姓名',
  clear: '清空已选',
  approved: '审批通过',
  viewUser: '查看用户',
  userDetail: '员工详情',
  editUser: '编辑用户',
  applicantApproval: '员工审批',
  adjustingDepartmentsSuess: '部门调整成功',
  pass: '通过',
  fail: '不通过',
  selectUser: '已选择{arg1}个员工',
  failTip: '请输入其他原因(非必填)',
  pleaseUserName: '请输入用户名',
  departmentName: '部门名称',
  departmentName1: '部门名称：',
  departmentName2: '子部门名称',
  pleaseDepartmentName: '请输入部门名称',
  newDepartment: '新建部门',
  editDepartmen: '编辑部门',
  addSub: '添加子部门',
  addSuess: '部门创建成功',
  addSubSuccess: '子部门添加成功',
  modifySubSuccess: '修改部门成功',
  transferringEmployees: '转移员工',
  deleteDepartment: '删除部门',
  batchDeleteDep: '批量删除部门',
  batchDeleteRole: '批量删除角色',
  departmentHeadcount: '部门人数',
  pleaseBatchDelete: '请选择批量删除的部门',
  pleaseBatchDelete1: '请选择批量删除的角色',
  viewPermissions: '查看权限',
  editRole: '编辑角色',
  deleteTip1: '确定删除所选的角色吗？删除后不可恢复！',
  deleteTip2: '确定删除当前角色吗？删除后不可恢复！',
  currentRole: '当前角色',
  deleteTip3: '确定删除所选的部门吗？删除后不可恢复！',
  deleteTip4: '确定删除当前部门吗？删除后不可恢复！',
  roleIdentity: '角色身份',
  numberEmployees: '员工数',
  roleMark: '角色备注',
  pleaseMark: '请输入备注',
  staff: '普通员工',
  admin: '管理员',
  currentlyDep: '当前所选部门：',
  transferTo: '转移至',
  nameRule: '部门名称不能超过8个字符',
  authenticationTip:
    '根据国家工信部规定，火豹浏览器客户需要进行企业或个人认证，请您仔细填写资料，认证成功后信息不可修改，该信息仅用于认证，平台方不会泄露。',
  enterpriseCertification: '企业认证',
  authenticationTip1: '请谨慎填写资料，申请成功后，信息不可修改！该信息仅用于企业认证，平台方不会泄露。',
  successfullyApplied: '申请成功',
  enterpriseName: '企业名称',
  enterpriseType: '企业类型',
  pleaseEnterpriseName: '请输入企业名称',
  creditCode: '统一社会信用代码',
  pleaseCreditCode: '请输入统一社会信用代码',
  pleaseCreditCode1: '请输入正确的社会信用代码',
  businessLicense: '营业执照',
  authenticationTip2: '很遗憾，您提交的企业认证申请未通过，请重新申请！',
  reasonFailure: '失败原因',
  recertification: '重新认证',
  authenticationTip3: '您的企业认证申请已提交，飞速审核中，请耐心等待！',
  submittedSuccessfully: '提交成功',
  pleaseIdCard: '请输入身份证号',
  pleaseIdCard1: '输入正确的中国身份证',
  legalPerson: '法人信息',
  idCard: '法人身份证号',
  pleaseLegalPersonName: '请输入法人姓名',
  pleaseLegalPersonNameTip: '法人姓名最多50字符',
  companyNameMax: '企业名称最多50字符',
  enterCompanyName: '请输入企业名称',
  uploadID: '上传身份证',
  uploadImg: '文件格式限定为 jpg、png（请确保上传资料完整性，文字清晰可见，大小为5M以内)',
  uploadID1: '身份证头像所在面',
  uploadID2: '身份证国徽所在面',
  enterprise: '企业',
  selfEmployed: '个体户',
  imageMax5: '图片要小于5M',
  pleaseUploaadPicture: '请选择上传图片',
  buyAgentLimit: '实名认证成功后，设备购买数量不受限制！',
  authenticateNow: '立即认证',
  buyAgentLimit1: '购买设备限制',
  authenticateNowTip: '同时将支持使用更多服务！',
  authenticateNowTip3: '请进入“企业管理”菜单，点击“认证管理”选项卡，按要求填写内容完成认证！',
  authenticateNowTip1: '请联系您的主账号进行实名认证，实名认证后将不再弹出此对话框。',
  authenticateNowTip2: '7天内不再提醒',
  authenticateNowTitle: '实名认证通知',
  authentication: '认证管理',
  Personal: '个人认证',
  certificationSuccessful: '认证成功',
  certificationFailed: '认证失败',
  personalTip: '请填写正确的姓名、证件类型、证件号码',
  personalTip1: '请输入真实姓名',
  personalTip2: '请选择证件类型',
  personalTip3: '请输入证件号码',
  personalTip4: '认证步骤：',
  personalTip5: '使用支付宝“扫一扫”扫描生成的二维码，点击开始认证；',
  personalTip6: '根据支付宝的提示，在支付宝内完成刷脸认证。',
  personalTip7: '认证完成后，点击“已完成认证”，在个人认证面板中显示“认证成功”即完成认证！',
  personalTip7_1: '认证完成后，点击“已完成认证”。',
  nextStep: '下一步',
  Back: '上一步',
  personalTip8: '已完成认证',
  personalTip9: '请填写真实姓名',
  personalTip10: '姓名不能超过10个字符',
  personalTip11: '请填写证件号码',
  personalTip12: '请输入正确的证件号码',
  personalTip13: '证件号码长度不超过30位',
  IDtype: '证件类型',
  idNo: '证件号码',
  name: '姓名',
  pleaseRoleMsg: '没有角色，请先添加角色',
  authenticationFailed: '认证失败，请重新认证！',
  authenticationFailed1: '认证失败，请确认信息无误后重新认证！',
  certified: '待认证',
  currentDep: '当前部门',
  numPeople: '{arg}人',
  dept: '所在部门',
  transformSuccess: '转移成功',
  transformError: '请选择转移部门并且选择需要转移的员工',
  selectStopTip: '请选择要授权的店铺',
  toAuthen: '去认证',
  enterpriseSuccess: '企业认证成功',
  enterpriseErr: '企业认证失败',
  selectStopTip: '请选择要授权的店铺',
  replaceModifyEnterprise: '是否一键修改企业名称',
  replaceCreditCode: '是否一键修改社会信用代码',
  enterpriseCertificationTip: '需要法人使用支付宝完成人脸识别认证确认，是否继续认证',
  legalPersonNameTip: '法人姓名根据上传企业营业执照自动识别，无法修改，如识别不准确，请联系客服。',
  legalPersonIdentifyTip: '法人：{arg1} 使用支付宝扫码完成人脸识别认证',
  unbindPhone: '点击"确定"按钮后将解除（{username}）和（{telphone}）的绑定关系',
  underReview: '企业认证审核中，30分钟内审核完成，请您耐心等待！',
  agentVerification: '经办人认证',
  agentName: '经办人姓名',
  idCard: '身份证号',
  clickGoCertification: '点击“去认证”将使用您的支付宝进行刷脸识别认证',
  agentTip:
    '注意：只有成功完成经办人刷脸认证后，才可以继续进行下一步“企业信息核验”，企业信息核验主要包括：企业名称、统一社会信用代码、法人姓名、法人身份证件号等。',
  authenticationCompleted: '已完成认证',
  enterpriseVerification: '企业信息核验',
  enterLegalIdCard: '请输入法人身份证号',
  goEnterpriseCertification:
    '点击“去认证”将核验您填写的企业名称、统一社会信用代码、法人姓名、法人身份证号等，认证成功后，即可进行下一步的企业认证',
  auditFailed: '审核失败',
  selectEnterpriseAuthorization: '请选择您将要企业认证的方式',
  paymentTitle: '企业对公账户打款',
  paymentTitleTip: '使用企业银行账户向第三方平台银行账户发起反向打款，成功后认证完成。',
  paymentTip1: '企业对公打款流程',
  paymentTip2: '点击下方“获取账户”按钮，获取对公打款的账户信息。',
  paymentTip3: '请使用认证公司的对公银行账号向下方获取的账户打款指定金额。',
  paymentTip4: '注意：必须使用认证公司的对公银行账户，打款实际到账金额必须与获取的金额保持一致，否则认证失败。',
  paymentTip5: `打款完成请点击“已完成打款”然后等待认证，大约需要30分钟。`,
  getAccount: '获取账户',
  accountInformation: '账户信息',
  recipientName: '收款方公司名称',
  recipientAccount: '收款方账户',
  recipientBank: '收款方开户行',
  paymentAmount: '打款金额',
  legalAuthorization: '企业法人授权签署',
  legalAuthorizationTip: '通知企业法人并启动企业授权签署，完成签署任务后认证完成。',
  legalAuthorizationProcess: '企业法人授权签署流程',
  legalAuthorizationProcess1: '输入法人手机号，获取验证码，获取成功后，输入验证码。',
  legalAuthorizationProcess2: '点击“获取签署链接”按钮，获取链接地址/二维码（支持复制），使用【手机浏览器】打开地址后按步骤进行认证。',
  legalAuthorizationProcess3: '若已经完成签署授权认证，请点击“已完成授权”按钮。',
  legalAuthorizationProcess4: '当签署链接失效时，请重新输入法人手机号，获取签署链接。',
  getSigning: '获取签署链接',
  signingLink: '签署链接',
  paymentCompleted: '若已经完成打款，请点击“已完成打款”按钮',
  authorizationCompleted: '若已经完成签署授权认证，请点击“已完成授权”按钮',
  enterpriseCompleted: '恭喜您，已完成企业认证',
  paymentDone: '已完成打款',
  authorizationCompletedBtn: '已完成授权',
  legalPhone: '法人手机号',
  legalPersonName: '法人姓名',
  awaitCompletedPayment: '账户对公打款待完成，请完成后点击“已完成打款”按钮。',
  authorisationCertification: '签署授权认证待完成，请完成后点击“已完成授权”按钮。',
  shopNamePlaceholder: '请输入店铺名称',
  limitLoginDate: '员工只能在设置的开始日期到结束日期内正常登录，例如：2024/3/1-2024/3/10，超出该日期范围将自动登出！',
  limitLoginTime: '员工每天只能在设置的开始时间到结束时间内登录，例如：08:00:00-18:00:00，超出该时间范围将自动登出！',
  endDateRule: '结束日期必须晚于开始日期'
}
