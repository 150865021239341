export default {
  dialogTitle: 'Apply For Withdrawal',
  balance: 'Withdrawable Amount: ',
  payType: 'Withdrawal Method',
  payMoney: 'Amount',
  payMoneyPlaceholder: 'Please enter withdrawal amount',
  quickCheckPlaceholder: 'Quick selection items',
  quickCheck: 'Quick Selection',
  alipayPlaceholder: 'Please enter your Alipay account.',
  cardPlaceholder: 'Please enter your bank card No.',
  alipay: 'Alipay Account',
  cardNo: 'Bank Card No',
  paypal: 'PayPal Account',
  bankPlaceholder: "Please enter the bank's name",
  bank: 'Bank',
  usernamePlaceholder: 'Please enter your name',
  bankUsernamePlaceholder: 'Please enter your account name',
  username: 'Name',
  bankUsername: 'Account Name',
  moneyRuleMsg1: 'Amount is required.',
  moneyRuleMsg2: 'The withdrawal amount must be greater than 0 and not exceed two decimal places.',
  moneyRuleMsg3: 'Cannot exceed the withdrawable amount.',
  alipayRuleMsg1: 'The alipay account is required.',
  alipayRuleMsg2: 'The Alipay account is your mobile phone number or email address.',
  alipayRuleMsg3: 'The Alipay account can not exceed 50 characters.',
  alipayUsernameRule1: 'The name of Alipay account is required.',
  alipayUsernameRule2: 'The name of Alipay account can not exceed 30 characters.',
  cardRule1: 'Bank card No is required',
  cardRule2: 'Please enter valid bank card No.',
  cardRule3: 'The bank card No can not exceed 30 characters.',
  bankRule1: "The bank's name is required.",
  bankRule2: "The bank's name cannot contain special characters.",
  bankRule3: "The bank's name can not exceed 64 characters.",
  bankUsernameRule1: 'The account name is required.',
  bankUsernameRule2: 'The username of account cannot contain special characters.',
  bankUsernameRule3: 'The username of account cannot exceed 64 characters.',
  withBalance: 'Recharge To Balance For Subscribe/Renew',
  withCard: 'Withdraw To Bank Card',
  withAlipay: 'Withdraw To Alipay',
  withPaypal: 'Withdraw To PayPal',
  requestSuccess: 'Successful application, waitting for approval.',
  paypalPlaceholder: 'Please enter your PayPal account.'
}
