export default {
  seq: '序号',
  copyID: '复制ID',
  baseInfo: '基础设置',
  proxyInfo: '代理设置',
  preferenceInfo: '常用设置',
  fingerprintInfo: '指纹设置',
  clonePlaceholder: '输入克隆数量',
  batchAddPlaceholder: '输入批量创建数量',
  numberOfClones: '克隆数量',
  numberOfAdd: '创建数量',
  cloneNumTips: '克隆此店铺的数量',
  batchAddNumTips: '一次性批量创建此数量的店铺',
  genFingerprintType: '指纹生成方式',
  randomFingerprint: '随机指纹',
  cloneFingerprint: '克隆指纹',
  randomFpTips: '将随机生成UA与各项指纹参数',
  cloneFpTips: '将复制当前店铺的UA与指纹参数',
  namePlaceholder: '自定义浏览器店铺名称',
  namePrefix: '店铺名称前缀',
  browserName: '店铺名称',
  batchAddNameTips: '为该浏览器店铺命名，店铺名称将以此为前缀，组合店铺序号，生成最终的店铺名称，比如：批量创建店铺_1234',
  addNameTips: '为该浏览器店铺命名，方便以后的日常管理操作！',
  group: '店铺分组',
  groupSelect: '选择分组',
  groupPlaceholder: '选择分组',
  groupSelectTips: '注意：如果选择了其他账号下的分组，则该店铺将转移至其他账号的分组里（店铺归属权将属于其他账号）',
  noGroup: '未分组',
  duplicateValidation: '校验重复项',
  validByUsername: '根据用户名密码校验',
  validByUsernameTips:
    '账号平台、用户名、密码都不为空时才进行校验，会与自己的过去所有数据进行对比，前述3者完全相同时才判定为重复项且不会创建该店铺！',
  usernamePlaceholder: '设置平台登录用户名',
  platform: '账号平台',
  platformAuth: '账号密码',
  username: '用户名',
  usernameTips: '设置后打开浏览器店铺，指定平台的用户名将自动填充！',
  passwordPlaceholder: '设置平台登录密码',
  password: '密码',
  passwordTips: '设置后打开浏览器店铺，指定平台的密码将自动填充！',
  remarkPlaceholder: '请填写浏览器店铺备注',
  remark: '备注',
  cookiePlaceholder: '选填，支持数组包含JSON格式的Cookie，例如 {demo}',
  paste: '粘贴',
  cookieTips: '请输入正确的cookie格式',
  parseCookies: '尝试修复',
  otherCookies: '其他cookie',
  addOtherCookies: '添加其他cookie',
  otherCookiesPlaceholder: '其他cookie，可分次添加多个',
  add: '添加',
  randomAll: '一键随机生成指纹配置',
  addRuleMsg: '最多一次可批量创建1 - 300个店铺',
  cloneRuleMsg: '最多一次可克隆1 - 300个店铺',
  nameRuleMsg: '店铺名称最多50个字符',
  groupRuleMsg: '请选择分组',
  remarkRuleMsg: '备注最多500个字符',
  usernameRuleMsg: '用户名最多100个字符',
  passwordRuleMsg: '密码最多100个字符',
  toGroupConfirm: '您确定要跳转到分组管理吗？<br/>跳转后已填写的信息将丢失，请悉知。',
  editSuccess: '修改成功',
  addSuccess: '添加成功',
  validationErrMsg: '校验错误',
  pasteError: '粘贴出错，请手动粘贴',
  parseCookiesFailed: '修复失败',
  copySuccess: '复制成功',
  coreVersion: '浏览器内核版本',
  coreVersionTips: '建议选最新内核，若切换不同内核请清除缓存，以避免异常',
  coreVersionTips2: '2.4.1及以上版本有效',
  ostype: '设备',
  pcTips: '将该浏览器店铺环境模拟为PC端页面，同时生成PC端UA！',
  androidTips: '将该浏览器店铺环境模拟为安卓手机端页面，同时生成安卓手机端UA！',
  iosTips: '将该浏览器店铺环境模拟为苹果手机端页面，同时生成苹果手机端UA！',
  osPlaceholder: '请选择操作系统',
  os: '操作系统',
  osTips: 'navigator platform值',
  versionPlaceholder: '请选择浏览器版本',
  version: '浏览器版本',
  uaPlaceholder: '建议自动生成',
  changeBtn: '换一换',
  language: '语言',
  languageTips: '基于IP生成对应国家的浏览器语言，不开启可自定义',
  checkLanguage: '选择语言',
  displayLanguage: '界面语言',
  displayLanguageTips: '基于IP生成对应国家的浏览器界面语言，不开启可自定义',
  checkDisplayLanguage: '选择界面语言',
  displayLanguageValueTips: '默认跟随系统',
  timezone: '时区',
  timezoneTips: '基于IP生成对应的时区，不开启可自定义',
  checkTimezone: '选择时区',
  webrtcTips0: '开启WebRTC，将公网IP替换为代理IP，同时掩盖本地IP',
  webrtcTips1: '开启WebRTC，将使用当前电脑的真实IP',
  webrtcTips2: 'WebRTC被关闭，网站会检测到您关闭了WebRTC',
  ignoreHttps: '忽略HTTPS证书错误',
  ignoreHttpsTips: '忽略HTTPS证书错误直接打开网页',
  position: '地理位置',
  positionTips0: '网站会显示获取您当前位置的询问提示，您可以允许或禁止，与普通浏览器的提示一样',
  positionTips1: '网站请求获取您当前位置时，始终被允许',
  positionTips2: '网站请求获取您当前位置时，始终被禁止',
  positionValueTips: '基于IP生成对应的地理位置，不开启可自定义',
  lngPlaceholder: '请输入经度',
  lng: '经度',
  latPlaceholder: '请输入纬度',
  lat: '纬度',
  precisionPlaceholder: '请输入精度',
  precision: '精度（米）',
  windowSize: '店铺尺寸',
  openWidth: '店铺宽度',
  openHeight: '店铺高度',
  windowSizeTips: '设置店铺打开时的尺寸',
  resolution: '分辨率',
  windowSizeLimit: '约束店铺最大尺寸不可超过分辨率',
  pixelRatio: '显示缩放比例',
  fonts: '字体',
  edit: '编辑',
  canvasTips0: '同一电脑每个浏览器生成不同的Canvas',
  canvasTips1: '每个浏览器使用当前电脑默认的Canvas',
  webgl: 'WebGL图像',
  webglTips0: '同一电脑每个浏览器生成不同的WebGL',
  webglTips1: '每个浏览器使用当前电脑默认的WebGL',
  webglMeta: 'WebGL元数据',
  webglVender: 'WebGL厂商',
  webglRender: 'WebGL渲染',
  audioTips0: '同一电脑上每个浏览器生成不同的Audio',
  audioTips1: '每个浏览器使用当前电脑默认的Audio',
  mediaDevice: '媒体设备',
  mediaDeviceTips0: '使用相匹配的值代替您真实的媒体设备ID',
  mediaDeviceTips1: '每个浏览器使用当前电脑默认的媒体设备ID',
  speechTips0: '使用相匹配的值代替您真实的Speech Voices',
  speechTips1: '每个浏览器使用当前电脑默认的Speech Voices',
  doNotTrackTips: '设置浏览器是否愿意站点追踪个人信息',
  clientRectTips0: '使用相匹配的值代替您真实的ClientRects',
  clientRectTips1: '每个浏览器使用当前电脑默认的ClientRects',
  deviceInfo: '设备信息',
  deviceInfoTips0: '使用相匹配的值代替您真实的设备名称和MAC地址',
  deviceInfoTips1: '每个浏览器使用当前电脑默认的设备名称和MAC地址',
  computerNamePlaceholder: '自定义设备名称',
  computerName: '设备名称',
  macAddrPlaceholder: '请输入MAC地址',
  macAddr: 'MAC地址',
  portScan: '端口扫描保护',
  portScanTips: '开启后，将会阻止网站扫描您的本地服务端口',
  portWhiteList: '扫描白名单',
  portWhiteListTips: '允许被网站扫描的端口，多个用英文逗号隔开',
  portWhiteListPlaceholder: '输入整数，多个用英文逗号隔开',
  ssl: 'SSL指纹设置',
  sslTips: '开启后，禁用某些SSL特性可能会导致部分网址无法打开',
  disableSSL: '禁用SSL特性',
  plugins: '自定义插件指纹',
  pluginsTips: '开启后，自定义的插件可能会影响真实插件功能',
  pluginsInfo: '插件信息',
  pluginsDetails: '自定义插件信息 ({num}个)',
  cpu: '硬件并发数',
  cpuUnit: '核',
  cpuTips: '设置当前浏览器环境的CPU核心数',
  deviceMemory: '设备内存',
  deviceMemoryTips: '设置当前浏览器环境环境的机器内存',
  customizeFonts: '自定义字体',
  fontPlaceholder: '输入字体名搜索',
  customizePlugins: '自定义插件',
  addPlugin: '添加插件',
  editPlugin: '修改插件',
  reset2Default: '恢复默认',
  pluginName: '插件名称',
  pluginDesc: '描述',
  pluginFilename: '文件名称',
  pluginMime: 'mime 描述',
  pluginSuffixes: 'mime 扩展名',
  lngRuleMsg: '请输入合法的经度值，小数点后最多6位',
  latRuleMsg: '请输入合法的纬度值，小数点后最多6位',
  precisionRuleMsg: '请输入3 ～ 5000米范围内的精度值',
  languageRuleMsg: '请选择语言',
  timezoneRuleMsg: '请选择时区',
  portWhiteListRuleMsg: '请输入规范的端口',
  sslRuleMsg: '请选择禁用的特性',
  pluginNameRuleMsg: '请填写插件名称',
  pluginDescRuleMsg: '请填写插件描述',
  pluginFilenameRuleMsg: '请填写文件名称',
  pluginMimeRuleMsg: '请填写mime type',
  pluginMimeDescRuleMsg: '请填写mime 描述',
  pluginSuffixesRuleMsg: '请填写mime 扩展名',
  setSuccess: '指纹配置生成成功',
  switchCoreVersionMsg: '注意：切换浏览器内核后，若出现浏览器店铺功能异常的情况，请清空该浏览器店铺缓存后再试！',
  clearCacheAndSave: '清理缓存并保存',
  cancelSwitchAndSave: '不切换内核保存',
  batchUpdateConfig: '批量修改店铺配置',
  batchUpdateTips: '需要对哪项进行修改，请勾选该项最左侧的勾选框，然后再修改该项配置值，届时所有店铺的该项配置值将同时修改为您设的！',
  enable: '开启',
  disable: '关闭',
  replace: '替换',
  allow: '允许',
  forbidden: '禁止',
  default: '系统默认',
  customize: '自定义',
  random: '随机匹配',
  randomValue: '随机',
  followComputer: '跟随电脑',
  ask: '询问',
  urlPlaceholder: '网址前请加http://或https://或chrome-extension://',
  urlPlaceholder1: '请输入自定义平台域名',
  otherUrl: '设置常用网址',
  urlTips: '此处设置您的常用网址，会展示到浏览器工作台页面，便于快捷打开！',
  urlRule1: 'URL长度不能超过500个字符',
  urlRule2: '请输入正确的URL',
  urlRule3: 'URL已存在',
  urlRule4: '请输入网址后添加',
  urlRule5: '打开指定网址个数已经超过最大限度，禁止再添加！',
  urlRule6: '平台不支持http类型域名，请输入https的域名！',
  platformPlaceholder: '选择账号平台，选择底部“其他平台”可自定义',
  platformOthers: '其他平台',
  platformOtherTips:
    '请选择账号平台或选择“其他平台”自行设置网址。每次打开该浏览器店铺时，都将默认打开该账号平台网址，并将下面设置的用户名、密码自动填充到该平台的登录页。',
  platformUrl: '平台URL',
  platformUrlPlaceholder: 'URL前请加http://或https://，留空则什么也不打开！',
  platformRule1: '请选择账号平台',
  platformRule2: '平台地址URL不能超过100个字符',
  platformRule3: '请输入正确的URL',
  editUrl: '修改网址',
  editOtherUrl: '编辑指定网址',
  enterOsVersion: '请输入操作系统版本',
  osVersion: '操作系统版本',
  secretKeyRuleMsg1: '秘钥为数字或字母',
  secretKeyRuleMsg2: '秘钥最多100个字符',
  privacy: '隐私',
  privacyTips: '开启WebRTC，但禁止获取IP'
}
