export default {
  tips: 'Reposition and resize browser windows',
  reset: 'Reposition And Resize Browser Windows',
  startBtn: 'Start',
  box: 'Box',
  diagonal: 'Diagonal',
  startX: 'Start Position: ',
  windowSize: 'Size: ',
  width: 'Width:',
  height: 'Height:',
  col: 'Col:',
  space: 'Space: ',
  spaceX: 'Space X:',
  spaceY: 'Space Y:',
  offset: 'Offset: ',
  minSizeTips: 'Note: The min width of the browser window is 120px, and the min height is 40px.',
  startXRuleMsg: 'Start X is required.',
  startYRuleMsg: 'Start Y is required.',
  widthRuleMsg: 'Width is required.',
  heightRuleMsg: 'Height is required.',
  colRuleMsg: 'Col is required.',
  spaceXRuleMsg: 'Space X is required.',
  spaceYRuleMsg: 'Space Y is required.',
  offsetXRuleMsg: 'Offset X is required.',
  offsetYRuleMsg: 'Offset Y is required.',
  resetFlexable: 'Adaptive Arrangement',
  monitor: 'Monitor:'
}
