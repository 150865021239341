import jsSHA from 'jssha'

// 获取加密后的文本
export function getHash(text, format = 'HEX') {
  const shaObj = new jsSHA('SHA-1', 'TEXT')
  shaObj.update(text)
  return shaObj.getHash(format)
}

// utf8编码
export function encodeUtf8(str) {
  var bytes = []
  for (let ch of str) {
    // for...of循环，能正确识别 32 位的 UTF-16 字符， 可以查阅资料了解。
    let code = ch.codePointAt(0)
    if (code >= 65536 && code <= 1114111) {
      // 位运算， 补齐8位
      bytes.push((code >> 18) | 0xf0)
      bytes.push(((code >> 12) & 0x3f) | 0x80)
      bytes.push(((code >> 6) & 0x3f) | 0x80)
      bytes.push((code & 0x3f) | 0x80)
    } else if (code >= 2048 && code <= 65535) {
      bytes.push((code >> 12) | 0xe0)
      bytes.push(((code >> 6) & 0x3f) | 0x80)
      bytes.push((code & 0x3f) | 0x80)
    } else if (code >= 128 && code <= 2047) {
      bytes.push((code >> 6) | 0xc0)
      bytes.push((code & 0x3f) | 0x80)
    } else {
      bytes.push(code)
    }
  }
  return bytes
}
// 补位
function padStart(str, len, prefix) {
  return (new Array(len + 1).join(prefix) + str).slice(-len) //  也可用 new Array(len+1).fill(0)
}
// utf8解码
export function decodeUtf8(str) {
  let strValue = ''
  let obStr = [...str]
    .map(ch => {
      // 一位16进制数 转二进制 需要四位来表示  补全位数
      return padStart(parseInt(ch, 16).toString(2), 4, 0)
    })
    .join('')
    .match(/\d{8}/g)
    .map(item => parseInt(item, 2))
  for (var i = 0; i < obStr.length; ) {
    let code = obStr[i]
    let code1, code2, code3, code4, hex
    // 比较 前4 位 parseInt(11110000,2) = 240 4个字节
    if ((code & 240) == 240) {
      code1 = (code & 0x03).toString(2)
      code2 = padStart((obStr[i + 1] & 0x3f).toString(2), 6, '0')
      code3 = padStart((obStr[i + 2] & 0x3f).toString(2), 6, '0')
      code4 = padStart((obStr[i + 3] & 0x3f).toString(2), 6, '0')
      hex = parseInt(code1 + code2 + code3 + code4, 2)
      strValue = strValue + String.fromCodePoint(hex)
      i = i + 4
    } else if ((code & 224) == 224) {
      // 3个字节表
      code1 = (code & 0x07).toString(2)
      code2 = padStart((obStr[i + 1] & 0x3f).toString(2), 6, '0')
      code3 = padStart((obStr[i + 2] & 0x3f).toString(2), 6, '0')
      hex = parseInt(code1 + code2 + code3, 2)
      strValue = strValue + String.fromCodePoint(hex)
      i = i + 3
    } else if ((code & 192) == 192) {
      // 2个字节表
      code1 = (code & 0x0f).toString(2)
      code2 = padStart((obStr[i + 1] & 0x3f).toString(2), 6, '0')
      hex = parseInt(obStr + code2, 2)
      strValue = strValue + String.fromCodePoint(hex)
      i = i + 2
    } else {
      hex = code
      strValue = strValue + String.fromCodePoint(code)
      i = i + 1
    }
  }
  return strValue
}
// byte to hex
export function transferHex(bytes) {
  let s = ''
  bytes &&
    bytes.forEach(ch => {
      s = s + ch.toString(16)
    })
  return s
}

// utf8加解密demo
// const encodeStr = encodeUtf8('abc')
// console.log(transferHex(encodeStr))
// console.log(decodeUtf8(transferHex(encodeStr)))
