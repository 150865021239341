export default {
  all: 'All',
  employeeApproval: 'Approval',
  departmentManagement: 'Department',
  roleManagement: 'Role',
  authorizedStores: 'Authorized',
  add: 'Add',
  addDeparment: 'Add Department',
  invite: 'Invite',
  numTip: '{arg1} employees in total',
  pleaseDepartment: 'Select Department',
  pleaseStatu: 'Select Status',
  batchOperation: 'Batch operation',
  disableEmployees: 'Disable Employees',
  enableEmployees: 'Enable Employees',
  adjustingDepartments: 'Adjusting Departments',
  adjustingRoles: 'Adjusting Roles',
  adjustingRolesTip: `After adjusting the role, the employee's current role will be changed to a new one, and different roles correspond to different permissions. Please operate with caution!`,
  deleteEmployee: 'Delete Employee',
  successfullyApplied: 'Successfully applied',
  enable: 'Enable',
  disable: 'Disable',
  tel: 'Phone',
  department: 'Department',
  rejectReason: 'Reject Reason',
  approvedBy: 'Approved By',
  approvalTime: 'Approval Time',
  pleaseEmployee: 'Please select the operating employee',
  verifyMarket1: 'Resignated employees',
  verifyMarket2: 'Non company employees',
  verifyMarket3: 'Incorrect information filling',
  verifyMarket4: 'Other reasons',
  username: 'User Name',
  lastTime: 'Last Time',
  disableTip: 'Are you sure to activate the selected employee? After activation, all store permissions will be restored!',
  disableTip1: 'Are you sure to disable the selected employee? After disabling, you will not be able to log in to the store!',
  enableSuccess: 'Successfully enabled employee',
  disableSuccess: 'Employee disabled successfully',
  role: 'Role',
  view: 'View',
  handoverStore: 'Handover',
  deleteTip: 'Delete employee? Can’t undo. Proceed with caution.',
  selectedEmployee: 'Selected Employee',
  inviteTip: 'Method 1: Invite through a link',
  inviteTip1: 'You can copy the invitation link below to join the company.',
  inviteTip2: 'After resetting, the previously shared QR code will become invalid',
  inviteTip3: 'Method 2: Invite through QR code',
  inviteTip4: 'Employees can join through the shared QR code',
  copy: 'Copy Link',
  resetUrl: 'Reset Link',
  download: 'Download',
  resetQr: 'Reset QR code',
  companyName: 'Company Name',
  addStaffTip: 'Log in with full username:',
  addStaffTip1: 'Automatic permanent authorization',
  addStaffTip2: 'The first device is automatically and permanently authorized, and subsequent devices require approval from superiors',
  addStaffTip3: 'Every time a new device is logged in, approval from the superior is required',
  addStaffTip1_0:
    'Employees can log in directly to the system using any terminal, without authorization, and are suitable for employees with low likelihood of resignation.',
  addStaffTip2_0:
    'The first time an employee logs in to the terminal used by the system, they do not need authorization and can directly log in. Later, when logging in to a different terminal, they need authorization from their superiors to log in.',
  addStaffTip3_0:
    'Every time employees use a new terminal to log in to the system, they need approval from their superiors before they can log in.',
  addStaffTip4: 'Unrestricted login time',
  addStaffTip5: 'Restrict login time',
  pleaseStartTime: 'Please select a start time',
  pleaseEndTime: 'Please select the end time',
  pleaseStartDate: 'Please select a start date',
  pleaseEndDate: 'Please select the end date',
  adjustingRoleSuccess: 'Role adjustment successful',
  authorizedtip: 'Please select the store account to be authorized',
  authorizedtip1:
    '{arg1} member has been selected, and the above {arg2} accounts will be authorized (without affecting the previously authorized accounts)',
  pleaseSelectUser: 'Please select an authorized user',
  pleaseSelectStore: 'Please select an authorized store',
  currentEmployee: 'Current Employee',
  exchangeShopTip: 'Please select the stores that need to be handed over (total: {arg1})',
  pleaseEmployeeName: 'Enter UserName/Name',
  clear: 'Clear',
  approved: 'Approved',
  viewUser: 'View Users',
  userDetail: 'Employee Details',
  editUser: 'Edit User',
  applicantApproval: 'Applicant Approval',
  adjustingDepartmentsSuess: 'Successfully adjusted department',
  pass: 'Pass',
  checkedStaff: 'Check Employee:',
  checkedStaff1: '{arg1} employees selected',
  fail: 'Fail',
  selectUser: '{arg1} employees selected',
  failTip: 'Please enter another reason (not required)',
  pleaseUserName: 'Enter Username',
  departmentName: 'Department Name',
  departmentName1: 'Department Name:',
  departmentName2: 'Sub Department Name',
  pleaseDepartmentName: 'Enter Department Name',
  newDepartment: 'New Department',
  editDepartmen: 'Editing Department',
  addSub: 'Add Sub Department',
  transferringEmployees: 'Transferring Employees',
  deleteDepartment: 'Delete Department',
  batchDeleteDep: 'Batch Delete Department',
  departmentHeadcount: 'Department',
  pleaseBatchDelete: 'Select departments to be deleted in bulk',
  pleaseBatchDelete1: 'Please select the roles to be deleted in bulk',
  viewPermissions: 'View Permissions',
  editRole: 'Edit Role',
  deleteTip1: 'Are you sure to delete the selected role? Unrecoverable after deletion!',
  deleteTip2: 'Are you sure to delete the current role? Unrecoverable after deletion!',
  deleteTip3: 'Are you sure to delete the selected department? After deletion, it cannot be restored!',
  deleteTip4: 'Are you sure to delete the current department? After deletion, it cannot be restored!',
  roleIdentity: 'Role Identity',
  numberEmployees: 'Number of employees',
  roleMark: 'Role Notes',
  pleaseMark: 'Please enter a comment',
  staff: 'Ordinary Employees',
  batchDeleteRole: 'Batch Delete Roles',
  admin: 'Admin',
  currentlyDep: 'Currently Department:',
  transferTo: 'Transfer To',
  nameRule: 'Department name cannot exceed 8 characters',
  addSubSuccess: 'Successfully added sub department',
  modifySubSuccess: 'Successfully modified department',
  addSuess: 'Department created successfully',
  authenticationTip:
    'Fire Leopard Browser users must complete enterprise or personal authentication as mandated by the Ministry of Industry and Information Technology. Provided details are final upon successful authentication and are solely used for this purpose, with no disclosure by the platform.',
  enterpriseCertification: 'Certification',
  authenticationTip1: 'After successful application, the information cannot be modified!',
  enterpriseName: 'Enterprise Name',
  enterpriseType: 'Enterprise Type',
  pleaseEnterpriseName: 'Enter the company name',
  creditCode: 'Credit Code',
  pleaseCreditCode: 'Enter a unified social credit code',
  pleaseCreditCode1: 'Please enter the correct social credit code',
  businessLicense: 'Business License',
  authenticationTip2: 'Application not approved, please reapply',
  reasonFailure: 'Reason for failure',
  recertification: 'Recertification',
  authenticationTip3: 'Enterprise certification application has been submitted!',
  submittedSuccessfully: 'Submitted successfully',
  pleaseIdCard: 'Enter your ID card',
  pleaseIdCard1: 'Enter the correct Chinese ID card',
  legalPerson: 'Legal Person',
  legalPersonName: 'Name',
  idCard: 'Legal Person ID',
  pleaseLegalPersonName: 'Enter Legal Person Name',
  pleaseLegalPersonNameTip: 'Legal name with a maximum of 50 characters',
  companyNameMax: 'Enterprise name can have up to 50 characters',
  enterCompanyName: 'Please enter the company name',
  uploadID: 'Upload ID card',
  uploadImg: 'Clear images of png, jpg, and jpeg within 5M',
  uploadID1: 'ID Card Front',
  uploadID2: 'ID Card Back',
  enterprise: 'Enterprise',
  selfEmployed: 'self-employed',
  imageMax5: 'The picture should be less than 5M',
  pleaseUploaadPicture: 'Please select a picture',
  buyAgentLimit: 'After successful real name authentication, the number of device purchases is not limited!',
  authenticateNow: 'Authenticate Now',
  buyAgentLimit1: 'Restrictions on purchasing devices',
  authenticateNowTip: 'We will also support the use of more services!',
  authenticateNowTip1:
    'Please contact your main account for real name authentication. After real name authentication, this dialog box will no longer pop up.',
  authenticateNowTip2: 'No more reminders within 7 days',
  authenticateNowTip3:
    'Please enter the "Enterprise Management" menu, click on the "Certification Management" tab, and complete the certification according to the requirements!',
  authenticateNowTitle: 'Enterprise authentication notice',
  currentRole: 'Current role',
  authentication: 'Authentication',
  Personal: 'Personal',
  certificationSuccessful: 'Certification Successful',
  certificationFailed: 'Authentication Failed',
  personalTip: 'Please fill in the correct name, ID type, and ID number',
  personalTip1: 'Enter your real name',
  personalTip2: 'Select the type of document',
  personalTip3: 'Enter your ID number',
  personalTip4: 'Certification steps:',
  personalTip5: 'Use Alipay "Scan" to scan the generated QR code, and click to start authentication;',
  personalTip6: `According to Alipay's prompts, complete face brushing certification in Alipay.`,
  personalTip7: `After the authentication is completed, click "Completed Authentication" and display "Authentication Successful" in the personal authentication panel to complete the authentication!`,
  personalTip7_1: "After completing the authentication, click 'Completed Authentication'.",
  personalTip8: 'Certification completed',
  nextStep: 'next step',
  Back: 'Back',
  personalTip9: 'Please fill in your real name',
  personalTip10: 'Name cannot exceed 10 characters',
  personalTip11: 'Please fill in the ID number',
  personalTip12: 'Please enter the correct ID number',
  personalTip13: 'The length of the ID number should not exceed 30 digits',
  IDtype: 'ID Type',
  idNo: 'ID NO',
  name: 'Name',
  pleaseRoleMsg: 'No role, please add a role first',
  authenticationFailed: 'Authentication failed, please re authenticate!',
  authenticationFailed1: 'Authentication failed. Please confirm that the information is correct and re authenticate!',
  certified: 'To be certified',
  currentDep: 'Current department',
  numPeople: '{arg} people',
  dept: 'department',
  transformSuccess: 'Transfer successful',
  transformError: 'Please select the transfer department and select the employees who need to be transferred',
  selectStopTip: 'Please select the store you want to authorize',
  toAuthen: 'To authenticate',
  enterpriseSuccess: 'Enterprise certification successful',
  enterpriseErr: 'Enterprise authentication failed',
  unbindPhone: 'Clicking the "OK" button will unbind ({username}) and ({telphone})',
  replaceModifyEnterprise: 'Is it possible to modify the company name with just one click',
  replaceCreditCode: 'Is it possible to modify the social credit code with just one click',
  enterpriseCertificationTip:
    'Legal person is required to use Alipay to complete face recognition authentication confirmation. Continue authentication',
  legalPersonNameTip:
    "The legal representative's name is automatically recognized based on the uploaded business license and cannot be modified. If the recognition is not accurate, please contact customer service.",
  legalPersonIdentifyTip: 'Legal person: {arg1} Use Alipay scanning code to complete face recognition authentication',
  unbindPhone: 'Clicking the "OK" button will unbind ({username}) and ({telphone})',

  underReview: 'Enterprise certification audit, audit completed within 30 minutes, please wait patiently!',
  agentVerification: 'Agent verification',
  agentName: "Agent's name",
  idCard: 'ID card',
  clickGoCertification: 'Clicking "Go to certification" will use your Alipay for facial recognition authentication',
  agentTip: `Note: Only after successfully completing the agent's facial recognition authentication, you can proceed to the next step "Enterprise Information Verification," which includes company name, unified social credit code, legal person's name, and ID card number.`,
  authenticationCompleted: 'Authentication completed',
  enterpriseVerification: 'Enterprise information verification',
  enterLegalIdCard: `Enter the legal person's ID card`,
  goEnterpriseCertification: `Clicking "Go to certification" will verify the information you provided, including company name, unified social credit code, legal person's name, and ID card number. After successful authentication, you can proceed to the next step of enterprise`,
  auditFailed: 'Audit failed',
  selectEnterpriseAuthorization: 'Please choose the method of enterprise authorization',
  paymentTitle: `Payment to the company's public account`,
  paymentTitleTip:
    'Initiate a reverse payment from your corporate bank account to the third-party platform bank account. Certification is complete upon successful payment.',
  paymentTip1: 'Enterprise public payment process',
  paymentTip2: 'Click the "Get Account" button below to obtain the account information for public payment.',
  paymentTip3: `Please use the company's authenticated public bank account to transfer the specified amount to the account obtained below.`,
  paymentTip4: `Note: You must use the company's authenticated public bank account, and the actual payment amount must match the amount obtained. Otherwise, authentication will fail.`,
  paymentTip5: 'After completing the payment, click "Payment Completed" and wait for authentication, which takes approximately 30 minutes.',
  getAccount: 'Get account',
  accountInformation: 'Account information',
  recipientName: 'Recipient company name',
  recipientAccount: 'Recipient account',
  recipientBank: 'Recipient bank',
  paymentAmount: 'Payment amount',
  legalAuthorization: 'Corporate legal representative authorization signing',
  legalAuthorizationTip:
    'Notify the corporate legal representative and start the corporate authorization signing. Certification is complete after signing.',
  legalAuthorizationProcess: 'Corporate legal representative authorization signing process',
  legalAuthorizationProcess1: `Enter the legal person's mobile number, get the verification code, and after successful retrieval, enter the code.`,
  legalAuthorizationProcess2: `Click the "Get Signing Link" button to get the link address/QR code (copying is supported), open the address with your mobile browser and follow the steps to authenticate.`,
  legalAuthorizationProcess3: `If the signing link has been completed, click the "Authorization Completed" button.`,
  legalAuthorizationProcess4: `When the signing link is invalid, please re-enter the legal person's mobile number to obtain the signing link.`,
  getSigning: 'Get signing link',
  signingLink: 'Signing link',
  paymentCompleted: `If the payment has been completed, click the "Payment Completed" button`,
  authorizationCompleted: `If the signing authorization has been completed, click the "Authorization Completed" button`,
  enterpriseCompleted: 'Congratulations, enterprise certification completed',
  paymentDone: 'Payment Done',
  authorizationCompletedBtn: 'Authorization Completed',
  legalPhone: 'Legal Rep. Phone',
  awaitCompletedPayment: `Please click on the "Completed Payment" button when you have completed the payment to your account.`,
  authorisationCertification: `Signing authorisation certification to be completed, please complete and click on the "Completed Authorisation" button.`,
  shopNamePlaceholder: 'Enter the store name',
  limitLoginDate:
    'Employees can only log in normally within the set start date to end date, for example: 2024/3/1-2024/3/10. If they exceed this date range, they will automatically log out!',
  limitLoginTime:
    'Employees can only log in from the set start time to the end time every day, for example: 08:00:00-18:00:00. If they exceed this time range, they will automatically log out!',
  endDateRule: 'The end date must be later than the start date.'
}
