export default {
  add: 'Add',
  users: 'Users',
  assigned: 'Assigned',
  total: 'Total',
  role: 'Role: ',
  roleHead: 'Role',
  usernameHead: 'User Name',
  username: 'User Name: ',
  showBrowsers: 'To Browsers with user filter',
  resetPass: 'Reset User Password',
  resetPlaceholder: 'Please enter password',
  resetPassword: 'Password',
  confirmPasswordPlaceholder: 'Please confirm your password',
  confirmPassword: 'Confirm Password',
  rolePlaceholder: 'Please select a role(Add first if there is no roles)',
  namePlaceholder: 'Please enter name',
  name: 'Name',
  usernamePlaceholder: 'Please enter login username',
  passwordPlaceholder: 'Please enter login password',
  password: 'Password',
  tel: 'Phone Number',
  telPlaceholder: "Please enter user's phone number",
  emailPlaceholder: "Please enter user's email",
  email: 'Email',
  userType: 'Data Role',
  userType2Tips: 'Can manage all user accounts, browsers and groups',
  userType3Tips: 'Can manage user accounts, browsers and groups under the name.',
  userType4Tips: 'No user management permission, can operate browser data under the name.',
  members: 'Members',
  manager: 'Manager',
  noData: 'No Data',
  group: 'Group',
  belong: 'Belong To:',
  browserCount: 'Browsers: ',
  browserCountHint: 'Only the main account can alloc the browsers.',
  browserCountLabel: 'Allocated Quantity',
  groupDialogBtn: 'View',
  userGroup: 'User Group',
  userGroupTips:
    'Note: Select "User Group", and click "To Browsers" to "Browsers" page, it will filter browsers by "User Group" automatically.',
  browserCountTableHead: 'Browsers(Used/Total)',
  status: 'Status',
  createdTime: 'Created Time',
  passwordRuleMsg1: 'Please enter password',
  passwordRuleMsg2: 'Passwords must have at least 6 characters',
  passwordRuleMsg3: 'Passwords can not exceed 30 characters.',
  confirmPasswordMsg1: 'Please enter password again',
  confirmPasswordMsg2: 'Two passwords do not match',
  addDialogTitle: 'Add User',
  editDialogTitle: 'Update User',
  roleRuleMsg: 'Select Role',
  usernameRuleMsg: 'User name must have at least 6 characters.',
  emailRuleMsg: 'Please enter a valid email.',
  emailRuleMsg2: 'Email address can not exceed 50 characters.',
  browserCountRuleMsg: 'Please enter a number',
  browserCountRuleMsg2: 'The min number of browsers cannot be less than 0',
  browserCountRuleMsg3: 'The "Allocated Quantity" cannot exceed your allocatable quantity.',
  deleteConfirm:
    'Confirm to delete this user?<br/><span class="text-negative">The browsers and groups under the account will also be completely deleted, which cannot be recovered. Please be careful!</span>',
  deleteSuccess: 'User deleted.',
  resetSuccess: 'Success',
  activateConfirm: 'Conform to enable this user?',
  deactivateConfirm: 'Confirm to disable this user?',
  activateSuccess: 'Success',
  deactivateSuccess: 'Success',
  editSuccess: 'Success',
  addSuccess: 'Success',
  allBrowsers: 'All Browsers',
  ownBrowsers: 'User Own Browsers',
  userGroupDialogTitle: 'User Groups({name})',
  roles: 'Roles',
  addRole: 'Add Role',
  roleNamePlaceholder: 'Please enter a role name',
  roleName: 'Name',
  permission: 'Permission',
  roleNameRule1: 'Enter Role Name',
  roleNameRule2: 'Role name must have at least 8 characters.',
  roleNameRule3: 'Please enter English or numbers',
  editRoleTitle: 'Update Role',
  deleteRoleTitle: 'Delete Role',
  deleteUser: 'Delete User',
  addRoleTitle: 'Add Role',
  deleteRoleConfirm: 'Confirm to delete this role?<br/>It cannot be restored after deleted. Please be careful!',
  deleteRoleSuccess: 'Success',
  editRoleSuccess: 'Success',
  addRoleSuccess: 'Success',
  checkPermission: 'Please check permissions.',
  toBrowsers: 'To Browsers',
  groupHint: 'The group can be assigned to multiple users.',
  secondaryPasswordMsg: 'Please enter the password corresponding to this account to perform this operation!',
  securityVerification: 'Please complete security verification',
  securityVerification1: 'Slide Right to Fill Puzzle',
  securityVerification2: 'try again',
  secretKey: '2FA Key',
  secretKeyPlaceholder: 'Please enter the key',
  secretKeyTip: 'Secondary captcha generation for websites, similar to Google Authenticator.'
}
