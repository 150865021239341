export default {
  fillIn: '请输入',
  select: '请选择',
  pickFile: '选择文件',
  fillInUsername: '输入用户名',
  fillInGroupName: '输入分组名称',
  fillInInt: '请输入整数',
  fillInNum: '请输入数字',
  edit: '修改',
  cancel: '取消',
  confirm: '确定',
  action: '操作',
  loading: '加载中...',
  positiveInt: '请输入大于0的整数',
  notExcel: '请选择Excel文件上传',
  importSuccess: '导入成功',
  reset2default: '恢复默认设置',
  reset: '重置',
  save: '保存',
  saveSuccess: '保存成功',
  updateSuccess: '修改成功',
  checkAll: '全选',
  dontPopupAgain: '不再弹出',
  close: '关闭',
  numMax: '请填写不超过5位的整数',
  numMax1000: '请填写小于1000的整数',
  numMax10000: '请填写四位整数',
  numMin500: '请填写大于500的数字',
  numMax2000: '请填写小于2000的数字',
  numMin200: '请填写大于200的数字',
  numMin120: '请填写大于120的数字',
  numMin40: '请填写大于40的数字',
  searchPlaceholder: '输入内容搜索',
  search: '搜索',
  advancedSearch: '高级搜索',
  searchNow: '开始搜索',
  userPlaceholder: '选择员工账号搜索',
  image: '选择图片',
  back: '返回',
  filter: '筛选',
  next: '下一步',
  prev: '上一步',
  copy: '复制',
  copySuccess: '复制成功',
  deleteSuccess: '删除成功',
  clear: '清空',
  home: '返回主页',
  retry: '重试',
  minimize: '最小化至托盘',
  contain: '包含：',
  userPhone: '用户名/手机号'
}
