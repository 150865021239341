export default {
  loginRequestAuthorization: 'Login Request Authorization',
  loginLog: 'Login Log',
  operationLog: 'Operation Log',
  pleaseLoginTime: 'Please select a login time',
  pleaseActionTime: 'select the operation time',
  loginTime: 'Login Time',
  loginTime1: 'Login Time Limit',
  loginEmployee: 'Login Employee',
  pleaseContent: 'Please select content',
  details: 'Details',
  Area: 'Area',
  exit: 'Exit',
  code: 'Code',
  pass: 'Passed',
  fail: 'Fail',
  termination: 'Termination',
  rejected: 'Rejected',
  empower: 'Empower',
  refuse: 'Refuse',
  reauthorization: 'Reauthorization',
  userName: 'User Name',
  name: 'Name',
  device: 'Terminal',
  status: 'application status',
  time: 'Date',
  refuseTip: 'After rejection, the user cannot log in to the software. Are you sure you want to decline?',
  terminationTip: "The user's login permission to the authorized terminal will be terminated. Please proceed with caution.",
  terminationTip1: `The user's login permission to the authorized device will be terminated. Are you sure to terminate?`,
  refuseTip1: 'Authorization denied successfully',
  detailTitle: 'Employee Login Authorization',
  localNetwork: 'Local Network',
  mac: 'MAC Address',
  authorizationMethod: 'Method',
  permanently: 'Permanent',
  permanently2: 'By Date',
  noLimit: 'No Limit',
  fixedTime: 'Fixed Time',
  startTime: 'Start Time',
  endTime: 'End Time',
  startDate: 'Start Date',
  endDate: 'End Date',
  selectFixed: 'Please select a fixed date to log in',
  approvalSuccessful: 'Approval successful',
  pendingApproval: 'Pending Approval'
}
