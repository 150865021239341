import { openOrClose } from 'api/browser'
import { LocalStorage } from 'quasar'

const state = () => ({
  name: '',
  openedWindows: new Map(), // id集合
  updateAvailable: false, // 发现新版本
  showUpdateDialog: false, // 更新窗口
  downloadPercent: 0, // 下载进度
  logo: '',
  agentUrl: '', // 代理官网地址
  softUrl: '', // 软件下载地址
  webAppVersion: '',
  isMobile: '', //是否是移动端
  cnWebsit: 'https://www.firebrowser.cn',
  enWebsit: 'https://www.firebrowser.net',
  themeDark: LocalStorage.getItem('darkMode') || false
})

const getters = {
  name: state => state.name,
  openedWindows: state => state.openedWindows,
  openedWindowIds: state => new Set(state.openedWindows.keys()),
  isInBitBrowser: () => !!window.isInBitBrowser,
  updateAvailable: state => state.updateAvailable,
  showUpdateDialog: state => state.showUpdateDialog,
  downloadPercent: state => state.downloadPercent,
  logo: state => state.logo,
  agentUrl: state => state.agentUrl,
  softUrl: state => state.softUrl,
  webAppVersion: state => state.webAppVersion,
  isMobile: state => state.isMobile,
  cnWebsite: state => state.cnWebsit,
  enWebsite: state => state.enWebsit,
  clientVersion: state => state.clientVersion,
  themeDark: state => state.themeDark
}

const mutations = {
  WINDOW_OPENED(state, config) {
    state.openedWindows.set(config.id, config)
  },
  WINDOW_CLOSED(state, id) {
    state.openedWindows.delete(id)
  },
  // 刷新或其他原因导致重新加载时，同步客户端的数据
  SYNC_IDS(state, map) {
    state.openedWindows = map
  },
  UPDATE_AVAILABLE(state, available) {
    state.updateAvailable = available
  },
  SHOW_UPDATE_DIALOG(state, show) {
    state.showUpdateDialog = show
  },
  UPDATE_PERCENT(state, percent) {
    state.downloadPercent = percent
  },
  SET_OEM_INFO(state, info) {
    state.logo = info.logo
    state.name = info.title
    if (info.showAgentUrl === 1) {
      state.agentUrl = info.agentUrl
    }
  },
  SET_SOFT_URL(state, url) {
    state.softUrl = url
  },
  SET_WEB_VERSION(state, webVersion) {
    state.webAppVersion = webVersion
  },
  SET_ISMOBILE(state, flag) {
    state.isMobile = flag
  },
  SET_CLIENT_VERSION(state, payload) {
    state.clientVersion = payload
  },
  THEME_DARK(state, dark) {
    state.themeDark = dark
    LocalStorage.set('darkMode', dark)
  }
}

const actions = {
  windowOpened({ commit }, config) {
    openOrClose({ ids: [config.id], status: 1 })
  },
  windowClosed({ commit }, { id, urls }) {
    openOrClose({ ids: [id], status: 0, url: urls || '' })
  }
}

export default { state, getters, mutations, actions }
