export default {
  workbench: 'Workbench',
  chuhai2345: '2345 Navigation',
  localServer: 'Local Server',
  workbenchDisable: 'Disable',
  workbenchTips: 'Use the "Local Server" or "Disable" to save traffic.',
  abortImg: 'Abort Image',
  abortImgTips: 'When enabled, the browser will not load images, improving browsing speed and saving traffic.',
  abortMedia: 'Abort Video Autoplay/Payload',
  abortMediaTips: 'When enabled, autoplay and preloading will be disabled, and non-standard formats may be invalid.',
  muteAudio: 'Mute Audio',
  muteAudioTips: 'All video or audio will play in mute mode.',
  stopWhileNetError: 'Stop Opening While Net Error',
  stopWhileNetErrorTips: 'Stop opening the browser if the network is not smooth due to proxy or other reasons.',
  syncTabs: 'Sync Tabs',
  syncTabsTips: 'Synchronize browser opened tabs.',
  syncCookies: 'Sync Cookies',
  syncCookiesTips: 'Synchronize browser cookies to stay logged in.',
  syncIndexedDb: 'Sync IndexedDB',
  syncIndexedDbTips: 'Synchronizing IndexedDB, some sites will use IndexedDB to store login information.',
  syncLocalStorage: 'Sync Local Storage',
  syncLocalStorageTips: 'Synchronizing Local Storage, some sites will use Local Storage to store login information.',
  syncBookmarks: 'Sync Bookmarks',
  syncBookmarksTips: 'Synchronize browser bookmarks.',
  syncAuthorization: 'Sync Saved Autofill',
  syncAuthorizationTips: 'Synchronize browser saved username and password.',
  credentialsEnableService: 'Disable Autofill Pop-up',
  credentialsEnableServiceTips: 'If Enabled, Autofill will no longer pop up.',
  syncHistory: 'Sync History',
  syncHistoryTips: 'Synchronize browser access history.',
  syncExtensions: 'Sync Extension Data',
  syncExtensionsTips: 'Synchronize the extension data of this browser and keep the login status of the extension.',
  syncUserExtensions: 'Sync Extension Data Across Browsers',
  syncUserExtensionsTips:
    'The extension data will be synchronized between different browsers under the same user to maintain the login status.',
  clearCacheFilesBeforeLaunch: 'Clear Cache Dir Before Launch',
  clearCacheFilesBeforeLaunchTips: 'Clear all cache files before launch, and do not synchronize the cache saved by the server.',
  clearCookiesBeforeLaunch: 'Clear Cookies Before Launch',
  clearCookiesBeforeLaunchTips: 'Clear cookies before launch, and do not synchronize the cookies saved by the server.',
  clearHistoriesBeforeLaunch: 'Clear History Before Launch',
  clearHistoriesBeforeLaunchTips: 'Clear all the history before launch, and do not synchronize the history saved by the server.',
  randomFingerprint: 'Random Fingerprint Before Launch',
  randomFingerprintTips: 'Generate randomly Fingerprint before launch.',
  allowedSignin: 'Allowed Signin To Chrome',
  allowedSigninTips:
    'Disable to log in to Google websites such as Gmail without sign to Chrome(The login status can be synchronized between different computers).',
  enableBackgroundMode: 'Enable Background Mode',
  enableBackgroundModeTips: 'Disable to stop running in background mode, which can save some system resources.',
  disableGpu: 'Disable GPU',
  disableGpuTips:
    'When enabled, GPU hardware acceleration will be automatically enabled to improve browser performance if the system supports it.',
  settingTips: 'Tips: The preference value will apply to the corresponding property when creating a new browser.',
  disableTranslatePopup: 'Disable Translate Pop-up',
  disableTranslatePopupTips: 'When enabled, the browser will be prohibited from automatically popping up Google Translate'
}
