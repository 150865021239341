export default {
  unbindDevice: 'Are you sure you want to unbind the IP device bound to {arg1}?',
  unbindDeviceTip: 'Currently selected crosslines: {arg1}',
  unbindDeviceTip1: 'Are you sure you want to unbind the IP device bound to the selected crosslines?',
  deleteCompletelyConfirm:
    'Are you sure you want to completely delete the {arg1} crossline?<p class="text-red">Crossline deletion is irreversible, please proceed with caution!</p>',
  deleteCompletelyConfirm1:
    'Are you sure you want to completely delete the selected {arg1} crosslines?<p class="text-red">Crossline deletion is irreversible, please proceed with caution!</p>',
  unbindCrossTip: 'Are you sure you want to unbind the crossline bound to {arg1}?',
  buyAgentLimit: 'After successful real-name authentication, there is no limit to the number of crosslines you can purchase!',
  buyAgentLimit1: 'Crossline Purchase Limit',
  batchUnbind: 'Batch Unbind',
  deleteCross: 'Delete Crossline',
  bindIp: 'Bind IP Device',
  unbindIp: 'Unbind IP Device',
  name: 'Crossline Name',
  bindIpName: 'Device Name',
  status: 'Status',
  pleaseSelectCross: 'Please select a crossline',
  buy: 'Buy Crossline',
  pleaseInputName: 'Please enter a crossline name',
  agentIpName: 'IP Device Name',
  crossType: 'Crossline Type',
  crossLine: 'Crossline',
  viewCross: 'View Cross',
  crossPrice: 'Crossline Price',
  selectCrossTitle: 'Select crosslines to bind',
  selectCross: 'Selected crosslines',
  crossRenew: 'Crossline Renewal',
  currentCross: 'Current crossline:',
  bindCross: 'Bind Crossline',
  unbindCross: 'Unbind Crossline',
  bindCrossTip: 'Crossline already bound',
  buyTip:
    'Huobao Crossline provides dedicated bandwidth services from Guangzhou to Hong Kong. It greatly improves the speed and network stability of overseas IP devices. We recommend purchasing this service and binding it to your overseas IP devices. <div class="text-red">Note: Only overseas platform IP devices can be bound; mainland IP devices and self-owned IP devices cannot be bound!</div>',
  buyTip1:
    'All overseas IP devices on the platform default to include cross-border network acceleration function, greatly improving overseas IP network speed and stability!',
  buyCross: 'Buy Crossline',
  renewalTip2: 'Enable automatic renewal for the number of crosslines',
  renewalTip3: 'Disable automatic renewal for the number of crosslines',
  renewalTip4:
    '1. Automatic renewal will be performed 3 days before the crossline expires, and the renewal amount will be deducted from your account balance. Please ensure that your account balance is sufficient.',
  renewalTip5:
    '2. If the balance is insufficient and renewal fails, crosslines that are not renewed will be permanently canceled and cannot be retrieved.',
  renewalTip6: 'Cancel the automatic renewal of the selected crosslines. Are you sure you want to cancel?',
  deviceExpirationDate: 'Crossline Expiration Date',
  noBind: 'Not currently bound'
}
