export default {
  seq: 'Seq',
  activate: 'Subscribe',
  renew: 'Renew',
  add: 'Add',
  editBrowser: 'Update Stores',
  import: 'Batch Import Stores',
  batchAdd: 'Batch Add',
  batchUpdate: 'Batch Update',
  used: 'Added ',
  total: 'Total ',
  pkgBrowsers: 'Package Stores: ',
  pkgUsed: 'Added store: ',
  pkgAssigned: 'Assigned Stores: ',
  openCount: 'Times ',
  shareUser: 'Authorized to employee:',
  activatePkg: 'Subscribe',
  changePkg: 'Change Plan',
  placeholder1: 'Filter by user name',
  all: 'All',
  mine: 'Mine',
  commonly: 'Favorite',
  allStatus: 'All',
  opened: 'Opened',
  unOpened: 'Not Opened',
  share2me: 'Shared With Me',
  transform2me: 'Transferred To Me',
  group: 'Group',
  groups: 'Groups',
  noGroup: 'Ungrouped',
  checked: 'Checked: ',
  refreshData: 'Refresh Data',
  customizeColumns: 'Customize Columns',
  recycle: 'Recycle Bin',
  moreAction: 'More',
  openChecked: 'Open Selected',
  clone: 'Clone Selected',
  clone1: 'Clone',
  setCommonly: 'Set As Favorite',
  setCommonly1: 'Set As Favorite',
  cancelCommonly: 'Cancel Favorite',
  cancelCommonly1: 'Cancel Favorite',
  export: 'Export Stores',
  reopenAtPos: 'Reopen Stores At Current Position',
  reopenSelectedAtPos: 'Reopen Selected Stores',

  reopenAllAtPos: 'Reopen All Opened Stores',
  exportChecked: 'Batch Update Stores Data',
  exportBySeq: 'Export Stores By Seq',
  exportByGroup: 'Export Stores By Group',
  batchEdit: 'Batch Update Stores',
  updateGroupOfChecked: 'Update Group',
  updateProxyOfChecked: 'Update Proxy',
  updateRemarkOfChecked: 'Update Remarks',
  updatePropertiesOfChecked: 'Update All Properties Of Selected',
  closeBrowsers: 'Close Stores',
  closeChecked: 'Close Selected Stores',
  persistenceCookie: 'Non Persistence Cookie Settings',
  persistenceCookieTip: 'Do you want to set the effective time of non persistence cookies?',
  persistenceCookieTip1: 'Non persistent version cookie expiration time setting:',
  persistenceCookieTip2: 'Please enter a valid time value',
  persistenceCookieTip3:
    '1.Non persistence cookies refer to cookie files that will be cleared immediately after the stores window is closed.',
  persistenceCookieTip4: `2."Non persistence Cookie Settings" is mainly used to help users save temporary cookies within a certain time range. This reduces the number of repeated login operations for users`,
  persistenceCookieTip5:
    '3. After the save state is enabled, you can set the effective time for saving non persistence cookies. After setting, the saving time of non persistence cookies will be extended. ',
  customTime: 'Custom time',
  closeAll: 'Close All Stores',
  clearCaches: 'Clear Stores Cache',
  clearCachesAll: 'Clear Stores Cache (All)',
  clearCaches1: 'Clear Stores Cache',
  share: 'Share To Users',
  reopenAllAtPos: 'Reopen All Opened Stores',
  transform: 'Transfer To User',
  removeShared: 'Delete Shared Stores',
  deleteBrowsers: 'Delete Stores',
  deleteBrowsers1: 'Delete Stores',
  deleteCompletely: 'Delete Selected Stores',
  deleteCompletely1: 'Delete This Store',
  deleteCompletely2: 'Delete Store',
  moveToRecycle: 'Move Selected To Recycle Bin',
  moveToRecycle1: 'Move This To Recycle Bin',
  deleteCheckPwd: `<p class="text-bold q-mb-sm">If you need to disable the password verification, to <u class="cursor-pointer">Stores</u> to disable it! </p>`,
  belongTo: 'Belong To: ',
  noProxy: 'No Proxy',
  remark: 'Remark',
  lastOpenTime: 'Last Open Time: ',
  lastCloseTime: 'Last Close Time: ',
  updateBy: 'Modify Users:',
  lastUpdateTime: 'Update Time: ',
  userName: 'User: ',
  isOpening: 'Is Opening This stores!',
  browserDisabled: 'The number of available Stores is reduced, and the Stores are automatically invalidated.',
  opening: 'Opening',
  open: 'Open',
  goDown: 'Go Down',
  goUp: 'Go Up',
  goMiddle: 'Go Middle',
  editGroup: 'Update Group',
  editGroupWithSeq: 'Update Group (seq: {seq})',
  editNameWithSeq: 'Update stores Name (seq: {seq})',
  browserName: 'Name',
  addBorwserTip1: 'Unique verification of store name for page display.',
  editRemark: 'Update Remarks',
  editRemarkWithSeq: 'Update Remarks (seq: {seq})',
  editRemarkType: 'Update Method',
  replaceRemark: 'Replace',
  appendRemark: 'Append',
  appendRemarkTips: 'Note: New lines will be added based on the original remarks if "Append" method is used.',
  remarkContent: 'Content',
  exportFromSeq: 'Start Seq',
  minSeqPlaceholder: 'Please enter a number greater than 0',
  exportToSeq: 'End Seq',
  maxSeqPlaceholder: 'Please enter a number greater than the starting sequence',
  shareTitle: 'Share Stores',
  shareTips: 'The stores can be shared with multiple users, just enter the user name and click the "Add" button.',
  shareWith: 'Share To',
  shareAdd: 'Add',
  shareRemark: 'Remark',
  transformTitle: 'Transfer Stores',
  transformTips:
    'Tip: After transfer, the stores will completely belong to the transferred user, and you have no permission to see the Stores, please be careful!',
  transformTo: 'Transfer To Company ',
  transformDevice: 'Simultaneously transferring bound IP devices',
  groupName: 'Group Name',
  checkAll: 'Select All',
  loading: 'Loading...',
  checkGroup: 'Select Group',
  ruleNameMsg: 'stores name cannot exceed 50 characters',
  ruleRemarkMsg: 'Remark cannot exceed 500 characters',
  ruleMinSeqMsg: 'The starting number is greater than 0',
  ruleMaxSeqMsg: 'The end number is greater than the start number',
  usernameMinLength: 'The user name is at least 6 characters',
  usernameExists: 'Already added the user',
  remarkMaxLength: 'Remarks up to 100 max length',
  transformGroupMsg: 'Please enter transfered group name under the user',
  noAddPermission: 'You currently do not have permission to create a stores, please contact the account admin to subscribe.',
  setSuccess: 'Success',
  notInClient: 'Please install the client software and proceed opreation!',
  installText: 'Install Desktop Client',
  getExtensionFailed: 'Get extensions failed, please check your network.',
  getConfigFailed: 'Get configuration failed, please refresh and try again.',
  openAnyway:
    'The stores has been opened in another account, do you still want to open it? <p style="margin-top: 10px">Note: After repeated opening, different accounts will trigger synchronization operations after closing browser, it may cause data synchronization error!</p>',
  cloneMsg: 'Clone stores, only one is allowed, multi-selection is prohibited.',
  openAnyway2: 'The stores has been synchronously closed by other account. whether reopen it now?',
  selectBrowser: 'Please Select Stores.',
  noBrowserChecked: 'No Stores Selected.',
  freeAccountTips:
    'The free package will have certain restrictions, if you need unlimited use, please subscribe the charging package to use.',
  close: 'Close',
  checkedIncludesOpened: 'The selected stores contains opened Stores, please close the stores before proceeding.',
  editShareGroupMsg: 'Group of shared stores cannot be edited.',
  editNameSuccess: 'Stores Name Updated.',
  editRemarkSuccess: 'Remark Updated.',
  deleteConfirmMsg1: 'Are you sure to delete the store?',
  deleteConfirmMsg: 'Confirm deleting {arg1} stores? <br/>You can restore in stores recycle bin.',
  moveToRecycleSuccess: 'The stores has moved to recycle bin.',
  deleteCompletelyConfirm2:
    'Are you sure to delete all stores completely? <p class="text-red">After the store is completely deleted, it cannot be restored, please operate with caution! </p>',
  deleteCompletelyConfirm:
    'Are you sure you want to delete the selected {arg1} stores completely? <p class="text-red">After the store is completely deleted, it cannot be restored, please operate with caution! </p>',
  deleteCompletelyConfirm1:
    'Are you sure you want to delete the selected {arg1} stores completely? <p class="text-red">After the store is completely deleted, it cannot be restored, please operate with caution! </p>',
  deleteCompletelySuccess: 'Success',
  deleteShareConfirm: 'Confirm to delete the shared stores?',
  deleteShareSuccess: 'Success',
  handleInClient: 'Please handle in client.',
  clearCachesConfirm: 'Clearing the stores cache will invalidate all login status.<br/>Do you want to clear it?',
  clearCacheSuccess: 'Success',
  checkedHasClosed: 'The selected stores has closed.',
  allBrowserClosed: 'All stores has closed.',
  transformSuccess: 'Success',
  atleatShareOneUser: 'Fill in and add at least one user',
  shareSuccess: 'Success',
  exportConfirm: 'Confirm to export selected Stores?',
  exportFilename: 'Exported Stores.xlsx',
  browserHasOpened: ' The stores Has Opened.',
  exportFilenameWithSeq: 'Exported Stores {seq}.xlsx',
  batchOpenTitle: 'Batch Open Stores',
  batchOpenChecked: 'Select Stores: ',
  batchOpened: 'Opened: ',
  batchOpenFailed: 'Open Failed: ',
  toSetting: 'To "Settings", close or modify the trigger conditions.',
  batchOpening: 'Opening...',
  batchOpenTips: 'Note: After the pop-up closed, the unopened stores will no longer to open.',
  CPU使用率: 'CPU Utilization Rate',
  内存使用率: 'Memory Utilization Rate',
  缓存所在目录剩余空间小于: 'The directory remaining space of the cache is less than ',
  extractIpFailed: 'Extract agent ip failed, stop opening',
  batchUpdateDialog: 'Batch Update Stores',
  batchUpdateImport: 'For a single excel, the number of imported stores should not exceed 1000',
  batchUpdateComment: 'Instructions: ',
  batchUpdateComments:
    '1. Export the stores that needs to update information | 2. Modify the corresponding information based the exported ID | 3. Upload the modified Excel to the server',
  batchUpdateTips:
    'Note: Do not delete or modify the ID, the stores will not be recognized, and please keep same as before, if you want no update',
  proxyIp: 'Proxy Ip',
  createdTime: 'Created Time',
  pleaseCreatedTime: 'Please select the creation time',
  config: 'Config',
  fileImport: 'File Import',
  importExcel: 'Import Excel',
  downloadTemplate: 'Downlod Template',
  importTips: '*It also supports importing common fingerprint stores files.',
  downTemplateTips: 'Note',
  downTemplateTips2: 'The import template has been updated, please download the latest template to use.',
  checkFileErrMsg: 'Select File',
  downloadFilename: 'Import Stores Template.xlsx',
  uploadMaxSize: 'The upload max size is 20MB.',
  openProgressDialog: 'The Stores Is Opening...',
  browserOpened: 'The Stores Has Opened',
  searchItem: 'Search Item',
  setDefault: 'Set Default',
  searchNamePlaceholder: 'Enter stores name',
  searchUsernamePlaceholder: 'Enter user name',
  searchSeqPlaceholder: 'Enter seq (Multiple seqs are separated by commas)',
  setSeq: 'Seq',
  minSeq: 'Min Seq ',
  maxSeq: 'Max Seq',
  searchRemarkPlaceholder: 'Enter remarks',
  searchRemark: 'Remark',
  searchProxyPlaceholder: 'Select proxy method',
  searchHostPlaceholder: 'Enter host',
  searchBrowserStatus: 'Status',
  seqRuleMsg: 'Enter a number from 0 - 999999',
  seqSplitRuleMsg: 'Enter the integer seqs separated by commas',
  proxyRuleMsg: 'Select a valid proxy type',
  hostRuleMsg: 'Host up to 100 characters',
  ipRuleMsg: 'IP up to 100 characters',
  shareBtn: 'Shared Stores',
  shareDialog: 'My Shared Stores',
  shareBrowserNamePlaceholder: 'Enter Stores name',
  shareRemarkPlaceholder: 'Enter remarks',
  shareUsernamePlaceholder: 'Enter user name',
  shareHandlerUser: 'Operation User',
  shareCancel: 'Cancel',
  shareCancelTips: 'Do you want to cancel this stores sharing? <br/>After cancelled, the shared user will no longer see this stores.',
  shareCancelSuccess: 'Success',
  检查并同步扩展中: 'Checking And Syncing Extensions...',
  '扩展同步失败，请尝试重新启动': 'Sync Extensions Failed, Please Try To Relaunch App...',
  '可能因为网络不稳造成，请尝试在客户端的左上角切换线路后重试！':
    'It may be caused by unstable network. Please try to switch the line in the upper left corner of the client and try again!',
  开始获取IP: 'Getting Proxy IP...',
  '直连模式，网络畅通': 'No Proxy, smooth network...',
  IP代理成功: 'IP Agent Succeeded...',
  网络不通已停止打开浏览器: 'Network Error And Stop Opening...',
  正在同步指纹信息: 'Syncing Fingerprint config...',
  正在同步缓存配置: 'Syncing Cache Files...',
  指纹同步失败: 'Syncing Fingerprint Failed',
  '浏览器缓存文件创建失败，请重试': 'Cache Dir Creation Failed, Please Reopen Again',
  正在打开浏览器窗口: 'Opening Stores...',
  店铺已打开: 'The Stores Has Opened.',
  '正在更新浏览器内核，请稍后': 'Updating Stores, Please wait...',
  打开店铺发生异常:
    'Stores launch failed: 1. The system time may be inaccurate, please adjust it to the correct time; 2. The stores may be deleted by the antivirus software, please exit the antivirus software and reinstall it; 3. The 64-bit system is not compatible, download the 32-bit client and try again ;4. The Mac version supports macOS 10.15 Catalina and later; 5. The problem has not been solved after trying the above methods, please contact customer service!',
  缓存所在目录剩余空间小于: 'Stores {arg1} launch failed, the free space of the cache files located disk is less than {arg2}.',
  内存使用率超出: 'Stores {arg1} launch failed, the memory is greater than {arg2}.',
  同步IndexedDB失败: 'Sync IndexedDB Failed.',
  '同步Local Storage失败': 'Sync Local Storage Failed.',
  同步书签失败: 'Sync Bookmarks Failed.',
  同步历史记录失败: 'Sync History Failed.',
  同步已保存的密码失败: 'Sync Saved Password Failed.',
  同步Google账号信息失败: 'Sync Google Account Failed.',
  代理IP无法连接: 'Proxy IP cannot connect.',
  exportCookies: 'Export Cookies',
  exportSelectedCookies: 'Export Selected Stores Cookies',
  importShopTip: 'It also supports importing files from common e-commerce Stores.',
  exportCookiesBySeq: 'Export Stores Cookies By Seq',
  exportCookiesByGroup: 'Export Stores Cookies By Group',
  exportCookiesConfirm: 'Confirm to export the select stores cookies as txt?',
  importCookies: 'Batch Update Cookies',
  importCookiesTips: 'Note:',
  importCookiesTips1: '1. Save each cookie to a separate txt file, named after the stores Stores seq, such as 1234.txt;',
  importCookiesTips2: '2. Put all txt files into the same folder, name the folder Cookies, and then compress it into a zip file;',
  importCookiesTips3: '3. A maximum of 100 txt files can be imported at a time, and the size of the zip file cannot exceed 20MB;',
  importCookiesTips4:
    '4. After uploading, the cookie of the Stores corresponding to the seq will be replaced with the newly uploaded cookie.',
  notZip: 'Please select a zip file.',
  importCookiesUploader: 'The zip archive contains no more than 100 txt files.',
  updateCookiesSuccess: 'Cookie updated successfully.',
  Recently: 'Recently Opened',
  renewal: 'Urgent Renewal',
  needBinding: 'Bind Required',
  needAuthorized: 'To Be Authorized',
  buyEquipment: 'Purchase IP',
  buyOwnDevice: 'Add Own IP',
  shop: 'Stores',
  importAttachment: 'Import Additional Accounts',
  device: 'Device',
  assignDevices: 'Assign Selected Stores Devices',
  bindDevice: 'Bind Device',
  bindDevice1: 'Bind Stores Device',
  unbindDevice: 'Unbind Device',
  unbindDevice1: 'Unbind Stores Device',
  authorizeSelect: 'Bind/Unbind Stores Devices',
  additionalManagement: 'Account Management',
  authorizedEmployees: 'Authorized Employees',
  morePlatforms: 'More Platforms',
  bindingSuccessful: 'Binding successful',
  unbindDeviceTip: '{arg1}  currently selected stores',
  unbindDeviceTip1: 'Confirm unbound devices for the selected store?',
  unbindSuccess: 'Unbind successfully',
  authorizationSuccess: 'Store authorization successful',
  pleaseBindDevice: 'Please bind device',
  pleaseSelectSiteName: 'Please select platform name',
  pleaseSelectSite: 'Please select a platform site',
  pleaseSelectSite2: 'Please select a platform',
  siteUrl: ' Platform Site',
  toChoose: 'To choose',
  platformName: 'Platform Name',
  PleaseShopName: 'Enter store name',
  SelectEmployee: 'Select Authorized Employees',
  PleaseShopMarke: 'Please enter store comments',
  isBindDevice: 'Bind device or not',
  yes: 'Yes',
  no: 'No',
  pleaseSelectDevice: 'Bind device or not',
  equipmentArea: 'Equipment Area',
  pleaseEquipmentArea: 'Select device region',
  isAuthorized: 'Authorized or not  ',
  pleaseSelectEmployee: 'Please select an authorized employees',
  loginAccount: 'Login Account',
  pleaseSelectEmployee1: 'Select An Employee',
  sharingRestrictions: 'Sharing restrictions',
  accountTip: '(If restrictions are selected, only one account is allowed to open the store at the same time)',
  accountTip1: `After selection,(the website account password filled in cannot be modified again)`,
  accountTip2:
    'The account has enabled account protection and cannot be modified. It only supports logging into the account of the current dropdown account. If you need to modify it, please contact the administrator',
  simpleMode: 'Simple Mode',
  expertMode: 'Expert Mode',
  advancedSetting: 'Advanced Setting',
  limitation: 'Limitation',
  noLimit: 'No Limit',
  所有平台: 'All platforms',
  电商后台: 'E-commerce backend',
  电商前台: 'E-commerce front desk',
  独立站: 'Independent station',
  支付平台: 'Payment Platform',
  邮箱: 'E-mail',
  其他: 'Other',
  自定义: 'Custom',
  details: 'Stores Details',
  deviceDetails: 'Device Details',
  lastLoginStatus: 'Last login',
  checkedDevice: 'Checked：',
  notSet: 'Not Set',
  pleaseAccountName: 'Enter account name',
  addExistingAccount: 'Add Existing Account',
  AddAccount: 'Add New Account',
  currentStore: 'Current Store:',
  checkedRole: 'Check Role:',
  addAccountTip: `Bound additional accounts will be displayed on the main store's dashboard page. The account, password, and other information from the additional accounts will be automatically filled into the login fields, allowing for quick access. It is recommended to add email, payment platform, and other accounts!`,
  editAccount: 'Edit Additional Account',
  addAdditionalAccount: 'Add Additional Account',
  accountName: 'Account Name',
  accountDetails: 'Account Details',
  accountNameLimit: 'Account name cannot be greater than 50 characters',
  pleaseAddAccount: 'Please choose to add an account',
  addAccountTitle: 'Main Store - Add Existing Additional Account',
  batchImportExc: 'Batch Import Stores',
  updateDate: 'Update Stores Data',
  batchImportAccount: 'Import Additional Accounts',
  importTips1: '*Import Instructions',
  ImportTips2: `Only Excel files with suffixes xls, xlsx, and csv are supported, with a file size limit of 10M or less, and no more than 300 entries per import.`,
  importTips3: `The default authorization for batch imported additional accounts is for employees with main store permissions; Releasing the association between the main store and additional accounts can be done in [Additional Account Management]`,
  download: 'Download',
  downLoadTip2: 'Based on this data, make modifications according to the table requirements before uploading!',
  batchUpdateTips1: 'Please select the store data to be updated first, and make modifications based on this data before uploading.',
  otherAccountTel: 'Import Additional Accounts Template.xlsx',
  updateTel: 'Selected Stores.xlsx',
  pleaseCompanyName: 'Please enter the company name',
  userNameMaxLength: 'Account can have up to 100 characters',
  numTime: `{arg1} Hours`,
  cookieMax: 'Maximum cannot exceed 720 hours',
  account: 'Account',
  syncCookieStore1: 'Multiple users using the same store cookie',
  syncCookieUser1: 'Multiple users using multiple different store cookies',
  syncCookie1: 'Synchronize store cookies and open them in a new state',
  syncCookieStore: 'Sync Store',
  syncCookieUser: 'Synchronize by User',
  syncCookie: 'out of sync',
  cookieMethod: 'Cookie method',
  accountProtection: 'Account protection',
  closeBrowser: 'Close Stores',
  openBrowser: 'Open Stores',
  detailedRequest: 'Detailed Request',
  operationalInformation: 'Operational Information',
  accessLog: 'Access Log',
  requestingAuthorization: 'Requesting Authorization',
  submit: 'Submit Application',
  submitTip: 'Please contact your supervisor to authorize you',
  submitTip2: 'Log in to a new terminal',
  submitTip3: 'The account you are currently logged in to requires superior authorization to log in',
  operator: 'Operator',
  pleaseSelectUser: 'Please select the user to be handed over',
  unbindShop: 'Are you sure you want to unbind the device from {arg1} stores?',
  reopening: 'Reopening Stores',
  reopenFinished: 'Reopen Finished.',
  reopeningAndWait: 'Stores reopening, please wait...',
  reopenErrors: 'Stores that reopen failed:',
  shopError: 'Invalid store',
  deviceError: 'Invalid device',
  storeUnauthorized: 'Store Unauthorized',
  shopNot: 'Store does not exist',
  自定义平台: 'Custom Platform',
  storeImport: 'Stores Import',
  bindeviceTip:
    'The currently selected device has already been bound to {arg} stores, which may trigger store IP associated risk control. This operation is not recommended for store security',
  bindeviceTip1:
    'The currently selected device has already been bound to {arg1} stores. Multiple stores using the same device at the same time may cause store association risks and poor internet speed!',
  bindeviceTip2:
    'You have selected {arg1} stores to bind to this device. Multiple stores using the same device at the same time may cause store association risks and poor internet speed!',
  confirmBinding: 'Confirm Binding',
  rebind: 'Rebind',
  rebindtip: 'Before binding an account to a device, please understand the policies of each platform site.',
  rebindtip1:
    'Multiple stores using the same device at the same time may result in poor network speed associated with the store. This operation is not recommended for store safety!',
  notAdded: 'Not added',
  rebindtip2: 'If there is no suitable device in the list, please:',
  expriceDevice: 'The current device has expired and cannot open the store. Please renew and try again!',
  pleaseSelectCustomSite: 'Please add or select a custom platform',
  selectStoresTip: '{arg1} stores selected',
  currentDevice: 'Current device:',
  cloneSucces: 'Clone Success',
  transformRule: 'Company name can have up to 100 characters',
  transformTip:
    'Note: If the transferred IP device is also bound to another store, the other stores will become unbound after the transfer and need to be rebound to open normally!',
  labelManagement: 'Label Management',
  setLabel: 'Set label',
  batchsetLabel: 'Batch Setting Labels',
  customLabels: 'Custom labels',
  labelManagement: 'Tag Management',
  setLabel: 'Set Tag',
  batchsetLabel: 'Batch Setting Tag',
  customLabels: 'Custom Tag',
  tag: 'Tag',
  enterLabelName: 'Enter a Tag Name',
  tagName: 'Tag Name',
  labelNameRules: 'The tag name cannot exceed 20 characters',
  deleteTag: 'Delete Tag',
  editTag: 'Edit Tag',
  toBrowser: 'Click Quick Look tag store',
  setTagTip: 'Tag Set Successfully',
  selectTag: 'Select Tag',
  newTag: 'Add New Tag',
  addTagSuccess: 'Tag Added Successfully',
  addTag: 'Add Tag',
  noTag: 'No Tag',
  platformClassify: 'Platform classification',
  selectTagTip: 'Please select a label',
  batchDel: 'Batch Delete',
  deletetags: 'Please select the label that needs to be deleted',
  tagEditSuccess: 'Tag Modification Successful',
  tagDeleteSuccess: 'Tag deleted successfully',
  storeNum: 'Number',
  transformTip:
    'Note: If the transferred IP device is also bound to another store, the other stores will become unbound after the transfer and need to be rebound to open normally!',
  transformTip2: `If the IP device being moved is bind to a cross-border dedicated line, cross-borderline it won't be relocated together`,
  clearCacheWithoutExtensions: 'Clear Stores Cache(Without Extensions)',
  clearCachesConfirm2:
    'This operation will clear all cache files except for Stores extensions and extension data. Are you sure you want to proceed with the clearance?',
  openBrowserError:
    'An exception occurs when opening the window: 1. Please check whether the system time is accurate. If the system time is not accurate, please adjust it to the correct time; 2. It may be caused by incomplete software client, try to uninstall the software, then download and install the latest version and try again; 3. It may be caused by the lack of certain components or incompleteness of the system, such as: Ghost system, simple system, please update the operating system and update the driver, or install the full version of the operating system and try again. 4. If the problem is not resolved after trying the above methods, please contact customer service',
  waitBatchOpenBrowser: 'Opening windows in batches, please wait...',
  beginning: 'starts with',
  contain: 'contains',
  customSort: 'Custom Sort',
  editCustomSort: 'Edit Sort',
  sortLabel: 'Sort',
  customSortBtn: 'Random Custom Sort',
  customSortDialogTip1: 'Please go to "Customize Columns" and check "Custom Sort" before you can use this function.',
  customSortDialogTip2:
    'Random custom sort will overwrite the original sort value, and cannot be retrieved, do you want to continue to modify?',
  sortOkBtn: 'Go to Settings',
  sortRuleMsg2: 'The sort number must be greater than or equal to 0 and less than 3000000.',
  openToolTip: 'UserName: {userName} is opening the shop!',
  updateSortOfChecked: 'Update Custom Serial Number',
  customSeqMsg: 'Random custom sort',
  positiveSequenceNum: 'Ascending order',
  reverseSequenceNum: 'Descending order',
  startSerialNumlabel: 'Starting',
  sequenceNumplaceholder: 'Please enter the serial number',
  positiveSequenceTip:
    'The selected profiles will generate sequence numbers in positive order according to the set starting sequence number, which will overwrite the original sorting value and cannot be retrieved!',
  reverseSequenceTip:
    'The selected profiles will generate sequence numbers in reverse order according to the set starting sequence number, which will overwrite the original sorting value and cannot be retrieved!',
  authorizeLogin1: 'Authorization is required to log in to a new terminal device.',
  authorizeLogin2: 'Not within the login time.',
  authorizeLogin3: 'Logging in to a new terminal device requires authorization and is not within the login time.',
  openMethod: 'open Method',
  manualOpening: 'Manual Opening',
  AutomaticallyOpen: 'Automatically open current Store',
  AutomaticallyOpenHistroy: 'Automatically open Histroy',
  unbindStoreTip1: 'Are you sure you want to unbind all selected {arg} IP devices from all bound stores?',
  unbindStoreTip2: 'After unbinding, the IP devices can be bound to stores again!'
}
