import { LocalStorage, Cookies, uid } from 'quasar'

export const TOKEN_KEY = 'BITBROWSER_TOKEN'
const userNameKey = 'BITBROWSER_USERNAME'
const passowrdKey = 'BITBROWSER_PASSWORD'
const qrGroupKey = 'QR_GROUP_KEY'

/**
 * @description 获取token
 * @returns String
 */
export function getToken() {
  // TODO 删掉导致token重复的字段，过几天恢复
  Cookies.remove(TOKEN_KEY, { path: '/extensions' })
  return LocalStorage.getItem(TOKEN_KEY)
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setLocalToken(token) {
  Cookies.set(TOKEN_KEY, token, { expires: 365, path: '/' }) // 一年
  return LocalStorage.set(TOKEN_KEY, token)
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  Cookies.remove(TOKEN_KEY)
  return LocalStorage.remove(TOKEN_KEY)
}

/**
 * @description 存储本地的用户信息
 * */
export function saveUserinfo2Local(userName, password) {
  LocalStorage.set(userNameKey, userName)
  LocalStorage.set(passowrdKey, password)
}

/**
 * @description 获取本地存储的用户信息
 * */
export function getLocalUserinfo() {
  return {
    userName: LocalStorage.getItem(userNameKey),
    password: LocalStorage.getItem(passowrdKey)
  }
}

/**
 * @description 清除本地用户信息
 * */
export function clearLocalUserinfo() {
  LocalStorage.remove(userNameKey)
  LocalStorage.remove(passowrdKey)
}

// 是否点击过官方群【我知道了】
export function hasKnowGroupQr() {
  return Cookies.has(qrGroupKey)
}

// 存储官方群【我知道了】，随机值
export function saveKnowGroupQr() {
  Cookies.set(qrGroupKey, uid(), { expires: 365 })
}
