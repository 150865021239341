const constantRoutes = [
  {
    path: '/login',
    component: () => import('layouts/LoginLayout.vue'),
    name: 'LoginLayout',
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('pages/login.vue')
      }
    ]
  },
  {
    path: '/register',
    component: () => import('layouts/LoginLayout.vue'),
    name: 'RegisterLayout',
    children: [
      {
        path: '',
        name: 'Register',
        component: () => import('pages/register.vue')
      }
    ]
  },
  {
    path: '/reset-pass',
    component: () => import('layouts/LoginLayout.vue'),
    name: 'ResetLayout',
    children: [
      {
        path: '',
        name: 'ResetPass',
        component: () => import('src/pages/resetPass/index.vue')
      }
    ]
  },
  {
    path: '/assistant-register',
    component: () => import('layouts/LoginLayout.vue'),
    name: 'AssistantRegisterLayou',
    children: [
      {
        path: '',
        name: 'AssistantRegister',
        component: () => import('pages/assistantRegister.vue')
      }
    ]
  },
  {
    path: '/forget-account',
    component: () => import('layouts/LoginLayout.vue'),
    name: 'ForgetAccountLayout',
    children: [
      {
        path: '',
        name: 'ForgetAccount',
        component: () => import('pages/forgetAccount.vue')
      }
    ]
  },
  // {
  //   path: '/policy',
  //   component: () => import('pages/policy.vue'),
  //   name: 'Policy'
  // },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default constantRoutes
