export default {
  zh: {
    nameRequired: '请输入姓名',
    nameLenLimit: '姓名不超过20位',
    nameSchema: '姓名为中英文或数字',
    usernameRequired: '请输入用户名',
    usernameLenLimit: '用户名不超过20位',
    usernameSchema: '用户名为英文、数字或下划线',
    passRequired: '请输入密码',
    passMinLen: '密码长度不小于6位',
    passMaxLen: '密码长度不能超过30位',
    telRequired: '请输入手机号码',
    telBeNumber: '手机号码为纯数字',
    telMax: '手机号码不能超过20位',
    telInvalid: '请输入正确的手机号',
    emailInvalid: '请输入正确的邮箱格式',
    emialMaxLen: '邮箱账号不能超过50位',
    qqInvalid: '微信/QQ只包含英文字母和数字',
    qqMaxLen: '微信/QQ不能超过30位',
    speicalCharacters: '长度为8~30个字符，需包含：大小写字母、数字、特殊字符',
    spaceError: '密码不能包含空格'
  },
  en: {
    nameRequired: 'Name is required.',
    nameLenLimit: 'Name cannot exceed 20 characters.',
    nameSchema: 'Name in Chinese and English or in figures.',
    usernameRequired: 'Username is required.',
    usernameLenLimit: 'Username cannot exceed 20 characters.',
    usernameSchema: 'Username is English, number or underscore.',
    passRequired: 'Password is required.',
    passMinLen: 'The min password length is 6 digits.',
    passMaxLen: 'Password cannot exceed 30 characters.',
    telRequired: 'Phone Number is required.',
    telBeNumber: 'The phone number must be numbers.',
    telMax: 'Cannot exceed 20 characters.',
    telInvalid: 'Please enter the valid phone number.',
    emailInvalid: 'Please enter the valid email address.',
    emialMaxLen: 'Email address cannot exceed 50 characters.',
    qqInvalid: 'Wechat/QQ number only contains English letters and numbers.',
    qqMaxLen: 'Wechat/QQ cannot exceed 30 characters.',
    speicalCharacters: '8-30 characters with uppercase, lowercase, digits, and special characters.',
    spaceError: 'Password cannot contain spaces'
  }
}
