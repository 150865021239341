export default {
  add: 'Add',
  groupName: 'Group Name: ',
  search: 'Search',
  toBrowser: 'Click to goto the grouping browsers.',
  copyID: 'Copy ID',
  groupNamePlaceholder: 'Please enter group name',
  sortPlaceholder: 'Please enter a sort number greater than or equal to 0',
  sortHint: 'Hint: Please enter a number, the smaller the number, the higher the order.',
  sort: 'Sort',
  belongTo: 'Belonging User',
  belongUser: 'Belonging User',
  belongUserTips: 'After updating the "Belonging User", the gorup and the browsers under the gourp will both be transferred to the user.',
  groupNameRuleMsg1: 'Group name is required.',
  groupNameRuleMsg2: 'Group name cannot exceed 20 characters.',
  groupNameRuleMsg3: 'The name cannot contain spaces and line breaks.',
  sortRuleMsg1: 'Please enter a number',
  sortRuleMsg2: 'The sort number must be greater than or equal to 0 and less than 10000.',
  addGroup: 'Add Group',
  browserCount: 'Group Browsers',
  createdTime: 'Created Time',
  deleteConfirmText: 'Confirm to delete Tag?<br/>After the Tag is deleted, it cannot be restored. Please be careful!',
  deleteConfirmText2:
    'Are you sure you want to delete the selected {arg} labels completely? <br/>After the tag is deleted, it cannot be restored, please operate with caution!',
  deleteSuccess: 'Success',
  editSuccess: 'Success',
  addSuccess: 'Success',
  copySuccess: 'Success',
  deleteGroup: 'Delete Group'
}
