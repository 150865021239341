<template>
  <router-view />
  <bit-dialog
    :title="$t('common.upgradeAvailable')"
    :visible="showUpdateDialog"
    :width="550"
    @update:visible="onClose"
    v-if="isInBitBrowser"
    no-route-dismiss
  >
    <div class="version-info">
      <h4>{{ $t('common.clientVersion') }}v{{ updateInfo.version }}</h4>
      <h4 class="q-mt-xs">{{ $t('common.releaseNote') }}</h4>
      <ul>
        <li v-for="(item, index) of updateInfo.releaseNotes" :key="`Release_Note_${index}`">{{ item }}</li>
      </ul>
    </div>
    <q-linear-progress size="30px" :value="percent / 100" stripe color="primary" class="q-mt-sm" v-show="showProgress">
      <div class="absolute-full flex flex-center">
        <q-badge color="white" text-color="primary" :label="progressLabel" />
      </div>
    </q-linear-progress>
    <div class="text-red q-mt-sm" v-if="errMsg" v-html="errMsg"></div>
    <template v-slot:footer>
      <div class="row">
        <q-checkbox v-model="hasNoUpdate" @update:model-value="checkUpdateFn" dense :label="$t('common.ignoreUpdate')" color="primary" />
        <q-space></q-space>
        <div v-if="isMac">
          <q-btn no-caps :label="$t('common.toDownlaod')" unelevated class="q-mr-sm theme-cancel-btn" @click="toDownlaod"></q-btn>
        </div>
        <div class="text-right" v-else>
          <q-btn
            no-caps
            :label="$t('common.downloadBackgroud')"
            unelevated
            class="q-mr-sm theme-cancel-btn"
            @click="backDownload"
            v-show="!showProgress && !showRetry"
          ></q-btn>
          <q-btn
            no-caps
            :label="$t('common.upgradeNow')"
            unelevated
            color="primary"
            @click="downloadNow"
            v-show="!showProgress && !showRetry"
          ></q-btn>
          <span class="text-orange q-mr-md" v-show="showProgress">{{ $t('common.upgradeTips') }}</span>
          <q-btn no-caps label="重试" unelevated color="primary" @click="retry" v-show="showRetry" class="q-mr-sm"></q-btn>
          <q-btn
            no-caps
            :label="$t('common.install')"
            unelevated
            color="primary"
            @click="quitAndInstall"
            v-show="showProgress"
            :disable="percent < 100"
          ></q-btn>
        </div>
      </div>
    </template>
  </bit-dialog>
  <bit-dialog
    :visible="visibleFlag"
    @update:visible="visibleFlagFn"
    :noRouteDismiss="true"
    :hideClose="hideClose()"
    :persistent="hideClose()"
  >
    <template #title>
      <div class="q-px-md q-pt-md">
        比特浏览器电商版，更名为：<span class="text-primary cursor-pointer" @click="goOfficial()">火豹浏览器（www.firebrowser.cn）</span
        >，点击“立即下载”按钮下载火豹浏览器！
      </div>
    </template>
    <div>
      比特浏览器电商版，不再进行任何产品更新，请尽快安装火豹浏览器。火豹浏览器与比特浏览器电商版的所有用户数据互通，请放心安装使用！
      <p class="text-red q-mt-sm">截止到2023.7.17，比特浏览器电商版将禁止打开，请尽快安装火豹浏览器！</p>
    </div>
    <template #footer>
      <q-btn class="full-width" color="primary" @click="gotoDownload" unelevated> 立即下载 </q-btn>
    </template>
  </bit-dialog>
</template>
<script>
import { defineComponent } from 'vue'
import { useQuasar, Cookies, SessionStorage, LocalStorage } from 'quasar'
import BitDialog from 'components/BitDialog'
import { mapGetters } from 'vuex'
import { appQuitManually, getOemInfo, getReleaseNotes, heartbeatFn, closeFailureReminder } from 'api/user'
import { getWebAppVersion } from 'api/dicts'
import SysConfirm from 'components/SysConfirm'
import debounce from 'lodash/debounce'
import { deleteIds, setOpenedBrowser } from './layouts/openbrowser.js'
import { CacheMap } from 'utils/cache'
import { getClientXCF } from './boot/freetrail'
export default defineComponent({
  name: 'App',
  components: { BitDialog },
  data() {
    return {
      hasNoUpdate: false,
      visibleFlag: location.hostname.includes('store.bitbrowser'),
      updateInfo: {},
      showRetry: false,
      errMsg: '',
      clientVersion: '',
      isMac: window.isMac,
      notify: null,
      enterpriseValidation: {},
      notifyFlag: false,
      errNotify: null
    }
  },
  computed: {
    showProgress() {
      return this.percent > 0
    },
    progressLabel() {
      return this.percent.toFixed(2) + '%'
    },
    ...mapGetters({
      showUpdateDialog: 'client/showUpdateDialog',
      isInBitBrowser: 'client/isInBitBrowser',
      percent: 'client/downloadPercent',
      token: 'user/token',
      webAppVersion: 'client/webAppVersion',
      openedWindowIds: 'client/openedWindowIds',
      userInfo: 'user/userInfo',
      cnWebsite: 'client/cnWebsite',
      enWebsite: 'client/enWebsite'
    }),
    isOfficial() {
      return location.hostname.endsWith('firebrowser.cn') || location.hostname.endsWith('firebrowser.net') || process.env.DEV
    },
    locale() {
      return this.$i18n.locale
    }
  },
  watch: {
    token(e) {
      if (!e) {
        this.errNotify && this.errNotify()
      }
    },
    enterpriseValidation: {
      handler(e) {
        if (e.companyAuthentication && e.companyAuthentication.remind) {
          if (e.companyAuthentication.status) {
            this.successReg()
          } else {
            !this.notifyFlag && this.errorReg(e.companyAuthentication)
          }
        }
      },
      deep: true
    },
    locale() {
      this.initOem()
    }
  },

  created() {
    this.initOem()
    this.checkWebAppVersion()

    if (this.isInBitBrowser) {
      // 监听浏览器窗口打开
      window.bitClientMain.onBrowserOpened(config => {
        this.$store.commit('client/WINDOW_OPENED', config)
        deleteIds(config.id)
        setOpenedBrowser(config)
      })

      // 监听浏览器窗口关闭
      window.bitClientMain.onBrowserClosed((id, urls) => {
        this.$store.commit('client/WINDOW_CLOSED', id, urls)
        deleteIds(id)
      })
    }
  },
  mounted() {
    if (this.isInBitBrowser) {
      window.bitClientMain.onUpdateAvailable(info => {
        if (!this.userInfo.updateAvailable) return // 此用户不展示升级时，直接返回

        // 服务端取升级日志
        getReleaseNotes(info.version).then(res => {
          info.releaseNotes = res.upgradeContent.split('\n')
          this.updateInfo = info

          this.$store.commit('client/UPDATE_AVAILABLE', true)

          // 每次启动时弹出一次，然后6小时内只弹出一次
          if (SessionStorage.has('UpdateDialogSession') && Cookies.has('APP_Update_Available')) return
          SessionStorage.set('UpdateDialogSession', '1')
          if (this.hasNoUpdateFn(info.version)) {
            this.$store.commit('client/SHOW_UPDATE_DIALOG', true)
          }
        })
      })
      window.bitClientMain.onDownloadProgress(info => {
        this.$store.commit('client/UPDATE_PERCENT', info.percent)
        this.showRetry = false
        this.errMsg = ''
      })
      window.bitClientMain.onUpdateDownloaded(info => {
        this.$store.commit('client/SHOW_UPDATE_DIALOG', true)
        this.$store.commit('client/UPDATE_PERCENT', 100)
      })
      window.bitClientMain.onUpdateApply(() => {
        this.$q.loading.show({
          message: this.$t('common.applyingUpdates')
        })
      })
      // 下载出错
      window.bitClientMain.onUpdateError(msg => {
        if (msg === '更新遇到错误，请重新下载，或者重启软件后更新') return

        let permissionMsg = this.$t('common.updateError')

        this.$q.loading.hide()

        if (this.showUpdateDialog) {
          this.errMsg = msg.includes('文件写入失败') || msg.includes('解压升级文件失败') ? permissionMsg : msg
          this.showRetry = true
        } else {
          if (msg.includes('文件写入失败') || msg.includes('解压升级文件失败')) {
            this.$q.notify({
              type: 'negative',
              message: permissionMsg,
              timeout: 8000,
              html: true
            })
          } else {
            this.$q.notify({ type: 'negative', message: msg, timeout: 2000 })
          }
          this.$store.commit('client/UPDATE_PERCENT', 0)
        }
      })

      // 2秒后再检查更新，防止userInfo没数据
      setTimeout(() => {
        window.bitClientMain.checkForUpdates()
      }, 2000)

      window.bitClientMain.getClientVerion().then(res => {
        this.clientVersion = res.version
        // 打开项目执行一次心跳
        if (!SessionStorage.has('mountedHeartBeat')) {
          this.heartBeatApi()
          SessionStorage.set('mountedHeartBeat', '1')
        }
      })
    }
    this.heartBeat()
    CacheMap.DeleteChildrenDictsStorage()
  },
  setup() {
    const $q = useQuasar()

    // 主题色
    const darkMode = LocalStorage.getItem('darkMode')
    if (darkMode) $q.dark.set(true)
    else $q.dark.set(false)
    // Example of adding support for
    // <q-icon name="bit-xxx" />
    // This includes support for all "icon" props
    // of Quasar components

    // 无需显示loadingbar的接口
    const NO_LOADING_APIS = ['sysUsers/softHeartBeat', 'sysDictionarys/getNoPassCode']

    $q.loadingBar.setDefaults({
      hijackFilter: url => {
        return !NO_LOADING_APIS.some(api => url.includes(api))
      }
    })

    $q.iconMapFn = iconName => {
      // 只设置bit-开头的
      if (iconName.startsWith('bit-')) {
        return {
          cls: 'iconfont ' + iconName
        }
      }
    }
  },
  methods: {
    // 发送消息已读
    sendNotify() {
      closeFailureReminder({ type: 1 })
      this.notifyFlag = false
    },
    checkUpdateFn(val) {
      if (val) {
        LocalStorage.set('hasNoUpdate', this.updateInfo.version)
      } else {
        LocalStorage.remove('hasNoUpdate')
      }
    },
    hasNoUpdateFn(version) {
      if (LocalStorage.has('hasNoUpdate') && LocalStorage.getItem('hasNoUpdate') === version) {
        this.hasNoUpdate = true
        return false
      } else {
        return true
      }
    },
    //成功提示
    successReg() {
      this.$q.notify({
        message: this.$t('em.enterpriseSuccess'),
        timeout: 2000,
        position: 'top-right',
        actions: [
          {
            flat: true,
            dense: true,
            icon: 'o_close',
            label: '',
            color: 'white',
            handler: () => {
              this.sendNotify()
            }
          }
        ]
      })
      // 弹出后立即关闭
      this.sendNotify()
    },
    // 错误提示
    errorReg() {
      this.notifyFlag = true
      this.errNotify = this.$q.notify({
        type: 'info',
        multiLine: false,
        message: this.$t('em.enterpriseErr'),
        ignoreDefaults: false,
        iconSize: '16px',
        classes: 'enterprise_notify text-orange-14',
        iconColor: 'orange-14',
        actions: [
          {
            label: this.$t('em.toAuthen'),
            outline: true,
            dense: true,
            handler: () => {
              this.sendNotify()
              this.$router.push({ name: 'enterpriseManage', query: { business: 'authentication' } })
              //  发送请求
            }
          },
          {
            flat: true,
            dense: true,
            icon: 'o_close',
            handler: () => {
              this.sendNotify()
            }
          }
        ],
        timeout: 0,
        position: 'top-right'
      })
    },
    onClose() {
      this.$store.commit('client/SHOW_UPDATE_DIALOG', false)
      // 关闭时设置6h内不再打开
      Cookies.set('APP_Update_Available', '1', {
        expires: '6h'
      })
    },
    backDownload() {
      this.onClose()
      this.downloadNow()
    },
    downloadNow: debounce(function () {
      window.bitClientMain
        .downloadUpdate()
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.error(err)
        })
    }, 300),
    // 打开官网下载
    toDownlaod() {
      let url = this.cnWebsite + '/download/'
      if (this.$i18n.locale === 'en') url = this.enWebsite + '/download/'

      window.bitClientMain.openByDefaultBrowser(url)
    },
    goOfficial() {
      window.bitClientMain && window.bitClientMain.openByDefaultBrowser('https://www.firebrowser.cn/')
    },
    gotoDownload() {
      window.bitClientMain && window.bitClientMain.openByDefaultBrowser('https://www.firebrowser.cn/download/')
    },
    hideClose() {
      let d = +new Date(2023, 6, 17) - +new Date()
      if (d > 0) {
        return false
      } else {
        return true
      }
    },
    visibleFlagFn() {
      this.visibleFlag = false
    },
    retry() {
      this.$store.commit('client/UPDATE_PERCENT', 0)
      this.downloadNow()
    },
    quitAndInstall: debounce(async function () {
      this.$store.commit('client/SHOW_UPDATE_DIALOG', false)
      try {
        await window.bitClientMain.closeAllBrowsers()
        await appQuitManually()
      } catch {}
      window.bitClientMain.quitAndInstall()
    }, 300),
    unHandledRejection(e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e.reason)
      }
    },
    async heartBeatApi() {
      if (this.token) {
        let xcf = await getClientXCF()
        let ppt = await window.bitClientMain.getMacAddress()
        this.enterpriseValidation = await heartbeatFn(
          {
            ids: Array.from(this.openedWindowIds),
            softVersion: this.clientVersion,
            device: window.isMac ? 'mac' : 'win'
          },
          {
            'Accept-PPT': ppt,
            'Accept-XCF': xcf
          }
        )
      }
    },
    heartBeat() {
      // 注册心跳，以客户端的为准
      if (this.isInBitBrowser) {
        window.bitClientMain.onHeartbeat(async () => {
          this.heartBeatApi()
        })
      } else {
        setInterval(() => {
          console.log('web heart beat')
          if (this.token) heartbeatFn({ ids: [], softVersion: '' })
        }, 3 * 60 * 1000)
      }
    },
    checkWebAppVersion() {
      getWebAppVersion().then(res => {
        const remoteVersion = res.result
        // 服务端没有返回版本，则直接结束
        if (!remoteVersion) return
        // 初次设置，直接返回
        if (!this.webAppVersion) {
          this.$store.commit('client/SET_WEB_VERSION', remoteVersion)
          return
        }
        if (this.webAppVersion !== remoteVersion) {
          clearTimeout(this.timeId)
          this.$q
            .dialog({
              component: SysConfirm,
              componentProps: {
                cancelBtn: false,
                text: this.$t('common.updateDetected')
              }
            })
            .onOk(() => {
              location.reload()
            })
        }
      })
      this.timeId = setTimeout(this.checkWebAppVersion, 5 * 60 * 1000) // 5分钟检查一次
    },
    initOem() {
      getOemInfo('www').then(res => {
        document.title = res.title
        let config = res

        config.logo = require('assets/logo.png')
        config.titleEn = res.title
        document.querySelector('#favicon').setAttribute('href', 'favicon.ico')

        this.$store.commit('client/SET_OEM_INFO', config)
        this.$store.commit('client/SET_SOFT_URL', config.softUrl)
      })
    }
  },
  beforeCreate() {
    window.addEventListener('unhandledrejection', this.unHandledRejection)
  },
  beforeUnmount() {
    window.removeEventListener('unhandledrejection', this.unHandledRejection)
  }
})
</script>

<style scoped lang="scss">
.version-info {
  background: #f7f7f7;
  box-sizing: border-box;
  padding: 10px;
  ul {
    li {
      padding-left: 10px;
      line-height: 26px;
    }
  }
}
</style>
