export default {
  rpaName: 'RPA自动化',
  addTask: '新建RPA任务',
  updateTask: '修改RPA任务',
  bindBrowser: '绑定店铺',
  workflowDashboard: '进入流程设计（使用112内核）',
  taskConcurrent: '任务并发设置',
  taskConcurrentTips: '*此处代表同时打开多少个店铺执行该RPA任务',
  userManual: '操作手册',
  taskName: '任务名称',
  workflowId: '工作流ID',
  type: '类型',
  startTime: '开始时间',
  endTime: '结束时间',
  remark: '备注',
  remarkPlaceholder: '请输入RPA任务备注',
  taskNameRule: '请填写任务名称',
  workflowRule: '请填写工作流ID',
  browserConcurrent: '店铺并发数',
  browserConcurrentRule: '请填写店铺并发数',
  frequency: '执行频率',
  frequencyRule: '请填写执行频率',
  specifiedTime: '定时任务',
  timeInterval: '循环任务',
  immediately: '立即执行',
  day: '天',
  hour: '小时',
  minute: '分钟',
  selected: '已选择',
  startTimeRule: '请选择开始时间',
  startTimeRule2: '开始时间必须大于当前时间',
  endTimeRule: '请选择结束时间',
  endTimeRule2: '结束时间必须晚于开始时间',
  deleteTaskText: '确认删除任务吗？',
  rpaStatus: '状态',
  running: '运行中',
  pending: '等待执行',
  disabled: '已禁用',
  finished: '已执行',
  concurrentPlaceholder: '请输入并发数',
  concurrentValue: '并发值',
  concurrentRule1: '请输入并发值',
  runTask: '开始任务',
  stopTask: '停止任务',
  deleteTask: '删除任务',
  statusTooltip: '开启/禁用RPA任务',
  disableTask: '确认禁用RPA任务吗？<p class="q-mt-sm text-red">禁用任务后，将不再触发定时器执行。</p>',
  enableTask: `确认启用RPA任务吗？`,
  logs: '任务日志',
  logsTime: '执行时间',
  logContent: '执行结果',
  intervalPrepend: '每隔',
  intervalAppend: '执行一次',
  concurrentTips: '此处代表允许多少个任务同时运行，请根据电脑配置设置！',
  success: '成功',
  bindError: '请至少绑定一个店铺',
  hasBind: '已绑定：',
  bindNow: '绑定',
  logsType: '类型',
  openBrowser: '打开店铺',
  openSkip: '店铺已打开，跳过',
  openFailed: '店铺打开失败',
  closeBrowser: '关闭店铺',
  intNums: '请输入大于0的整数',
  skipTask: '任务运行中，跳过',
  enableRule: '请先修改RPA任务，确保开始时间大于当前时间！',
  timeout: '任务超时时间',
  timeoutTips: '*每个店铺打开后若超过该时间，将自动关闭（填写0该功能将失效）',
  timeoutRequired: '请填写任务超时时间',
  timeoutNums: '请填写大于等于0的数字',
  seconds: '秒',
  selectionTips:
    '注意：已绑定的店铺会展示在列表的最上方，点击箭头图标进行倒序/正序排列，已勾选绑定的店铺和未勾选绑定的店铺会分开进行排序。',
  selectionTipsExtend:
    '注意，此处勾选会替换所有已选数据为当前分页数据，取消勾选为取消全部数据选择，如果要追加/取消当前页面的数据到已选择，请使用下方的追加数据/取消数据按钮。',
  appendPageData: '全选该页',
  cancelPageData: '反全选该页',
  cancelAll: '取消选择全部',
  namePlaceholder: '请输入店铺名搜索',
  boundWindow: '已绑定店铺',
  taskSetting: '任务设置【并发{arg}】',
  taskSettingTitle: '任务设置',
  runningStrategy: '店铺执行策略',
  notOpened: '已打开店铺禁止执行RPA任务',
  allCanRun: '已打开店铺允许执行RPA任务',
  allCanRunTips: '已打开的店铺执行RPA任务，将不再触发“任务超时时间”！',
  runAtOpened: '已打开店铺执行任务',
  specifiedCore: '使用 {version} 内核'
}
