export default {
  groupControl: '群控系统',
  groupControlLabel: '群控系统',
  groupControlDesc: '1控多店铺同步操作',
  proxyIp: '代理IP推荐',
  proxyIpDesc: '汇集全球海量代理IP资源',
  chuhai2345: '出海资源汇集',
  chuhai2345Desc1: '2345出海导航',
  chuhai2345Desc2: '汇集优质出海资源',
  scriptMarket: '脚本市场',
  scriptMarketDesc: '脚本定制供需发布平台',
  zhiwenshouji: '指纹手机',
  zhiwenshoujiDesc: '无限生成防关联新机环境&无人直播',
  userLabel: '用户：',
  roleLabel: '身份：',
  balanceLabel: '账户余额：',
  rechargeLabel: '充值',
  getCoupon: '老用户领取8折优惠券',
  package: '经典套餐:',
  browsers: '窗口环境',
  unit: '个',
  users: '员工',
  additionUsers: '额外增加员工',
  expired: '有效期',
  browsersCount: '总额度：',
  used: '已使用：',
  assigned: '已授权：',
  activate: '去开通',
  totalUsers: '总额度：',
  controlBrowsers: '总控数：',
  groupControlDocs: '操作指南（图文）',
  groupControlVideoDocs: '操作指南（视频）',
  qa: '常见问题',
  open: '打开',
  notice: '通知',
  docs: '操作指南',
  help: '使用帮助',
  issues: '常见问题',
  apiDocs: 'API对接文档（支持操作店铺及Selenium/Puppeteer自动化脚本）',
  createdTime: '发布时间：',
  close: '关闭',
  groupControlOpened: '群控系统已打开',
  groupControlOpenFail: '群控系统打开失败，请确保网络通畅后重试；如果您的系统未安装.NET4.6.1环境，点击下方按钮下载并安装，然后重试！',
  upgrade: '请升级到最新版客户端后使用',
  couponText: `
    <p>8折优惠券领取成功！请在“费用中心 -> 查看优惠券”里面查看。</p>
    <p class="q-mt-sm">可以用于开通套餐/续费套餐/变更员工等消费项目使用！</p>
  `,
  downloadApp: '群控系统，仅支持Windows系统使用，如需使用请下载Windows版客户端！',
  rpaDesc: ' 浏览器自动完成繁琐重复内容，提升运行效率',
  shop: '店铺',
  deviceBound: '已绑定IP设备',
  add: '去添加',
  total: '总计IP设备',
  expiringSoon: '即将过期',
  goBuy: '去购买',
  downloadDonet: '[下载 .NET4.6.1]',
  registerGiftTip: `新注册用户赠送 <span class='text-red text-bold'>{num}</span> 张 <span class='text-red text-bold'>{arg}元</span> 优惠券，请点击“费用管理”菜单，进入“优惠券”界面查收，或点击下方“查看优惠券”，可进行查看。优惠券可以用于开通IP设备及续费IP设备使用！`,
  forcePasswordChangeTip:
    '为了保证您的火豹浏览器账号安全，我们强烈建议您定期修改登录密码，本次请务必修改密码后重新登录火豹浏览器，给您带来的不便深感歉意！',
  goChange: '去修改'
}
