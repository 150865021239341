export default {
  modelTitle: 'Select the device to bind',
  pleaseDeviceName: 'Enter device information',
  type: 'Type',
  type1: 'Type:',
  pleaseDeviceArea: 'Select the device region',
  deviceArea: 'Equipment Area',
  deviceIP: 'Device IP',
  pleaseDeviceName2: 'Enter device name',
  pleaseDeviceName3: 'Device name can have up to 100 characters',
  boundQuantity: 'Bound Quantity',
  expirationTime: 'Time',
  boundStore: 'Bound Store',
  expired: ' Expired',
  explain: 'Tip:',
  expired2: 'Device Expired',
  expired3: 'This store only allows one account to be opened at the same time',
  time: 'Time',
  renew: 'Renew',
  boundStore1: 'Bound Store',
  enableRenewal: 'Enable Automatic Renewal',
  batchEnableRenewal: 'Batch Enable Automatic Renewal',
  bindStore: 'Bind Store',
  unbindStore: 'Unbind store',
  deviceStatus: 'Device Status',
  cancelRenewal: 'Cancel Automatic Renewal',
  batchCancelRenewal: 'Batch Cancel Automatic Renewal',
  expirationDay: ' Due in {arg1} days',
  unbound: 'Unbound',
  generating: 'Generating',
  deleteDevice: 'Delete Device',
  view: 'View',
  delete: 'Delete',
  pleaseSelectDevice: 'Please select a device',
  normal: 'Normal',
  generationFailed: 'Generation Failed',
  editDevice: 'Edit Device',
  pleaseSelectbindShop: 'Please select the store account to bind to',
  pleaseSelectUnbindShop: 'Please select the store account to unbind',
  bind: 'binding',
  unbind: 'unbinding',
  bindTip: 'Are you sure about the {arg3} relationship between store [{arg1}] and device [{arg2}]?',
  deviceExpirationDate: 'Due Date',
  renewalTip: 'Automatic renewal started successfully',
  renewalTip1: 'Successfully closed automatic renewal',
  renewalTip2: 'Number of automatic renewal devices',
  renewalTip3: 'Number of devices to cancel renewal',
  renewalTip4:
    '1. Perform automatic renewal three days before the expiration of the device, and the renewal amount will be deducted from the account balance. Please ensure that your account balance is sufficient;',
  renewalTip5:
    '2. If the renewal fails due to insufficient balance, the device that has not been renewed will be permanently cancelled upon expiration and cannot be retrieved.',
  renewalTip6:
    'Cancel the automatic renewal of the selected device. After cancellation, there will be no further automatic renewal operations. Are you sure to cancel?',
  deleteDeviceTip:
    'Are you sure to delete the currently selected device? After deletion, the device is no longer usable. Please operate with caution.',
  buyTip:
    'Description: The devices provided by the FireBrowser  are all subject to the supervision of the Chinese network department. You should abide by the relevant laws and regulations of the Chinese Mainland region in the use process. You should not visit sensitive websites that are explicitly prohibited by the state. We do not provide all wall climbing services.',
  areaTip: 'The current area is not yet open',
  buyTip2:
    'Explanation: After purchasing your own device, you need to prepare your own proxy IP and edit the proxy information into the purchased device. Once the proxy detection is successful, it can be used normally!',
  areaTip1: 'Please select a region',
  remoteType: 'Remote type',
  equipmentPrice: 'Price',
  equipmentPrice1: 'Price:',
  purchaseDuration: 'Duration',
  purchaseQuantity: 'Number',
  purchaseDuration1: 'Duration:',
  purchaseQuantity1: 'Number:',
  recommended: 'Applicable',
  recommended1: 'No Applicable',
  confirmOrder: 'confirm an order',
  orderTip: 'Attention: Please confirm if your order information is accurate. Once placed, it cannot be replaced!',
  belongingRegion: 'Area',
  belongingRegion1: 'Area:',
  isRemote: 'Remote',
  isRemote1: 'Remote:',
  remoteCapable: 'Remote Capable',
  notRemote: 'Not Remote',
  month: 'Month',
  totalAmount: 'Amount',
  totalAmount1: 'Amount:',
  couponDiscount: 'Discount ',
  couponDiscount1: 'Discount:',
  payableAmount: 'Payment Amount',
  confirmPayment: 'Confirm Payment',
  months: ' Months',
  info: 'Info',
  bindShopNum: 'Number of bound stores',
  renewalDuration: 'Renewal',
  bindShopTitle: 'Bound Stores',
  viewStore: 'View Stores',
  viewDeviceDetails: 'View Device Details',
  buyDate: 'Purchasing Date',
  accountBound: 'Account Bound',
  deviceOperationLog: 'Device Operation Log',
  additionalAccount: 'Additional Account',
  lastLoginUser: 'Last Logged User',
  lastOpen: 'Last Open',
  lastLoginDate: 'Last Login',
  loginUser: 'Log On User',
  operationDetails: 'Operation Details',
  noticeMsg1: 'Notice: Your IP device is expiring soon. Please renew to keep your store running smoothly.',
  noticeMsg2: 'Notice: Your dedicated line is expiring soon. Please renew to keep your store running smoothly.',
  noticeMsg3: 'Notice: Both your IP device and dedicated line are expiring soon. Please renew to keep your store running smoothly.',
  AffiliatedPlatform: 'Affiliated Platform',
  modifyDeviceName: 'Modify Device Name',
  daysRemaining: 'Days Remaining',
  days: 'Days',
  renewalEquipment: 'Renewal of equipment',
  completelyDelect: 'Delete Device Completely',
  moveRecycle: 'Move To Recycle Bin',
  delectSelect: 'Delete Selected',
  completelyDelectTip: 'The device cannot be restored after deletion. Are you sure to delete it',
  restore: 'Restore',
  sellOut: 'sell out',
  status: 'status',
  tip: 'Tip:',
  generatingTip: 'The device deployment is successful within 30 minutes. Please be patient and wait.',
  selectUnbindStop: 'Please select unbound store',
  orderCheck: 'Order Verification',
  submitOrder: 'Submit Order',
  orderCheckTip1:
    'Attention: Please complete the payment as soon as possible to ensure that the information you have selected, such as device type, price, duration, quantity, and payment method, is filled in correctly. Once you place an order, it cannot be returned or exchanged!',
  orderCheckTip: 'Please complete the payment as soon as possible; once the order is placed, it cannot be refunded or exchanged.',
  chooseNow: 'choose Now',
  buyAgentTip:
    '"Attention: Binding personal IP devices for cross-border dedicated lines. No circumvention services provided, and access to prohibited websites is strictly prohibited."',
  buyAgentTip1: `This version doesn't support buying the device. Upgrade to the latest version for purchase.`,
  goUpdate: 'Go update',
  balanceRecharge: 'Balance recharge successful',
  orderCouponCheckTip:
    '【In an order, using a coupon consumes one instance of the coupon when you click the "Confirm Payment" button. If you have an unpaid order, please go to "Expense Management - Order Management" to cancel the order, and you can reuse the coupon afterward.】',
  attention: 'Attention:',
  automaticRenewal:
    'After automatic renewal is turned on, automatic renewal will be performed 3 days before the device expires. At that time, the renewal amount will be deducted from the account balance. Please ensure that your account balance is sufficient.',
  batchUnbindStore: 'Unbundle Stores Batches',
  renewalExpiredTip: 'The selected IP device has expired, please select again!'
}
