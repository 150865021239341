export default {
  modelTitle: '选择要绑定的IP设备',
  pleaseDeviceName: '请输入设备名称',
  type: '设备类型',
  type1: '设备类型：',
  pleaseDeviceArea: '请选择设备地区',
  deviceArea: '设备地区',
  platformDevice: '平台设备',
  ownEquipment: '自有设备',
  nicheDevices: '小众设备',
  deviceIP: 'IP设备',
  pleaseDeviceName2: '请输入设备名称',
  pleaseDeviceName3: '设备名称最多100字符',
  boundQuantity: '已绑定数量',
  expirationTime: '到期日期',
  time: '时间',
  boundStore: '绑定的店铺',
  expired: ' 已过期',
  expired2: '设备已过期',
  expired3: '该店铺同一时间只允许一个账户打开',
  renew: '续费',
  enableRenewal: '开启自动续费',
  batchEnableRenewal: '批量开启自动续费',
  bindStore: '绑定店铺',
  unbindStore: '解绑店铺',
  deviceStatus: '设备状态',
  cancelRenewal: '取消自动续费',
  batchCancelRenewal: '批量取消自动续费',
  expirationDay: ' 还有{arg1}天到期 ',
  unbound: '未绑定',
  generating: '生成中',
  deleteDevice: '删除设备',
  view: '查看',
  delete: '删除',
  pleaseSelectDevice: '请选择设备',
  normal: '正常',
  generationFailed: '生成失败',
  editDevice: '编辑设备',
  pleaseSelectbindShop: '请选择要绑定的店铺账号',
  pleaseSelectUnbindShop: '请选择要解绑的店铺账号',
  bind: '绑定',
  unbind: '解绑',
  bindTip: '确定店铺【{arg1}】与设备【{arg2}】的{arg3}关系吗？',
  deviceExpirationDate: '设备到期日期',
  renewalTip: '自动续费启动成功',
  renewalTip1: '自动续费关闭成功',
  renewalTip2: '开启自动续费设备个数',
  renewalTip3: '取消自动续费设备个数',
  renewalTip4: '1、设备到期前3日执行自动续费，届时续费金额将从账户余额中扣除，请保证您的账户余额充足；',
  renewalTip5: '2、若余额不足导致续费失败，未续费的设备到期后将被永久注销，不支持找回。',
  renewalTip6: '取消已选中设备的自动续费，将不再进行自动续费操作！您确定取消吗？',
  deleteDeviceTip: '确定删除当前所选的设备吗？删除后，设备不可再使用。请谨慎操作。',
  explain: '说明：',
  tip: '提示：',
  buyTip:
    '火豹浏览器提供的IP设备皆受中国网络部门监管，您在使用过程中应遵守中国大陆地区的相关法律法规，不得访问国家明令禁止登录的敏感网站。',
  buyTip2: '购买自有IP设备后，您需要自行准备代理IP，然后将代理信息编辑到已购买的自有IP设备中，代理检测成功后，可正常使用！',
  areaTip: '当前区域暂未开通',
  areaTip1: '请选择地区',
  remoteType: '远程类型',
  equipmentPrice: '设备价格',
  equipmentPrice1: '设备价格：',
  purchaseDuration: '购买时长',
  purchaseDuration1: '购买时长：',
  purchaseQuantity: '购买数量',
  purchaseQuantity1: '购买数量：',
  recommended: '适用平台',
  recommended1: '暂无适用平台',
  confirmOrder: '确认订单',
  orderTip: '注意：请确认您的订购信息是否准确，一旦下单不能退换！',
  belongingRegion: '归属地区',
  belongingRegion1: '归属地区：',
  isRemote: '是否远程',
  isRemote1: '是否远程：',
  remoteCapable: '可远程',
  notRemote: '不可远程',
  month: '月',
  totalAmount: '订单总额',
  totalAmount1: '订单总额：',
  couponDiscount: '优惠券优惠',
  couponDiscount1: '优惠券优惠：',
  payableAmount: '实际应付金额',
  confirmPayment: '确认支付',
  orderCheck: ' 订单核对',
  submitOrder: '提交订单',
  orderCheckTip: '注意：请尽快完成支付，一旦下单则不能退换！',
  orderCheckTip1: '注意：请尽快完成支付，确保您所选的设备类型、价格、时长、数量、支付方式等信息填写无误，一旦下单则不能退换！',
  months: '个月',
  info: '设备信息',
  bindShopNum: '绑定店铺数',
  renewalDuration: '续费时长',
  bindShopTitle: '已绑店铺',
  viewStore: '查看店铺',
  viewDeviceDetails: '查看设备详情',
  buyDate: '购买日期',
  boundStore1: '已绑定店铺',
  accountBound: '已绑定账号',
  deviceOperationLog: '设备操作日志',
  additionalAccount: '附加账号',
  lastLoginUser: '最后登录用户',
  lastLoginDate: '最后登录',
  loginUser: '登录用户',
  lastOpen: '最后打开',
  operationDetails: '操作详情',
  noticeMsg1: '提示：您当前有IP设备即将到期，请及时续费以确保店铺正常使用。',
  noticeMsg2: '提示：您当前有专线即将到期，请及时续费以确保店铺正常使用。',
  noticeMsg3: '提示：您当前有IP设备和专线即将到期，请及时续费以确保店铺正常使用。',
  AffiliatedPlatform: '所属平台',
  modifyDeviceName: '修改设备名称',
  daysRemaining: '剩余天数',
  days: '天',
  renewalEquipment: '续费设备',
  delectSelect: '删除选中设备',
  moveRecycle: '删除设备至回收站',
  completelyDelect: '彻底删除设备',
  completelyDelectTip: '设备删除后不可恢复，确定删除吗',
  restore: '恢复',
  sellOut: '售罄',
  status: '状态',
  generatingTip: '设备部署中，30分钟内部署成功，请耐心等待。',
  selectUnbindStop: ' 请选择解绑店铺',
  chooseNow: '立即选择',
  buyAgentTip: '注意：绑定跨境专线的自有IP设备，不提供任何翻墙服务，不得访问国家明令禁止登录的敏感网站。',
  buyAgentTip1: '当前版本不支持购买该自有IP设备，请升级最新版本进行购买。',
  goUpdate: '去更新',
  balanceRecharge: '余额充值成功',
  orderCouponCheckTip:
    '【订单中使用优惠券，点击“确认支付”按钮则会消耗一次优惠券次数，若未完成支付的订单，请前往“费用管理-订单管理”取消该订单，可重新使用优惠券】',
  attention: '注意：',
  automaticRenewal: '开启自动续费后，设备到期前3日执行自动续费，届时续费金额将从账户余额中扣除，请保证您的账户余额充足。',
  batchUnbindStore: '批量解绑店铺',
  renewalExpiredTip: '选择的IP设备已过期，请重新选择！'
}
