import { ref, reactive } from 'vue'
import { getNoProxyIpInfo } from 'src/api/agent'
const onOpeningIds = ref(new Set()) // 正在打开的浏览器ID集合，禁用掉打开按钮，浏览器打开或关闭以后，均删除ID
const openBrowser = reactive({})
const openBatchBrowser = reactive({})
const openedBrowser = ref({})
const batchBrowsersOpenStatus = ref(false)
function deleteIds(id) {
  onOpeningIds.value.delete(id)
}
function addIds(id) {
  onOpeningIds.value.add(id)
}
async function isNoproxy(config) {
  if (config.proxyMethod === 2 && config.proxyType === 'noproxy') {
    try {
      return await getNoProxyIpInfo()
    } catch (err) {
      console.error('get noproxy ipinfo error ==>>> ', err)
    }
  }
}

function batchActionOpen(configs) {
  openBatchBrowser.configs = configs
  batchBrowsersOpenStatus.value = true
}
function setBatchOpenedFlag() {
  batchBrowsersOpenStatus.value = false
}
function clarBatchActionOpen() {
  openBatchBrowser.configs = null
  openBatchBrowser.extensionList = null
}
function clarActionOpen() {
  openBrowser.id = null
}

function browserOpenAction(id) {
  openBrowser.id = id
}
// 设置哪个浏览器打开了
function setOpenedBrowser(config) {
  openedBrowser.value = config
}

export {
  isNoproxy,
  deleteIds,
  addIds,
  onOpeningIds,
  batchActionOpen,
  browserOpenAction,
  openBrowser,
  openBatchBrowser,
  clarBatchActionOpen,
  clarActionOpen,
  setOpenedBrowser,
  openedBrowser,
  setBatchOpenedFlag,
  batchBrowsersOpenStatus
}
