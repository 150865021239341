export default {
  inviteUrl: 'Your Referral Link',
  inviteUrlTips: '『Please subscribe first to permanently display your promotion links.』',
  copyUrl: 'Copy',
  inviteCode: 'Your Referral Code',
  inviteTips: '『Show after becoming a promoter』',
  copyCode: 'Copy',
  overview: 'Summary',
  couponBtn: 'Coupon',
  withdraw: 'Withdraw',
  detailsBtn: 'Details',
  divisibleSum: 'Accumulated sharing base:',
  withdrawRecords: 'Withdrawal Record',
  inviteUsers: 'Invited Subordinate Users',
  userUnit: '',
  rewards: 'Rewards',
  pleaseCreatedTime: 'Enter select Registration Time',
  pleaseTradeTime: 'Please select the transaction time',
  moneyUnit: '¥',
  withdrawTotal: 'Withdrawn',
  withdrawMoney: 'Withdrawable Amount',
  policyText1: 'The {name} invitation reward mechanism has been launched, and the rewards are generous!',
  policyText2: 'By inviting rewards, you can not only recommend {name} to those who need it, but also earn a generous share for yourself!',
  policyTitle: 'Rules:',
  policy1: '1. For all {name} paid users!',
  policy2:
    '2. Copy your referral link, and promote it in the way you are good at. By registering users and spending through your referral link, you can get a high percentage of the amount spent.',
  policy3Label: 'Rewards: ',
  policy3: "{rate}% of the user's consumption amount.",
  policy3Tips: '(Permanent passive income, unlimited term)',
  policy4:
    ' Invite 5 users in total, and give a ¥50 coupon; Invite 10 users in total, and give another ¥50 coupon; Invite 20 users in total, and give another ¥100 coupon.',
  policy51: 'Withdrawable Amount should greater than ',
  policy52: ', and at least',
  policy53: 'Invited users to purchase devices, Then you can apply for withdrawal.',
  policy6:
    'If your invited user cancels the subscription or generates a refund during the award period, the corresponding invitation award will also be cancelled.',
  copySuccess: 'Success',
  withdrawMsg: `Withdrawable Amount should greater than <span class='text-red'>¥ {money}</span>!`,
  withdrawMsg2: `At least {min} invited users are required to have subscribed the paid devices, currently only {max}!`,
  detailsDialog: 'Details',
  detailTips: 'You are the general agent, and you can set the proportion of lower level users!',
  rewardsTips: 'Your commission rate is: {rate}%    consumption. You will be rewarded for the consumption of your subordinate users.',
  rewardsTips2:
    'If your invited user invite another user, he will get a commission share ratio equal to the share ratio you set, and you will get a rebate of: payment amount × (your share ratio - lower-level user share ratio)!',
  users: 'Company',
  records: 'Records',
  invitedUsers: 'Invited Subordinate Users: ',
  totalPay: 'Paid: ',
  totalRewards: 'Rewards: ',
  createdTimeStart: 'Registration Start Date',
  createdTimeEnd: 'Registration End Date',
  tradeTimeStart: 'Trading Start Date',
  tradeTimeEnd: 'Trading End Date',
  minRate: 'Cannot be lower than the system default proportion.',
  maxRate: 'Cannot greater than your proportion',
  username: 'Username',
  invitedBy: 'Referred',
  rewardsRate: 'Bonus Rate',
  totalPayMoney: 'Consumption Amount',
  createdTime: 'Created Time',
  remark: 'Remark',
  payMoney: 'Amount',
  percent: 'Rate',
  reward: 'Reward',
  tradeTime: 'Trade Time',
  saveSuccess: 'Saved',
  status0: 'Under Review',
  status1: 'Paid',
  status2: 'Reject',
  alipay: 'Alipay: ',
  bank: 'Bank Card: ',
  balance: 'Balance',
  requestTime: 'Request Time',
  requestMoney: 'Amount',
  payInfo: 'Infomations',
  status: 'Status',
  company: 'Company',
  paypal: 'PayPal:',
  divisibleBase: 'Divisible base',
  couponRewards: 'Coupon Rewards: ',
  policy4: `For every new user registered through your invitation link, you will be rewarded with <span class='text-red'>{num}</span> vouchers of <span class='text-red'>{money}¥</span> each. Additionally, for each new user who makes a purchase of platform IP devices, you will receive an extra <span class='text-red'>{num1}</span> vouchers of <span class='text-red'>{money1}¥</span> each.`,
  policy4_1: '(These vouchers are only applicable for renewals, and their validity is based on the expiration date indicated.)',
  policy5: 'Both reward methods can be combined and enjoyed simultaneously!',
  policy8:
    'Participation in this activity using any unfair means or fraudulent behavior is strictly prohibited. In case of any violations, the official reserves the right to freeze all rewards and pursue legal action.',
  policy8_1:
    'The same device/IP/payment account generates the associated subordinate user, which permanently stops the commission sharing to the superior!',
  policy9:
    'The ultimate interpretation of this activity belongs to the organizer. If you have any questions, please contact customer service!',
  generateQrcodeTip: 'Use your promotional QR code to refer users to the product for rewards.',
  generateQrcode: 'Generate QR Code',
  qrCodeName: '{name} Promotional QR Code'
}
