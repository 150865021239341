export default {
  updateProxyWithSeq: 'Update Proxy(Serial No.: {seq})',
  updateProxy: 'Update Proxy',
  updateSuccess: 'Success',
  proxyMethod: 'Proxy Method',
  ipCheckService: 'Lookup Channel',
  ipCheckTips1: '1. The IP information which is lookuped by you selected channel will be displayed on workbench page.',
  ipCheckTips2:
    '2. Different IP lookup channels may have different detection results. If the result is unexpected, please switch the lookup channel.',
  ipCheckTips3:
    '3. If the Language, Display Language, Time Zone and Geolocation is checked by based on IP, please ensure that the detection results of the lookup channel are consistent with the actual information, otherwise errors will occur in these fingerprint properties.',
  ipCheckTips4: '(It is recommended to use channel IP123 for Rola-IP)',
  proxyType: 'Proxy Type',
  ipProtocol: 'Protocol',
  useGlobalProxy: 'Use Global Section',
  globalProxyInfo: 'Use {proxyType} proxy with global section.',
  host: 'Host',
  hostPlaceholder: '"Proxy Type://Host:Port:Username:Password" Auto Split',
  hostTips:
    'Automatically split when the cursor leaves for this template. <br/>Proxy Type://Host:Port:Username:Password <br/>If the host is in IPv6 format, please fill in the host in [ ]',
  port: 'Port',
  portPlaceholder: 'Please enter port',
  username: 'Username',
  usernamePlaceholder: 'Optional, please enter username',
  usernamePlaceholder2: 'Please enter username',
  password: 'Password: ',
  passwordPlaceholder: 'Optional, please enter password',
  passwordPlaceholder2: 'Please enter password',
  ipPlaceholder: 'Please enter the default IP(eg:11.22.33.44 )',
  noChangeIp: '只代理当前您填写的IP，若是当前填写IP失效，不会自动寻找可用IP代理！',
  ipHint: '若填写IP，下次打开窗口将优先使用该IP；不填写IP或已填写的IP失效，将使用下方位置信息自动寻找可用的IP！',
  ipTips: 'The country information will be automatically filled in by click the blank space after IP filled.',
  country: 'Country/Region',
  provicePlaceholder: 'Please enter State/Provice code',
  provice: 'State/Provice',
  proviceHint: '对应911s5中指定IP的“State”列中的英文代码！',
  cityPlaceholder: 'Please enter city code',
  city: 'City',
  cityHint: '对应911s5中指定IP的“City”列中的英文代码！',
  checkProxy: 'Check Proxy',
  httpsProxyMsg: 'Open the browser to check proxy.',
  ipCheckFailedMsg: 'Proxy check failed, please ensure that the IP is valid and contact the provider if necessary.',
  ipNoChange: '只代理当前IP',
  ipNoChangeTips: '该IP失效不会自动更换IP',
  hostRuleMsg: 'Please enter host',
  portRuleRequiredMsg: 'Please enter port',
  portRuleNumMsg: 'Please enter a number',
  portRuleValidMsg: 'The port number range is 0-65535',
  usernameRuleMsg: 'Username can not exceed 200 characters',
  passwordRuleMsg: 'Password can not exceed 200 characters',
  ipRuleMsg1: '勾选“只代理当前IP”时，务必填写IP',
  ipRuleValidMsg: 'Please enter a valid IP address.',
  proviceRuleMsg: 'State/Provice code can not exceed 30 characters',
  cityRuleMsg1: 'Please enter a valid city code',
  cityRuleMsg2: 'City code can not exceed 30 characters.',
  customize: 'Custom',
  extractIp: 'Extract By API',
  extractIpDialog: 'Extracting proxy IP through API extraction link...',
  extractIpMsg1: 'Check whether the last proxy IP is valid...',
  extractIpMsg2: 'The proxy IP is available. Opening...',
  extractIpMsg3: 'The proxy IP has expired. Retrieve the proxy IP again...',
  extractIpMsg4: 'Check API extraction link...',
  extractIpMsg5: 'Unable to extract IP without link.',
  extractIpMsg6: 'Attempt to extract proxy IP information...',
  extractIpMsg7: 'Parsing extraction results...',
  extractIpMsg8: 'Setting succeeded, opening browser...',
  extractIpMsg9: 'Unable to extract the proxy IP, please check the link is valid.',
  extractIpMsg10: 'Resolution succeeded, setting proxy IP...',
  formatError: 'Please change the API return format to text/txt and try again...',
  addWhiteList: 'Try to add IP white list...',
  notInWhiteList: 'Not added to the white list',
  usernameRequiredRule: 'Please enter user name',
  passwordRequiredRule: 'Please enter password',
  provider: 'Provider',
  protocol: 'Protocol',
  extractType: 'Method',
  extractUrlPlaceholder: 'Please enter extraction link.',
  extractUrl: 'Extraction Link',
  extractTest: 'Test',
  whitelistTips:
    'Note: Most proxy IP platforms need to add the local IP address to their white list to access the extracted link normally. Add your local IP address to whitelist if there is an exception!',
  initialPass: 'Initial Password: ',
  extractUrlTips: 'How to get extraction link?',
  rolaTips:
    'Click "To Rola-IP Admin" to goto Global Residential IP -> Rotating RESI IP, or to Global Phone IP, or to Global Datacenter IP -> Switch to "API Config" tab and click "Click to Generate API" to get extraction link after configuration!',
  extractIpRule: 'Please select a provider.',
  extractUrlRule: 'Please enter a valid URL.',
  extractProtocolRule: 'Please select proxy protocol.',
  extractIpChange1: 'Extract a new IP every time you open the browser',
  extractIpChange2: 'Extract a new IP util last ip is invalid',
  extractError: 'Failed to request data, please ensure the API link is valid.',
  ipCountry: 'Country/Region: ',
  ipState: 'State/Provice: ',
  ipCity: 'City: ',
  iplngAndLat: 'Lng/Lat: ',
  ipTimezone: 'Time Zone: ',
  ipZip: 'Zip: ',
  ipNotWorking: 'Connection failed.',
  common: 'Common',
  noproxy: 'Direct Connection Mode(Noproxy)',
  ipideaauto: 'Ipidea Dynamic Proxy',
  iphtmlauto: 'Iphtml Dynamic Proxy',
  lunaproxy: 'Lunaproxy Dynamic Proxy',
  oxylabsauto: 'Oxylabs Dynamic Proxy',
  lumauto: 'Luminati Dynamic Proxy',
  ssh: 'SSH',
  socks5: 'Socks5',
  http: 'HTTP',
  https: 'HTTPS',
  ip2world: 'IP2World Dynamic Proxy',
  lonAndLat: 'Lng/Lat',
  usedLabel1: 'Detected that this IP in recent time',
  usedLabel2: 'Used in this software!',
  ipNoUsed: 'This IP address has not been used in this software!',
  correctTitle: 'IP Identify Queries/Edit',
  isNotSure: 'Not true?',
  correct: 'Correct',
  correctSuccess: 'Correct successfully',
  lonAndLat: 'Lng/Lat',
  timeZone: 'Time Zone',
  postcode: 'Language',
  channel: 'Platform'
}
