import { request } from 'src/boot/axios'

// 获取代理IP list
export function getAgentIpList(data) {
  return request({ url: '/v1/agentip/list', method: 'post', data })
}

// 添加平台代理IP
export function addAgentIp(data) {
  return request({ url: '/v1/agentip', method: 'post', data })
}

// 修改平台代理IP
export function editAgentIp(data) {
  return request({ url: '/v1/agentip', method: 'put', data })
}
// 清空回收站
export function emptyTrash(data) {
  return request({ url: '/v1/agentip/emptyTrash', method: 'post', data })
}

//  彻底删除
export function delAgentIp(data) {
  return request({ url: '/v1/agentip/completelyDelete', method: 'post', data })
}
// 全部恢复
export function shiftOutAll() {
  return request({ url: '/v1/agentip/shiftOutAll', method: 'get' })
}
// 批量恢复
export function shiftOutRecycleBin(data) {
  return request({ url: '/v1/agentip/shiftOutRecycleBin', method: 'post', data })
}

// 平台代理IP加入回收站
export function removeAgentIpToRecycle(data) {
  return request({ url: '/v1/agentip', method: 'delete', data })
}
// 平台代理IP详情
export function getAgentIpDetail(id) {
  return request({ url: `/v1/agentip?agentId=` + id, method: 'get' })
}

// 切换自动续费
export function updateAutoRenew(data) {
  return request({ url: 'v1/agentip/modifyAgentAutoRenew', method: 'put', data })
}

// 获取国家地区
export function getAgentIpArea() {
  return request({ url: '/v1/agentipprice/getAgentIpArea', method: 'post' })
}

// 根据选择的国家获取所拥有的代理类型
export function getAgentTypeByCountry(country) {
  return request({ url: '/v1/agentipprice/getAgentTypeByCountry', method: 'post', params: { country } })
}

// 查询价格
export function getAgentIpPrice(data) {
  return request({ url: 'v1/agentIpPrice/calculateBuyAgentAmount', method: 'post', data })
}

// 购买IP
export function buyAgentIp(data) {
  return request({ url: '/v1/agentip/buyAgentIp', method: 'post', data })
}

// 续费IP
export function renewAgentIp(data) {
  return request({ url: '/v1/agentip/renewAgentIp', method: 'post', data })
}

// 获取即将到期的Ip
export function getAgentIpEnd() {
  return request({ url: '/v1/agentip/getAgentIpEnd', method: 'get' })
}

// 获取已有的代理IP数量
export function getAgentIpCount(data) {
  return request({ url: '/v1/agentip/getAgentIpCount', method: 'post', data })
}

// 获取rola ip token
export function getRolaToken() {
  return request({ url: '/v1/agentip/sendRolaIp', method: 'post' })
}
// 获取ipfoxy地址
export function sendIpfoxy() {
  return request({ url: '/v1/agentip/sendIpfoxy', method: 'post' })
}

// 获取ip123 token
export function getIp123Token() {
  return request({ url: '/v1/agentip/sendip123', method: 'post' })
}

// 获取SKY-IP token
export function getSkyIpToken() {
  return request({ url: '/v1/agentip/sendSkyIp', method: 'post' })
}

// 是否可以打开旧版
export function hasOldAgentIp() {
  return request({ url: '/v1/agentip/isOldAgentIp', method: 'post' })
}

// rola余额
export function getRolaBalance() {
  return request({ url: '/v1/agentip/getRolaBalance', method: 'post' })
}

// 获取dove url
export function getDoveUrl() {
  return request({ url: '/v1/agentip/sendDove', method: 'post' })
}

// 获取IPidea的url
export function getIpIdeaUrl() {
  return request({ url: '/v1/agentip/getIpIdeaUrl', method: 'post' })
}

// 获取Ipidea用户名密码
export function getIpideaUserInfo() {
  return request({ url: '/v1/agentip/getIpIdeaUser', method: 'post' })
}

// 获取 ipipgo token
export function getIpipgoToken() {
  return request({ url: '/v1/agentip/sendIpipgo', method: 'post' })
}

// 获取IPhtml打开url
export function getIphtmlUrl() {
  return request({ url: '/v1/agentip/sendIpHtml', method: 'post' })
}

// 获取直连模式的IP信息
export function getNoProxyIpInfo() {
  return request({ url: '/v1/apis/ipgeoMethod', method: 'post', data: { ip: '', method: 'ip-api' } })
}
// 修改平台代理IP
export function modifyAgent(data) {
  return request({ url: '/v1/agentip/modify', method: 'put', data })
}
// 根据设备查询绑定账号
export function getAccountByAgentId(id) {
  return request({ url: '/v1/agentip/getAccountByAgentId?agentId=' + id, method: 'get' })
}
// 获取设备绑定的店铺信息列表
export function getAgentBindBrowsers(data) {
  return request({ url: '/v1/browser/getAgentBindBrowsers', method: 'post', data })
}
// 绑定设备
export function bindingAgent(data) {
  return request({ url: '/v1/browser/bindingAgent', method: 'post', data })
}
// 解绑设备
export function unBindingAgent(data) {
  return request({ url: '/v1/browser/unBindingAgent', method: 'post', data })
}
// 批量解绑店铺
export function unBindingBrowser(data) {
  return request({ url: '/v1/agentip/unBindingBrowser', method: 'post', data })
}

// 根据设备id查询设备信息
export function getAgentInfoById(id) {
  return request({ url: '/v1/agentip/getAgentInfoById?id=' + id, method: 'get' })
}
// 获取即将到期设备通知
export function getAgentBecomeDueNotice() {
  return request({ url: '/v1/sysNotice/getAgentBecomeDueNotice', method: 'get' })
}
// 获取设备类型
export function agentOwnTypeList() {
  return request({ url: '/v1/agentOwnType/agentOwnTypeList', method: 'get' })
}
// 设备绑定店铺
export function bindingBrowsers(data) {
  return request({ url: '/v1/browser/bindingBrowsers', method: 'POST', data })
}
// 设备是否可以续费
export function canBeRenewed(data) {
  return request({ url: '/v1/agentip/canBeRenewed', method: 'POST', data })
}
// 根据店铺编号获取设备列表(绑定设备弹窗里面用的)
export function getAgentListByBrowserId(data) {
  return request({ url: '/v1/agentip/getAgentListByBrowserId', method: 'post', data })
}
// 更新IP状态
export function updateIPStatus(id) {
  return request({ url: '/v1/apis/ipStatus', method: 'post', data: { id } })
}

// 获取IP渠道
export function getChannelIpInfo(ip = '', type) {
  return request({ url: '/v1/apis/getChannelIpInfo', method: 'post', data: { ip: ip, method: type } })
}
// 获取设备列表（和专线相关）
export function getAgentListOfCross(data) {
  return request({ url: '/v1/agentip/getAgentListOfCross', method: 'post', data })
}
