import { request } from 'src/boot/axios'

// 浏览器窗口列表
export function getBrowserList(data) {
  return request({ url: '/v1/browser/list', method: 'post', data })
}

// 从回收站里恢复
export function recoverBrowser(data) {
  return request({ url: '/v1/browserInfos/recover', method: 'post', data })
}

// 恢复全部
export function recoverAllBrowser(data) {
  return request({ url: '/v1/browserInfos/recoverAll', method: 'post', data })
}

// 回收站中删除
export function deleteBrowsersForever(data) {
  return request({ url: '/v1/browserInfos/deleteFromRecycle99', method: 'post', data })
}

// 窗口列表中彻底删除
export function deleteForeverFromList(data) {
  return request({ url: '/v1/browserInfos/deleteForever', method: 'post', data })
}

// 设为常用
export function setMostCommon(data) {
  return request({ url: '/v1/browserInfos/setMostCommon', method: 'post', data })
}

// 批量修改窗口信息，分组
export function batchUpdateBrowserInfo(data) {
  return request({ url: '/v1/browserInfos/batchUpdateData', method: 'post', data: data })
}

// 批量修改代理IP
export function batchUpdateProxy(data) {
  return request({ url: '/v1/browserInfos/batchUpdateProxy', method: 'post', data: data })
}

// 添加窗口信息
export function addBrowserInfo(data) {
  return request({ url: '/v1/browser/addBrowserInfo', method: 'post', data })
}

// 修改窗口信息
export function updateBrowserInfo(data) {
  return request({ url: '/v1/browserInfos', method: 'put', data })
}

// 批量添加窗口
export function batchCreateBrowsers(data) {
  return request({ url: '/v1/browserInfos/batchAdd', method: 'post', data })
}

// 浏览器详情
export function getBrowserDetail(id) {
  return request({ url: `/v1/browserInfos/${id}`, method: 'get' })
}

// 打开窗口时获取窗口配置详情
export function getBrowserConfig(id) {
  return request({ url: `/v1/browser/getOpen/${id}`, method: 'get' })
}

// 删除窗口到回收站
export function removeBrowserToRecycleBin(data) {
  return request({ url: '/v1/browserInfos', method: 'delete', data })
}

// 批量修改备注
export function updateBrowserRemark(data) {
  return request({ url: '/v1/browserInfos/batchUpdateRemark', method: 'post', data })
}

// 导出数据
export function exportBrowserData(data) {
  return request({ url: '/v1/browserInfos/export', method: 'post', responseType: 'blob', data, timeout: 0 })
}

// 下载模板
export function downloadTpl() {
  return request({ url: '/v1/browserInfoExcl/downLoadBrowserExclModel', method: 'post', responseType: 'blob' })
}

// 下载附加账号模板
export function downloadOtherAccount() {
  return request({ url: '/v1/browserInfoExcl/downLoadAdditionalAccountModel', method: 'post', responseType: 'blob' })
}

// 浏览器缓存设置
export function browserSet(data) {
  return request({ url: '/v1/browserCaches', method: 'post', data })
}

/**
 * @description 修改浏览器名称
 * @param {object} data
 * @param {number} data.id
 * @param {string} data.name
 * */
export function updateBrowserName(data) {
  return request({ url: '/v1/browserInfos/updateName', method: 'post', data })
}

/**
 * @description 打开或关闭同步状态
 * @param {object} data
 * @param {Array} data.ids
 * @param {number} data.status 0:关闭 1:打开
 * */
export function openOrClose(data) {
  return request({ url: '/v1/browser/openOrClose', method: 'post', data })
}

/**
 * @description 同步911s5的ip
 * @param {number} id 浏览器窗口id
 * @param {lastIp} lastIp 真实的IP
 * @param {lastCountry} lastCountry 真实的country
 * */
export function syncLastIpInfo(id, lastIp, lastCountry) {
  return request({ url: '/v1/browser/updateIp', method: 'post', data: { browserId: id, ip: lastIp, lastIp, lastCountry } })
}

/**
 * @description 检查浏览器打开状态
 * @param {string} id 浏览器ID
 * */
export function getBrowserOpenStatus(data) {
  return request({ url: '/v1/browser/getOpenStatus', method: 'post', data })
}

/**
 * @description 同步cookie
 * @param {object} data
 * @param {string} data.id
 * @param {string} data.cookie
 * */
export function updateBrowserCookie(data) {
  return request({ url: '/v1/browserInfos/updateCookie', method: 'post', data })
}

/**
 * @description 根据平台代理IPID获取绑定窗口
 * */
export function getBrowsersByAgentIp(id) {
  return request({ url: '/v1/browserInfos/getByAgentIpId', method: 'post', data: { agentIpId: id } })
}

/**
 * @description 克隆窗口
 * @param {object} data
 * @param {string} data.browserid
 * @param {number} data.num
 * @param {number} data.type
 * */
export function cloneBrowser(data) {
  return request({ url: '/v1/browserInfos/cloneBrowser', method: 'post', data })
}

// 获取回收站里所有窗口的id
export function getDeletedBrowserIds(data) {
  return request({ url: '/v1/browserInfos/getDeleteIds', method: 'post', data })
}

// 彻底删除回收站里全部窗口
export function clearDeletedBrowsers(data) {
  return request({ url: '/v1/browserInfos/deleteAllFromRecycleBin', method: 'post', data })
}

// 批量修改所有配置
export function batchUpdateEveryBrowserInfo(data) {
  return request({ url: '/v1/browserInfos/batchUpdatePart', method: 'post', data })
}

// exportCookieText  导出cookie ,  参数 窗口List<String> ids
export function exportBrowserCookies(data) {
  return request({ url: '/v1/browserInfos/exportCookieText', method: 'post', responseType: 'blob', data, timeout: 0 })
}
// 查询当前用户是否可以打开选中的店铺
export function getUserOpenStatus(id) {
  return request({ url: '/v1/browser/getUserOpenStatus?id=' + id, method: 'get' })
}
// 单个修改分组
export function modifyBrowserGroup(data) {
  return request({ url: '/v1/browser/modifyBrowserTag', method: 'post', data })
}
// 批量修改分组
export function batchBrowserGroup(data) {
  return request({ url: '/v1/browser/batchBrowserTag', method: 'post', data })
}
export function getBrowserInfosByExtendId(data) {
  return request({ url: '/v1/browserExtend/getBrowserInfosByExtendId', method: 'post', data })
}

// 一键随机自定义排序
export function updateAllSort() {
  return request({ url: '/v1/browser/updateAllSort', method: 'post' })
}
// 列表更新排序
export function updateCurrentSort(data) {
  return request({ url: '/v1/browser/updateSort', method: 'post', data })
}
// 批量修改自定义排序
export function batchUpdateSort(data) {
  return request({ url: '/v1/browser/batchUpdateSort', method: 'post', data })
}

// 获取macAddress 1 非 apple，其他值为apple
export function getRealMacAddress(type = 1) {
  return request({ url: '/v1/browser/getBrowserFingerPrintMac', method: 'post', data: { type } })
}
